 <template lang="html">
  <div class="box is-paddingless">
    <h1>Hulpmiddelen
      <a class="button is-pulled-right is-clearfix" style="margin-bottom: 1em" @click="toggleFold">
        <span class="icon" v-if="isFolded">
          <i class="mdi mdi-chevron-down"></i>
        </span>
        <span v-if="isFolded">Toon hulpmiddelen</span>
        <span class="icon" v-if="!isFolded">
          <i class="mdi mdi-chevron-up"></i>
        </span>
        <span v-if="!isFolded">Verberg hulpmiddelen</span>
      </a>
    </h1>

    <div class="tabs" v-if="!isFolded">
      <ul>
        <li v-on:click="toggleTool('convert')" v-bind:class="{ 'is-active': activeTool == 'convert' }">
          <a>
            <span>Converteren</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="tab" v-show="activeTool == 'convert' && !isFolded">
      <div class="field">
        <p>Converteer een <strong>Word document</strong> naar de juiste opmaak om het invoeren van tekst uit bestaande documenten te vergemakkelijken.</p>
      </div>
      <div class="field" v-show="files.length > 0">
        <div class="control">
          <input v-for="(file, index) in files" class="input" v-model="file.name" type="text" placeholder="Bestandsnaam"
            v-bind:disabled="true" :key="index">
        </div>
      </div>
      <div class="field">
        <file-upload
          ref="upload"
          input-id="converter"
          v-model="files"
          :timeout="60 * 1000"
          :post-action="getUploadUrl()"
          :extensions="allowedExtensions"
          :headers="{'Authorization': 'Bearer ' + getToken() }">

          <a class="button is-outlined">
            <span class="icon">
              <i class="mdi mdi-folder-open"></i>
            </span>
            <span>Kies bestand</span>
          </a>

        </file-upload>

        <div class="columns">
          <div class="column">
            <progress v-if="uploadProgress" ref="progress" class="progress is-large is-info" v-bind:class="{ 'is-danger': uploadError, 'is-success': uploadComplete }" :value="uploadProgress" max="100">{{ uploadProgress }}</progress>
          </div>
          <div class="column is-2">
            <div class="tags has-addons" v-if="isUploading">
              <span class="tag is-info is-rounded" v-bind:class="{ 'is-danger': uploadError, 'is-success': uploadComplete }">{{ uploadSpeed }}</span>
              <span class="tag is-rounded">kb/s</span>
            </div>
          </div>
        </div>

        <p v-show="!isUploading && !uploadError" class="help is-info">De maximale grootte van een bestand is 7.5MB</p>
        <p v-show="!isUploading" v-for="(error, index) in errors.file" class="help is-danger" :key="index">{{ error }}</p>
      </div>
      <a class="button is-primary" v-bind:class="{ 'is-disabled': isUploading }" v-on:click="startUpload()">
        <span class="icon">
          <i class="mdi mdi-file-restore"></i>
        </span>
        <span v-show="!isUploading">Converteer</span>
        <span v-show="isUploading === true">Bezig met converteren</span>
      </a>
      <hr v-if="convert.result" />
      <div class="field" v-if="convert.result">
        <label class="label">Resultaat <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Kopieer blokken tekst uit het geconverteerde resultaat naar paragrafen. Voor meer overzicht kan het resultaat op volledig scherm worden weergegeven.'"></i></label>
        <div class="control">
          <mavon-editor
            v-model="convert.result"
            :toolbars="editorConfig.toolbar"
            :boxShadow=false
            placeholder='Resultaat van conversie'
            language='en'
            defaultOpen='edit' />
        </div>
      </div>

    </div>

  </div>
</template>

<script>

import FileUpload from 'vue-upload-component'
import * as Cookies from 'tiny-cookie'

export default {
  name: 'documentTools',
  props: [
    'document'
  ],
  data: function () {
    return {
      isFolded: true,
      errors: {},
      disabled: false,
      activeTool: 'convert',
      convert: {
        result: false
      },
      editorConfig: {
        toolbar: {
          bold: false,
          italic: false,
          header: false,
          underline: false,
          ol: false,
          ul: false,
          undo: false,
          redo: false,
          navigation: false,
          fullscreen: true,
          subfield: false,
          preview: true
        }
      },
      files: [],
      tags: [],
      uploadError: false,
      uploadComplete: false,
      isUploading: false,
      allowedExtensions: ['docx']
    }
  },
  methods: {
    getUploadUrl: function () {
      return process.env.VUE_APP_API_URL + '/tools/convert/document/word'
    },
    getToken: function () {
      return Cookies.get('access_token')
    },
    startUpload: function () {
      if (this.files.length >= 1 && this.$refs.upload) {
        // trigger upload & reset errors
        this.$refs.upload.active = true
        this.uploadError = false
        this.errors.file = false
        this.isUploading = true
      }
    },
    toggleFold: function () {
      if (this.isFolded) {
        this.isFolded = false
      } else {
        this.isFolded = true
      }
    },
    toggleTool: function (tool) {
      this.activeTool = tool
    }
  },
  computed: {
    // returns all steps exluding the current (to link to)
    uploadProgress: function () {
      if (this.files.length >= 1) return parseInt(this.files[0].progress)

      return 0
    },
    // returns upload speed if there is one running
    uploadSpeed: function () {
      if (this.files.length >= 1) return parseInt(this.files[0].speed / 1024)

      return 0
    }
  },
  watch: {
    'files' (files) {
      // grab the first item of the files (we have single file upload)
      let file = files[0]

      // handle succesfull upload
      if (file && file.success) {
        // handle possible validation problems (for file or other fields)
        if (file.response.code && file.response.code > 200) {
          this.errors = file.response.errors
          this.files = []
        } else {
          this.uploadComplete = true
          // return response
          this.convert.result = file.response.data.content
          // reset upload
          this.uploadError = false
          this.errors.file = false
          this.files = []
        }
        // emit not saving anymore
        this.isUploading = false
      }
      // handle upload errors
      if (file && file.error.length >= 2) {
        switch (file.error) {
          // denied response, in case of validation error
          case 'denied':
            this.errors = file.response.errors
            this.files = []
            break

          // denied based on extension filter
          case 'extension':
            this.uploadError = true
            this.errors.file = ['Het gekozen bestandstype wordt niet ondersteunt, upload een Word document met het .DOCX bestandsformaat.']
            this.files = []
            break

          // all other responses
          default:
            this.uploadError = true
            this.errors.file = ['Het uploaden is helaas misgegaan. Probeer een kleiner bestand te uploaden of gebruik een snellere verbinding.']
            this.files = []
            break
        }

        // emit not saving anymore
        this.isUploading = false
      }
    }
  },
  components: {
    'file-upload': FileUpload
  }
}
</script>
