<template lang="html">
  <nav class="navbar has-shadow" v-show="isloggedin">
    <div class="navbar-menu">
    <div class="navbar-start">
      <router-link class="navbar-item is-brand" to="/">
        <img class="logo" src="../assets/logo-header.svg">
      </router-link>
      <router-link class="navbar-item" to="/documents">
        Artikelen
      </router-link>
      <router-link class="navbar-item" to="/attachments">
        Bijlages
      </router-link>
      <router-link class="navbar-item" to="/comments">
        Commentaren
      </router-link>
      <router-link class="navbar-item" to="/categories">
        Categorie&euml;n
      </router-link>
      <router-link class="navbar-item" to="/tags">
        Thema's
      </router-link>
      <span v-show="isloading" class="spinner"></span>
    </div>
    <div class="navbar-end">
      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          Organisatie
        </a>
        <div class="navbar-dropdown is-boxed">
          <router-link class="navbar-item" to="/departments">
            Afdelingen
          </router-link>
          <router-link class="navbar-item" to="/positions">
            Functies
          </router-link>
          <router-link class="navbar-item" to="/readers">
            Lezers
          </router-link>
          <router-link class="navbar-item" to="/writers">
            Schrijvers
          </router-link>
          <router-link class="navbar-item" to="/notifications">
            Notificaties
          </router-link>
        </div>
      </div>
      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          <avatar
            :username="writerName"
            class="avatar"
            v-if="writerName"
            :size="35"
            backgroundColor="#1B9DC1"
            color="#FFFFFF">
          </avatar> {{ writerName }}
        </a>
        <div class="navbar-dropdown is-boxed">
          <a class="navbar-item" v-on:click="logout()">
            Uitloggen
          </a>
          <hr class="navbar-divider">
          <router-link class="navbar-item" :to="'/writer/' + writerId">
            Profiel
          </router-link>
          <hr class="navbar-divider">
          <router-link class="navbar-item" to="/account">
            Account
          </router-link>
        </div>
      </div>
    </div>
    </div>
  </nav>
</template>

<script>

import Avatar from 'vue-avatar'

export default {
  name: 'navbar',
  data: function () {
    return {
      isloading: false,
      isloggedin: false,
      writer: {
        name: '',
        id: ''
      }
    }
  },
  mounted: function () {
    this.$bus.$on('globalLoadingEvent', this.toggleIsLoading)
    this.$bus.$on('apiLoggedOutEvent', this.toggleLogout)
    this.$bus.$on('writerDataLoadedEvent', this.setWriter)
  },
  methods: {
    toggleIsLoading: function (state) {
      this.isloading = state
    },
    toggleLogout: function () {
      this.isloggedin = false
    },
    setWriter: function (writer) {
      this.writer = writer

      this.isloggedin = true
      this.isloading = false
    },
    logout: function () {
      this.$bus.$emit('askWriterLogoutEvent', true)
    }
  },
  computed: {
    writerName: function () {
      if (this.writer && this.writer.name) return this.writer.name

      return ''
    },
    writerId: function () {
      if (this.writer && this.writer.writerId) return this.writer.writerId

      return false
    }
  },
  components: {
    'avatar': Avatar
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('globalLoadingEvent')
    this.$bus.$off('apiLoggedOutEvent')
    this.$bus.$off('writerDataLoadedEvent')
  }
}
</script>
