<template lang="html">
  <div class="box" v-if="comment.status && comment.status !== 'closed'">
    <article class="media">
      <div class="media-left">
        <avatar
          :username="comment.reader.data.name"
          class="avatar"
          v-if="comment.reader"
          :size="35"
          backgroundColor="#1B9DC1"
          color="#FFFFFF">
        </avatar>
      </div>
      <div class="media-content">
        <div class="content">
          <p>
            <strong>{{ comment.reader.data.name }}</strong> <small>over </small>
            <router-link tag="small" :to="'/document/'+ comment.document_id" v-if="comment.document_id">{{ comment.document.data.name }}</router-link>
            <small> | {{ comment.created_at | moment("from", "now") }}</small>
            <br>
            {{ comment.comment }}
          </p>
        </div>
        <nav class="level is-mobile">
          <div class="field has-addons">
            <p class="control">
              <a class="button is-small" v-on:click="updateCommentStatus(comment, 'open')" v-bind:class="{ 'is-primary': comment.status === 'open' }">
                <span>Openstaand</span>
              </a>
            </p>
            <p class="control">
              <a class="button is-small" v-on:click="updateCommentStatus(comment, 'pending')" v-bind:class="{ 'is-primary': comment.status === 'pending' }">
                <span>In behandeling</span>
              </a>
            </p>
            <p class="control">
              <a class="button is-small" v-on:click="updateCommentStatus(comment, 'closed')" v-bind:class="{ 'is-primary': comment.status === 'closed' }">
                <span>Gesloten</span>
              </a>
            </p>
          </div>
        </nav>
      </div>
    </article>
  </div>
</template>

<script>

import Avatar from 'vue-avatar'

export default {
  name: 'comment',
  props: [
    'comment'
  ],
  methods: {
    updateCommentStatus: function (comment, status) {
      this.$bus.$emit('savingEvent', true)
      comment.status = status

      this.$api.put('comment/' + comment.id, comment).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savedEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.error('Commentaar status kon niet worden gewijzigd')
        } else {
          this.tag = response.data.data
          this.$bus.$emit('savedEvent', true)
          this.disabled = false
          this.$noty.success('De status van het commentaar is succesvol gewijzigd')
          this.errors = {}
        }
      }, (error) => {
        this.$bus.$emit('savedEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    }
  },
  components: {
    'avatar': Avatar
  }
}
</script>
