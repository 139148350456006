<template lang="html">
  <article class="message is-info" v-show="visible">
    <div class="message-body">
      <div class="columns">
        <div class="column is-10">
          <p>Dit {{ entityName }} is sinds <strong>{{ entity.locked_at | moment("DD-MM-YYYY HH:MM") }}</strong> in bewerking door <strong>{{ writer }}</strong> en daarom geblokkeerd.</p>
          <p>U kunt de blokkade opheffen, maar houdt er rekening mee dat wijzigingen van {{ writer }} daarmee verloren kunnen gaan.</p>
        </div>
        <div class="column is-2">
          <a class="button is-pulled-right" v-on:click="askLock()" v-bind:class="{ 'is-disabled': locking }">
            <span class="icon">
              <i class="mdi mdi-pen-lock"></i>
            </span>
            <span v-show="!locking">Blokkering opheffen</span>
            <span v-show="locking">Bezig met opheffen</span>
          </a>
        </div>
      </div>  
    </div>
  </article>
</template>

<script>

export default {
  name: 'unlockMessage',
  props: [
    'entity',
    'entityName'
  ],
  data: function () {
    return {
      visible: false,
      locking: false,
      writer: ''
    }
  },
  methods: {
    askLock: function () {
      this.$bus.$emit('askEntityLockEvent', this.entity)
      this.locking = true
    },
    setLocking: function (state) {
      this.locking = state
      if (state === false) {
        this.visible = false
      }
    }
  },
  mounted: function () {
    // listen for entity lock event
    this.$bus.$on('lockingEvent', (state) => this.setLocking(state))
  },
  watch: {
    entity: function (entity) {
      // hide unlock message if currently editing writer is same as the locked one
      var writerId = localStorage.getItem('writerId')

      if (parseInt(writerId) === entity.locked_writer_id || !entity.locked_writer_id) {
        this.visible = false
      } else {
        this.visible = true
      }

      // set locked writer name, only if it exists
      if (entity.locked_writer) {
        this.writer = entity.locked_writer.data.name
      }
    }
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('lockingEvent')
  }
}
</script>