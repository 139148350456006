<template lang="html">
<div>

  <navbar />

  <section class="section">
    <div class="container is-fluid p-b-lg">

      <extend-login-modal />

      <router-view></router-view>
    </div>
  </section>
</div>
</template>

<script>
export default {
  name: 'app'
}
</script>
