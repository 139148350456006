<template lang="html">
  <div id="comment">

    <archive-modal
      :entity="this.comment"
      :entityName="this.comment.comment" />

    <div class="columns">
      <div class="column is-7">
        <div class="box is-paddingless">

          <h1>Commentaar</h1>

          <div class="tab">
            <comment :comment="comment" />
          </div>

          <h1>Notificaties</h1>

          <div class="tab">
            <notification v-for="(notification) in attachedNotifications"
              v-bind:key="notification.id"
              :notification="notification"/>
          </div>
        </div>
      </div>
      <div class="column is-5">

        <comment-notification
          :comment="comment"
          @sent="getComment(comment.id)" />

      </div>
      <savebar
        :entity="comment"
        entityName="Commentaar">
      </savebar>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import savebar from '../savebar'
import archiveModal from '../common/archive-modal'
import notification from '../common/notification'
import comment from '../common/comment'
import commentNotification from './childs/comment-notification'

export default {
  name: 'commentEdit',
  data: function () {
    return {
      comment: {},
      errors: {},
      attachedNotifications: [],
      disabled: false
    }
  },
  methods: {
    getComment: function (commentId) {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('comment/' + commentId + '?include=notifications').then((response) => {
        this.comment = response.data.data
        this.attachedNotifications = []
        for (let notification of this.comment.notifications.data) {
          this.attachedNotifications.push(notification)
        }

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    archiveComment: function (comment) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Commentaar', comment.id, '/comment')
    }
  },
  mounted: function () {
    this.getComment(this.$route.params.id)

    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', this.updateComment)
    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (comment) => this.archiveComment(comment))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askSaveEvent')
    this.$bus.$off('askEntityArchiveEvent')
  },
  components: {
    'savebar': savebar,
    'archive-modal': archiveModal,
    'notification': notification,
    'comment-notification': commentNotification,
    'comment': comment
  }
}
</script>
