<template lang="html">
  <div id="login">
    <div class="columns">
      <div class="column is-3"></div>
      <div class="column is-6">
        <div class="card is-rounded">
          <div class="card-content">
            <div class="content">
              <h3>Inloggen</h3>
              <div class="field">
                <label class="label">E-mailadres</label>
                <div class="control">
                  <input class="input" type="email" ref="email" v-model="writer.username" placeholder="E-mailadres" autofocus required>
                </div>
              </div>
              <div class="field">
                <label class="label">Wachtwoord</label>
                <div class="control">
                  <input class="input" type="password" v-model="writer.password" placeholder="Wachtwoord" v-on:keyup.enter="login()" required>
                </div>
              </div>
              <a class="button is-primary" v-on:click="login" v-show="!loggingIn">
                <span class="icon">
                  <i class="mdi mdi-login-variant"></i>
                </span>
                <span>Inloggen</span>
              </a>
              <a class="button is-primary is-disabled" v-show="loggingIn">Bezig met inloggen</a>
              <router-link class="button is-pulled-right" to="/password-forgot">
                <span class="icon">
                  <i class="mdi mdi-help-circle"></i>
                </span>
                <span>Wachtwoord vergeten?</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3"></div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>
export default {
  name: 'login',
  data: function () {
    return {
      writer: {
        username: null,
        password: null
      },
      loggingIn: false
    }
  },
  methods: {
    login: function () {
      this.$bus.$emit('askWriterLoginEvent', this.writer)
      this.errors = {}
      this.loggingIn = true
    },
    setLoggedIn: function () {
      this.loggingIn = false
    },
    setFailed: function () {
      this.$noty.warning('De combinatie van gebruikersnaam en wachtwoord is onjuist.')
      this.loggingIn = false
    }
  },
  mounted: function () {
    this.$bus.$on('apiLoggedInEvent', this.setLoggedIn)
    this.$bus.$on('apiInvalidCredentialsEvent', this.setFailed)
    this.$bus.$on('apiInvalidRequestEvent', this.setFailed)
    this.$bus.$on('apiInvalidClientEvent', this.setLoggedIn)

    this.$refs.email.focus()
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('apiLoggedInEvent', this.setLoggedIn)
    this.$bus.$off('apiInvalidCredentialsEvent')
    this.$bus.$off('apiInvalidRequestEvent')
    this.$bus.$off('apiInvalidClientEvent', this.setLoggedIn)
  }
}
</script>