<template lang="html">
  <div class="modal" v-bind:class="{ 'is-active': visible }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <div class="field">
          <label class="label">Zoeken <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Zoek een icoon op basis van een Engelstalige zoekterm'"></i></label>
          <div class="control">
            <input type="text" placeholder="Zoekwoorden" class="input" v-model="keywords">
          </div>
        </div>
        <div class="icons">
          <a
            class="button is-medium m-r-sm m-b-sm"
            v-for="(icon, index) in filteredIcons"
            :class="{ 'is-primary': icon == selectedIcon }"
            v-on:click="selectIcon(icon)"
            :key="index">
            <span class="icon">
              <i :class="'mdi mdi-' + icon"></i>
            </span>
          </a>
        </div>
        <a class="button" v-on:click="close()">
          <span class="icon">
            <i class="mdi mdi-close"></i>
          </span>
          <span>Sluiten</span>
        </a>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
  </div>
</template>

<script>

import _ from 'lodash'
import icons from './icon-picker-modal-icons.json'

export default {
  name: 'iconPickerModal',
  data: function () {
    return {
      visible: false,
      keywords: '',
      icons: icons,
      filteredIcons: [],
      selectedIcon: false
    }
  },
  methods: {
    close: function () {
      this.visible = false
    },
    search: function () {
      let filteredIcons = []

      for (let [icon] of Object.entries(this.icons)) {
        if (icon.includes(this.keywords)) {
          filteredIcons.push(icon)
        }
        this.filteredIcons = filteredIcons
      }
    },
    spawnModal: function () {
      this.visible = true
    },
    selectIcon: function (icon) {
      this.selectedIcon = icon
      this.$bus.$emit('askIconPickEvent', icon)
    }
  },
  mounted: function () {
    // listen for ask for modal event
    this.$bus.$on('askIconPickerModalEvent', () => this.spawnModal())
    // initiate icon list
    this.search()
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askIconPickerModalEvent')
  },
  watch: {
    keywords: _.debounce(function () {
      this.search()
    }, 1000)
  }
}
</script>
