<template lang="html">
  <div class="modal" v-bind:class="{ 'is-active': visible }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <p>Weet je zeker dat je <strong>{{ entityName }}</strong> definitief wilt verwijderen?</p>

        <article class="message is-danger m-t-md" v-if="entityType === 'document'">
          <div class="message-body">
            Het verwijderen van dit document kan niet ongedaan gemaakt worden.
          </div>
        </article>

        <article class="message is-danger m-t-md" v-if="entityType === 'category'">
          <div class="message-body">
            Het verwijderen van de categorie kan niet ongedaan gemaakt worden.
          </div>
        </article>

        <article class="message is-danger m-t-md" v-if="entityType === 'attachment'">
          <div class="message-body">
            Het verwijderen van de bijlage kan niet ongedaan gemaakt worden.
          </div>
        </article>

        <article class="message is-danger m-t-md" v-if="entityType === 'position'">
          <div class="message-body">
            Het verwijderen van de functie kan niet ongedaan gemaakt worden.
          </div>
        </article>

        <article class="message is-danger m-t-md" v-if="entityType === 'tag'">
          <div class="message-body">
            Het verwijderen van het thema kan niet ongedaan gemaakt worden.
          </div>
        </article>

        <article class="message is-danger m-t-md" v-if="entityType === 'reader'">
          <div class="message-body">
            Het verwijderen van een lezer kan niet ongedaan gemaakt worden. 
          </div>
        </article>

        <article class="message is-danger m-t-md" v-if="entityType === 'comment'">
          <div class="message-body">
            Het verwijderen van een commentaar kan niet ongedaan gemaakt worden. 
          </div>
        </article>

        <hr />
        <a class="button is-danger" v-on:click="askDelete(entity)" v-bind:class="{ 'is-disabled': deleting }">
          <span class="icon">
            <i class="mdi mdi-delete-forever"></i>
          </span>
          <span v-if="!deleting">Definitief verwijderen</span>
          <span v-else>Bezig met verwijderen</span>
        </a>
        <a class="button" v-on:click="close()">
          <span class="icon">
            <i class="mdi mdi-cancel"></i>
          </span>
          <span>Annuleer</span>
        </a>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
  </div>
</template>

<script>

export default {
  name: 'deleteModal',
  props: {
    entity: {
      type: Object,
      default: () => ({})
    },
    entityName: String,
    entityType: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      deleting: false,
      visible: false
    }
  },
  methods: {
    askDelete: function (entity) {
      this.$bus.$emit('askEntityDeleteEvent', entity)
      this.deleting = true
    },
    close: function () {
      this.visible = false
    },
    spawnModal: function () {
      this.visible = true
    },
    setDeleting: function (state) {
      this.deleting = state
      if (state === false) {
        this.visible = false
      }
    }
  },
  mounted: function () {
    // listen for ask for modal event
    this.$bus.$on('askEntityDeleteModalEvent', this.spawnModal)
    this.$bus.$on('deletingEvent', (state) => this.setDeleting(state))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askEntityDeleteModalEvent')
    this.$bus.$off('deletingEvent')
  }
}
</script>