<template lang="html">
  <div class="card is-rounded">
    <header class="card-header">
      <p class="card-header-title">
        <router-link to="/attachments" class="has-text-dark">
          <span class="icon is-medium">
            <i class="mdi mdi-paperclip has-text-grey"></i>
          </span>
          <span class="has-text-weight-light is-size-4">Bijlages</span>
        </router-link>
      </p>
    </header>
    <div class="card-content">
      <div class="columns is-gapless">
        <div class="column has-text-centered">
          <router-link :to="{ name: 'attachments', params: { initialOwnershipFilter: 'me' } }">
            <span class="tag is-rounded is-medium">{{ attachments.length }}</span>
            <p class="has-text-grey">op jouw naam</p>
          </router-link>
        </div>
        <div class="column has-text-centered">
          <router-link :to="{ name: 'attachments', params: { initialOwnershipFilter: 'me', initialIsDueFilter: true } }">
            <span class="tag is-rounded is-medium is-warning">{{ attachmentsDue.length }}</span>
            <p class="has-text-grey">verlopen binnenkort</p>
          </router-link>
        </div>
        <div class="column has-text-centered">
          <router-link :to="{ name: 'attachments', params: { initialOwnershipFilter: 'me', initialIsOverDueFilter: true } }">
            <span class="tag is-rounded is-medium is-danger">{{ attachmentsOverDue.length }}</span>
            <p class="has-text-grey">reeds verlopen</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'attachmentsPersonal',
  props: [
    'attachments',
    'attachmentsDue',
    'attachmentsOverDue'
  ]
}
</script>