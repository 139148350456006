<template lang="html">
  <span>
    <div class="modal" v-bind:class="{ 'is-active': visible }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <p>Weet je zeker dat je deze <strong>{{ entityName }}</strong> definitief wilt verwijderen?</p>

          <article class="message is-danger m-t-md" v-if="entityType === 'paragraph'">
            <div class="message-body">
              Het verwijderen van deze paragraaf kan niet ongedaan gemaakt worden.
            </div>
          </article>

          <hr />
          <a class="button is-danger" v-on:click="askDelete(entity)" v-bind:class="{ 'is-disabled': deleting }">
            <span class="icon">
              <i class="mdi mdi-delete-forever"></i>
            </span>
            <span v-if="!deleting">Definitief verwijderen</span>
            <span v-else>Bezig met verwijderen</span>
          </a>
          <a class="button" v-on:click="close()">
            <span class="icon">
              <i class="mdi mdi-cancel"></i>
            </span>
            <span>Annuleer</span>
          </a>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
    </div>

    <a class="button is-danger" v-on:click="open()">
      <span class="icon">
        <i class="mdi mdi-delete"></i>
      </span>
    </a>
  </span>
</template>

<script>

export default {
  name: 'deleteConfirmedButton',
  props: {
    entity: {
      type: Object,
      default: () => ({})
    },
    entityName: String,
    entityType: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      deleting: false,
      visible: false
    }
  },
  methods: {
    askDelete: function (entity) {
      this.$emit('delete', entity)
      this.deleting = true
    },
    close: function () {
      this.visible = false
    },
    open: function () {
      this.visible = true
    },
    setDeleting: function (state) {
      this.deleting = state
      if (state === false) {
        this.visible = false
      }
    }
  },
  mounted: function () {
    // listen for ask for modal event
    this.$bus.$on('deletingEvent', (state) => this.setDeleting(state))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('deletingEvent')
  }
}
</script>