<template lang="html">
  <div id="tag">

    <archive-modal
      :entity="this.tag"
      :entityName="this.tag.name" 
      entityType='tag' />

    <icon-picker-modal />

    <div class="columns">
      <div class="column is-7">
        <div class="box">
          <div class="field">
            <label class="label title is-4 has-text-weight-light">{{ tag.name }}</label>
            <div class="control">
              <input class="input" @focus="$event.target.select()" type="text" v-model="tag.name" placeholder="Naam van het thema"
                v-bind:class="{ 'is-danger': errors.name }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="updateTag()">
            </div>
            <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Status</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="tag.status" v-bind:disabled="disabled">
                  <option v-for="(status, index) in statusses" :value="index" :key="index">{{ status }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.status" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Zichtbaarheid <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Dit bepaalt of het thema zichtbaar is voor lezers in de App en op de Desktop'"></i></label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="tag.is_browsable" v-bind:disabled="disabled">
                  <option v-for="(status, index) in visibility" :value="index" :key="index">{{ status }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.is_browsable" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Icoon <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Kies het icoon waarmee dit thema in de App en op de Desktop wordt aangeduid'"></i></label>
            <div class="control is-expanded">
              <input type="hidden" v-model="tag.icon" />
              <a class="button is-outlined" v-on:click="iconPickModal">
                <span class="icon">
                  <i :class="'mdi mdi-' + tag.icon"></i>
                </span>
                <span>Wijzig icoon</span>
              </a>
              <p v-for="(error, index) in errors.icon" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Kleur <i class="mdi mdi-help-circle has-text-info" v-tooltip="'De kleur waarmee het thema en/of het thema icoon wordt aangeduid'"></i></label>
            <div class="control is-expanded">
              <verte picker="square" model="hex" menuPosition="top" v-model="tag.color"></verte>
              <p v-for="(error, index) in errors.color" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-5">
        <tag-relations
          :tag="tag"
          :attachedAttachments="attachedAttachments"
          :attachedDocuments="attachedDocuments"
          :disabled="disabled" />
      </div>

      <savebar
        :entity="tag"
        entityName="Thema">
      </savebar>

    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import savebar from '../savebar'
import tagRelations from './childs/tag-relations'
import archiveModal from '../common/archive-modal'
import iconPickerModal from '../common/icon-picker-modal'
import Verte from 'verte'

export default {
  name: 'tagEdit',
  data: function () {
    return {
      tag: {},
      errors: {},
      disabled: false,
      attachedAttachments: [],
      attachedDocuments: [],
      statusses: {
        'active': 'Actief',
        'archived': 'Gearchiveerd'
      },
      visibility: {
        '1': 'Zichtbaar',
        '0': 'Onzichtbaar'
      }
    }
  },
  methods: {
    getTag: function (tagId) {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('tag/' + tagId + '?include=documents,attachments').then((response) => {
        this.tag = response.data.data
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false

        this.attachedDocuments = []
        for (let document of this.tag.documents.data) {
          if (document.status === 'published') {
            this.attachedDocuments.push({
              id: document.id,
              name: document.name,
              writer: document.writer.data.name,
              updated_at: document.updated_at })
          }
        }

        this.attachedAttachments = []
        for (let attachment of this.tag.attachments.data) {
          if (attachment.status === 'active') {
            this.attachedAttachments.push({
              id: attachment.id,
              name: attachment.name,
              writer: attachment.writer.data.name,
              updated_at: attachment.updated_at })
          }
        }
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    updateTag: function () {
      this.$bus.$emit('savingEvent', true)
      this.disabled = true
      this.$api.put('tag/' + this.tag.id, this.tag).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Controleer de invoervelden')
        } else {
          this.tag = response.data.data
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Thema ' + this.tag.name + ' is succesvol opgeslagen')
          this.errors = {}
          this.$router.push('/tags')
        }
      }, () => {
        this.$bus.$emit('savingEvent', false)
        this.disabled = false
      })
    },
    archiveTag: function (tag) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Thema', tag.id, '/tag')
    },
    iconPickModal: function () {
      this.$bus.$emit('askIconPickerModalEvent', true)
    },
    selectIcon: function (icon) {
      // set reactive
      this.$set(this.tag, 'icon', icon)
    }
  },
  mounted: function () {
    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', this.updateTag)
    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (tag) => this.archiveTag(tag))
    // listen for icon pick event
    this.$bus.$on('askIconPickEvent', (icon) => this.selectIcon(icon))

    this.getTag(this.$route.params.id)
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askSaveEvent')
    this.$bus.$off('askEntityArchiveEvent')
    this.$bus.$off('askIconPickEvent')
  },
  components: {
    'savebar': savebar,
    'tag-relations': tagRelations,
    'archive-modal': archiveModal,
    'icon-picker-modal': iconPickerModal,
    'verte': Verte
  }
}
</script>
