<template lang="html">
  <div class="box">

    <index-bar
      :activeStatus="statusFilter"
      :statusses="statusses"
      :showSearch=true
      :activeOwnership="mutableOwnershipFilter"
      entityName='commentaren' />

    <archive-modal
      :entity="this.tobeArchivedComment"
      :entityName="this.tobeArchivedComment.comment"
      entityType='comment' />

    <delete-modal
      :entity="this.tobeDeletedComment"
      :entityName="this.tobeDeletedComment.comment"
      entityType='comment' />

    <table class="table is-fullwidth" v-if="comments.length >= 1">
      <thead>
        <tr>
          <th><abbr title="Lezer">Lezer</abbr></th>
          <th><abbr title="Commentaar">Commentaar</abbr></th>
          <th><abbr title="Artikel">Artikel</abbr></th>
          <th><abbr title="Aangemaakt">Aangemaakt</abbr></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="comment in comments" v-on:click="editComment(comment)" :key="comment.id">
          <td>{{ comment.reader.data.name }}</td>
          <td>{{ comment.comment }}</td>
          <td v-if="comment.document">{{ comment.document.data.name }}</td>
          <td>{{ comment.created_at | moment("from", "now") }}</td>
          <td>
            <div class="is-pulled-right">
              <div class="field has-addons">
                <p class="control">
                  <router-link :to="'/comment/'+ comment.id" class="button">
                    <span class="icon">
                      <i class="mdi mdi-eye"></i>
                    </span>
                    <span>Bekijk</span>
                  </router-link>
                </p>
                <p class="control">
                  <a class="button is-danger" v-on:click.stop="archiveCommentModal(comment)" v-show="comment.status !== 'archived'">
                    <span class="icon">
                      <i class="mdi mdi-archive"></i>
                    </span>

                  </a>
                </p>
                <p class="control">
                  <a class="button is-danger" v-on:click.stop="deleteCommentModal(comment)" v-show="comment.status == 'archived'">
                    <span class="icon">
                      <i class="mdi mdi-delete-forever"></i>
                    </span>
                    <span>Verwijderen</span>
                  </a>
                </p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <no-entity
      title="Geen commentaar gevonden"
      message="Probeer andere zoektermen of filters."
      icon="comment-multiple-outline"
      :count="comments.length" />

    <index-pagination
      :pages="pages"
      :currentPage="pageFilter" />

  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import indexBar from '../index-bar'
import indexPagination from '../index-pagination'
import archiveModal from '../common/archive-modal'
import deleteModal from '../common/delete-modal'
import noEntity from '../common/no-entity'

export default {
  name: 'comments',
  data: function () {
    return {
      comments: [],
      tobeArchivedComment: {},
      tobeDeletedComment: {},
      pages: 1,
      pageFilter: 1,
      keywordsFilter: '',
      statusFilter: 'open',
      mutableOwnershipFilter: 'all',
      statusses: {
        'open': 'Open',
        'pending': 'In behandeling',
        'closed': 'Gesloten',
        'archived': 'Gearchiveerd'
      }
    }
  },
  props: {
    initialOwnershipFilter: {
      default: 'me',
      type: String
    }
  },
  methods: {
    searchComments: function () {
      this.$bus.$emit('loadingEvent', true)

      // determine api route based on ownership filter
      if (this.mutableOwnershipFilter === 'me') {
        var commentRoute = 'me/comments'
      } else {
        commentRoute = 'comments'
      }

      this.$api.get(commentRoute + '?comment=' + this.keywordsFilter + '*&status=' + this.statusFilter + '&page=' + this.pageFilter + '&include=document').then((response) => {
        this.comments = response.data.data

        this.pages = response.data.meta.pagination.total_pages
        this.pageFilter = response.data.meta.pagination.current_page

        this.$bus.$emit('loadingEvent', false)
      }, () => {
        this.$bus.$emit('loadingEvent', false)
      })
    },
    setPageFilter: function (page) {
      this.pageFilter = page
    },
    setKeywordFilter: function (keywords) {
      this.keywordsFilter = keywords
      this.pageFilter = 1
    },
    setStatusFilter: function (status) {
      this.statusFilter = status
      this.pageFilter = 1
    },
    setOwnershipFilter: function (ownership) {
      this.mutableOwnershipFilter = ownership
      this.pageFilter = 1
    },
    editComment: function (comment) {
      // navigate to document edit
      this.$router.push({ name: 'commentEdit', params: { id: comment.id } })
    },
    archiveCommentModal: function (comment) {
      this.tobeArchivedComment = comment
      this.$bus.$emit('askEntityArchiveModalEvent', true)
    },
    archiveComment: function (comment) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Commentaar', comment.id, '/comment')
    },
    deleteCommentModal: function (comment) {
      this.tobeDeletedComment = comment
      this.$bus.$emit('askEntityDeleteModalEvent', true)
    },
    deleteComment: function (comment) {
      // handle delete through entity abstraction layer
      this.$entity.delete('Commentaar', comment.id, '/comment')
    }
  },
  created: function () {
    this.mutableOwnershipFilter = this.initialOwnershipFilter
  },
  mounted: function () {
    this.searchComments()

    // listen for search event emitted from search bar
    this.$bus.$on('askSearchEvent', (keywords) => this.setKeywordFilter(keywords))
    // listen for page change event emitted from a paginator
    this.$bus.$on('askPageChangeEvent', (page) => this.setPageFilter(page))
    // listen for status filter change event emitted from a search bar
    this.$bus.$on('askStatusChangeEvent', (status) => this.setStatusFilter(status))
  // listen for ownership filter change event emitted from a search bar
    this.$bus.$on('askOwnershipChangeEvent', (ownership) => this.setOwnershipFilter(ownership))

    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (comment) => this.archiveComment(comment))
    // listen for archived event
    this.$bus.$on('entityArchivedEvent', () => this.searchComments())

    // listen for delete event
    this.$bus.$on('askEntityDeleteEvent', (comment) => this.deleteComment(comment))
    // listen for deleted event
    this.$bus.$on('entityDeletedEvent', () => this.searchComments())
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askEntityArchiveEvent')
    this.$bus.$off('entityArchivedEvent')

    this.$bus.$off('askEntityDeleteEvent')
    this.$bus.$off('entityDeletedEvent')

    this.$bus.$off('askPageChangeEvent')
    this.$bus.$off('askSearchEvent')
    this.$bus.$off('askStatusChangeEvent')
    this.$bus.$off('askOwnershipChangeEvent')
  },
  watch: {
    keywordsFilter: function () {
      // perform new search if keywords change
      this.searchComments()
    },
    pageFilter: function () {
      // perform new search if page is changed
      this.searchComments()
    },
    statusFilter: function () {
      // perform new search if page is changed
      this.searchComments()
    },
    mutableOwnershipFilter: function () {
      // perform new search if page is changed
      this.searchComments()
    },
  },
  components: {
    'index-bar': indexBar,
    'index-pagination': indexPagination,
    'archive-modal': archiveModal,
    'delete-modal': deleteModal,
    'no-entity': noEntity
  }
}
</script>
