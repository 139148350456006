<template lang="html">
  <div id="attachment">
    <div class="columns">
      <div class="column is-12">
        <div class="box">
          <article class="message is-info" v-if="!inModal">
            <div class="message-body">
              <span>
                <p>Handboek App ondersteunt momenteel bijlages in de vorm van <strong>PDF</strong> documenten, <strong>JPG</strong> afbeeldingen en <strong>MP4</strong> videobestanden. Via Handboek Web kun je ook daarnaast <strong>Excel</strong>, <strong>Word</strong> en <strong>ZIP</strong> bestanden downloaden.</p>
                <p>YouTube filmpjes kun je <a href="https://youtubemp4.to" target="_blank">online converteren</a> en daarna als <strong>MP4</strong> bestand in deze bijlage gebruiken.</p>
              </span>
            </div>
          </article>
          <div class="field">
            <label class="label title is-4 has-text-weight-light">{{ attachment.name }}</label>
            <div class="control">
              <input class="input" v-model="attachment.name" ref="name" type="text" required placeholder="Naam van de bijlage"
                v-bind:class="{ 'is-danger': errors.name }">
            </div>
            <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field" v-show="files.length > 0">
            <label class="label">Bestandsnaam</label>
            <div class="control">
              <input v-for="(file, index) in files" class="input" v-model="file.name" type="text" placeholder="Bestandsnaam van de bijlage" v-bind:disabled="true" :key="index">
            </div>
          </div>
          <div class="field">
            <label class="label">Bestand <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Handboek App opent alleen JPG, PNG, MP4 en PDF bestanden. Via Handboek Web kun je ook Excel en Word bestanden downloaden.'"></i></label>
            <file-upload
              ref="upload"
              v-model="files"
              :data="attachment"
              :timeout="3600 * 1000"
              :post-action="getUploadUrl()"
              :extensions="allowedExtensions"
              :headers="{'Authorization': 'Bearer ' + getToken() }">

              <a class="button is-outlined">
                <span class="icon">
                  <i class="mdi mdi-folder-open"></i>
                </span>
                <span>Kies bestand</span>
              </a>

            </file-upload>

            <div class="columns">
              <div class="column">
                <progress v-if="uploadProgress" ref="progress" class="progress is-large is-info" v-bind:class="{ 'is-danger': uploadError, 'is-success': uploadComplete }" :value="uploadProgress" max="100">{{ uploadProgress }}</progress>
              </div>
              <div class="column is-2">
                <div class="tags has-addons" v-if="isUploading">
                  <span class="tag is-info is-rounded" v-bind:class="{ 'is-danger': uploadError, 'is-success': uploadComplete }">{{ uploadSpeed }}</span>
                  <span class="tag is-rounded">kb/s</span>
                </div>
              </div>
            </div>

            <p v-show="!isUploading && !uploadError" class="help is-info">De maximale grootte van een bijlage is 1GB. We adviseren bijlages zo klein mogelijk te houden.</p>
            <p v-show="!isUploading" v-for="(error, index) in errors.file" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Themas</label>
            <div class="control">
              <v-select ref="vselect" multiple
                :options="tags"
                label="name"
                v-model="attachedTags">

                <no-entity-autocomplete
                  title="Thema niet gevonden"
                  message="Probeer andere zoektermen of voeg een nieuw thema toe via het hoofdmenu."
                  icon="tag-outline"
                  slot="no-options" />

              </v-select>
            </div>
          </div>

          <div class="field">
            <label class="label">Categorie&euml;n <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Koppel de bijlage aan relevante categorie&euml;n zodat lezers er naar toe kunnen bladeren.'"></i> <span class="tag is-warning mr-2">Alleen voor Android App</span></label>
            <div class="control">
              <v-select ref="vselect" multiple
                :options="categories"
                label="name"
                v-model="attachedCategories">

                <template slot="option" slot-scope="option">
                    {{ option.arrow }} {{ option.name }}
                </template>

                <no-entity-autocomplete
                  title="Categorie niet gevonden"
                  message="Probeer andere zoektermen of voeg een nieuwe categorie toe via het hoofdmenu."
                  icon="folder-outline"
                  slot="no-options" />

              </v-select>
            </div>
          </div>
          <div class="field">
            <label class="label">Functies <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Je kunt één of meerdere functies of afdelingen aan een bijlage koppelen. Alleen lezers met deze functies of afdelingen zien de bijlage in hun persoonlijke weergave.'"></i></label>

            <personalisation-scope-select
              :attachedPositionsImmutable="this.attachedPositions"
              :attachedDepartmentsImmutable="this.attachedDepartments"
              @updatePositions="updateAttachedPositions"
              @updateDepartments="updateAttachedDepartments" />

          </div>
          <div class="field">
            <p>
              <span class="icon has-text-danger is-pulled-right" v-if="isPositionRestricted">
                <i class="mdi mdi-lock"></i>
              </span>
              <span class="icon has-text-primary is-pulled-right" v-if="!isPositionRestricted">
                <i class="mdi mdi-lock-open"></i>
              </span>
              <strong>Beperk de toegang</strong> tot lezers met de gekozen functies of van de gekozen afdelingen <i class="mdi mdi-help-circle has-text-info" v-tooltip="'De bijlage wordt verborgen voor alle lezers die niet een van de gekozen functies hebben.'"></i>
            </p>
            <toggle-button
              :sync="true"
              v-model="isPositionRestricted"
              :width="58"
              :height="25"
              :labels="{checked: 'Ja', unchecked: 'Nee'}"
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
          </div>
          <div class="field">
            <label class="label">Geldigheid <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Op basis van de geldigheidsdatum worden notificaties over het verlopen van de bijlage verstuurd.'"></i></label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <datepicker
                  v-model="attachment.due_at"
                  name="due_at"
                  language="nl"
                  input-class="input"
                  :format="dueDateFormatter">
                </datepicker>
                <p v-for="(error, index) in errors.due_at" class="help is-danger" :key="index">{{ error }}</p>
              </div>
              <div class="control">
                <a class="button is-outlined" v-on:click="resetDueDate()">
                  <span class="icon">
                    <i class="mdi mdi-close"></i>
                  </span>
                  <span>Geldigheid opheffen</span>
                </a>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">
              Orientatie richting <i class="mdi mdi-help-circle has-text-info" v-tooltip="'PDF documenten worden op basis van de orientatie richting weergegeven in Handboek Web'"></i>
              <span class="icon has-text-primary is-pulled-right">
                <i class="mdi mdi-phone-rotate-landscape" v-if="attachment.orientation === 'landscape'"></i>
                <i class="mdi mdi-phone-rotate-portrait" v-if="attachment.orientation === 'portrait'"></i>
              </span>
            </label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="attachment.orientation" v-bind:disabled="disabled">
                  <option v-for="(orientation, index) in orientations" :value="index" :key="index">{{ orientation }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.orientation" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="field">
            <label class="label">Zoekwoorden</label>
            <div class="control">
              <input class="input" v-model="attachment.keywords" type="text" placeholder="Zoekwoorden voor deze bijlage"
                v-bind:class="{ 'is-danger': errors.keywords }"
                v-bind:disabled="disabled">
            </div>
            <p v-for="(error, index) in errors.keywords" class="help is-danger" :key="index">{{ error }}</p>
          </div>

        </div>
      </div>

      <savebar
        :entity="attachment"
        entityName="Bijlage">
      </savebar>

    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
  @import '../../assets/scss/attachments/attachment-create.scss';
</style>

<script>

import savebar from '../savebar'
import vSelect from 'vue-select'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import FileUpload from 'vue-upload-component'
import * as Cookies from 'tiny-cookie'
import noEntityAutocomplete from '../common/no-entity-autocomplete'
import personalisationScopeSelect from '../common/personalisation-scope-select'
import ToggleButton from 'vue-js-toggle-button/src/Button'

export default {
  name: 'attachmentCreate',
  data: function () {
    return {
      attachment: {
        orientation: 'portrait',
        is_position_restricted: 0,
        name: '',
        status: 'active',
        version: 1
      },
      attachedTags: [],
      attachedPositions: [],
      attachedDepartments: [],
      attachedCategories: [],
      errors: {},
      disabled: false,
      files: [],
      tags: [],
      categories: [],
      uploadError: false,
      uploadComplete: false,
      isUploading: false,
      isPositionRestricted: false,
      allowedExtensions: ['mp4', 'pdf', 'jpg', 'xls', 'xlsx', 'xlsm', 'doc', 'docx', 'zip', 'rdp', 'png'],
      orientations: {
        'portrait': 'Portret',
        'landscape': 'Landschap'
      }
    }
  },
  props: [
    'inModal',
    'modalSave',
    'reset'
  ],
  methods: {
    getTags: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('tags?limit=100').then((response) => {
        for (let tag of response.data.data) {
          this.tags.push({
            id: tag.id,
            name: tag.name })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getToken: function () {
      return Cookies.get('access_token')
    },
    getUploadUrl: function () {
      return process.env.VUE_APP_API_URL + '/attachments'
    },
    resetDueDate: function () {
      this.attachment.due_at = null
    },
    dueDateFormatter: function (date) {
      this.attachment.due_at = moment(date).format('YYYY-MM-DD HH:mm:ss')
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    startUpload: function () {
      if (this.files.length >= 1 && this.$refs.upload) {
        // append other form data
        this.$refs.upload.value[0].data = this.attachment
        // trigger upload & reset errors
        this.$refs.upload.active = true
        this.uploadError = false
        this.errors.file = false
        this.$bus.$emit('savingEvent', true)
      }
    },
    updateAttachment: function (attachmentId) {
      this.attachment.id = attachmentId

      // update attachment tags in separate api call
      this.updateAttachmentTags()

      // update attachment positions in separate api call
      this.updateAttachmentPositions()

      // update attachment departments in separate api call
      this.updateAttachmentDepartments()

      // update attachment positions in separate api call
      this.updateAttachmentCategories()

      // emit created event
      this.$bus.$emit('attachmentCreatedEvent', this.attachment)
      // emite saving off event
      this.$bus.$emit('savingEvent', false)
      this.disabled = false
      this.$noty.success('Bijlage ' + this.attachment.name + ' is succesvol opgeslagen')
      this.errors = {}

      // forward to index of not in modal
      if (!this.inModal) {
        this.$router.push('/attachments')
      }
    },
    updateAttachmentTags: function () {
      // attach selected tags
      if (this.attachedTags) {
        var selectedTags = []
        for (let tag of this.attachedTags) {
          selectedTags.push(tag.id)
        }
        this.$entity.reattach(this.attachment, { 'tags': selectedTags }, 'Thema\'s', '/attachment/' + this.attachment.id + '/tags')
      }
    },
    updateAttachmentPositions: function () {
      // attach selected positions
      if (this.attachedPositions) {
        var selectedPositions = []
        for (let position of this.attachedPositions) {
          selectedPositions.push(position.id)
        }
        this.$entity.reattach(this.attachment, { 'positions': selectedPositions }, 'Functies', '/attachment/' + this.attachment.id + '/positions')
      }
    },
    updateAttachmentDepartments: function () {
      // attach selected departments
      if (this.attachedDepartments) {
        var selectedDepartments = []
        for (let department of this.attachedDepartments) {
          selectedDepartments.push(department.id)
        }
        this.$entity.reattach(this.attachment, { 'departments': selectedDepartments }, 'Afdelingen', '/attachment/' + this.attachment.id + '/departments')
      }
    },
    updateAttachmentCategories: function () {
      // attach selected categories
      if (this.attachedCategories) {
        var selectedCategories = []
        for (let category of this.attachedCategories) {
          selectedCategories.push(category.id)
        }
        this.$entity.reattach(this.attachment, { 'categories': selectedCategories }, 'Categorieen', '/attachment/' + this.attachment.id + '/categories')
      }
    },
    toggleUploading: function (status) {
      this.isUploading = status
    },
    updateAttachedPositions: function (selectedPositions) {
      this.attachedPositions = selectedPositions
    },
    updateAttachedDepartments: function (selectedDepartments) {
      this.attachedDepartments = selectedDepartments
    },
    getCategories: function () {
      this.$bus.$emit('loadingEvent', true)
      this.categories = []
      this.disabled = true
      this.$api.get('categories/flat?limit=100&status=active').then((response) => {
        for (let category of response.data.data) {
          // markup arrow hierarchy structure
          let arrow = ''

          if (category.depth > 1) {
            for (var i = 0; i < category.depth; i++) {
              arrow = arrow + '-'
            }
            arrow = arrow + '>'
          }

          this.categories.push({
            id: category.id,
            name: category.name + ' (' + category.id + ')',
            depth: 0,
            arrow: arrow
          })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
  },
  mounted: function () {
    this.getTags()
    this.getCategories()

    // set attachedPositions to nothing to trigger select component
    this.attachedPositions = []

    // set a preselected category if applicable
    if (this.$route.params.preselectedCategory) {
      this.attachedCategories.push({
        id: this.$route.params.preselectedCategory.id,
        name: this.$route.params.preselectedCategory.name
      })
    }

    // listen for save events (both global and in modal)
    this.$bus.$on('askSaveEvent', this.startUpload)
    this.$bus.$on('savingEvent', this.toggleUploading)

    this.$refs.name.focus()
  },
  computed: {
    // returns all steps exluding the current (to link to)
    uploadProgress: function () {
      if (this.files.length >= 1) return parseInt(this.files[0].progress)

      return 0
    },
    // returns upload speed if there is one running
    uploadSpeed: function () {
      if (this.files.length >= 1) return parseInt(this.files[0].speed / 1024)

      return 0
    }
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askSaveEvent')
    this.$bus.$off('savingEvent')
  },
  watch: {
    'files' (files) {
      // grab the first item of the files (we have single file upload)
      let attachment = files[0]

      // handle succesfull upload
      if (attachment && attachment.success) {
        // handle possible validation problems (for file or other fields)
        if (attachment.response.code && attachment.response.code > 200) {
          this.errors = attachment.response.errors
          this.files = []
        } else {
          this.uploadComplete = true
          // handle finishing the attachment creation
          let attachmentId = attachment.response.data.id
          this.updateAttachment(attachmentId)
        }
        // reset upload component
        this.$refs.upload.clear()

        // emit not saving anymore
        this.$bus.$emit('savingEvent', false)
      }
      // handle upload errors
      if (attachment && attachment.error.length >= 2) {
        switch (attachment.error) {
          // denied response, in case of validation error
          case 'denied':
            this.errors = attachment.response.errors
            this.files = []
            break

          // denied based on extension filter
          case 'extension':
            this.uploadError = true
            this.errors.file = ['Het gekozen bestandstype wordt niet ondersteunt, upload PDF, MP4, JPG, Excel of Word bestanden.']
            this.files = []
            break

          // all other responses
          default:
            this.uploadError = true
            this.errors.file = ['Het uploaden is helaas misgegaan. Probeer een kleiner bestand te uploaden of gebruik een snellere verbinding.']
            this.files = []
            break
        }

        // emit not saving anymore
        this.$bus.$emit('savingEvent', false)
      }
    },
    'modalSave' (saving) {
      // trigger upload / save through prop change (happens in case component is used in modal)
      if (saving) {
        this.startUpload()
      }
    },
    'reset' (state) {
      // reset state of component if reset is requested through prop (happens in case component is used in modal)
      if (state) {
        this.attachment = {
          orientation: 'portrait',
          is_position_restricted: 0,
          status: 'active',
          version: 1
        }
        this.errors = {}
        this.files = []
        this.uploadError = false
        this.isUploading = false
        this.uploadComplete = false
        this.attachedTags = []
      }
    },
    isPositionRestricted: function (status) {
      this.attachment.is_position_restricted = status ? 1 : 0
    }
  },
  components: {
    'savebar': savebar,
    'v-select': vSelect,
    'datepicker': Datepicker,
    'file-upload': FileUpload,
    'no-entity-autocomplete': noEntityAutocomplete,
    'personalisation-scope-select': personalisationScopeSelect,
    'toggle-button': ToggleButton
  }
}
</script>
