<template lang="html">
  <div id="category">

    <archive-modal
      :entity="this.category"
      :entityName="this.category.name" 
      entityType='category' />

    <icon-picker-modal />

    <div class="columns">
      <div class="column is-7">
        <div class="box">
          <div class="field">
            <label class="label title is-4 has-text-weight-light">{{ category.name }}</label>
            <div class="control">
              <input class="input" @focus="$event.target.select()" type="text" v-model="category.name" placeholder="Naam van de categorie"
                  v-bind:class="{ 'is-danger': errors.name }"
                  v-bind:disabled="disabled"
                  v-on:keyup.enter="updateCategory()">
            </div>
            <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Omschrijving</label>
            <div class="control">
              <textarea class="textarea" v-model="category.description" placeholder="Omschrijving van de categorie"
                v-bind:class="{ 'is-danger': errors.description }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="updateCategory()">
              </textarea>
            </div>
            <p v-for="(error, index) in errors.description" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Bovenliggende categorie <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Je kunt een subcategorie en daarmee een hierarchie maken door een bovenliggende categorie te kiezen.'"></i></label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="category.parent_id" v-bind:disabled="disabled">
                  <option :value="null">Geen</option>
                  <option v-for="(category, index) in categories" :key="index" :value="category.id"><span v-for="index in category.depth" :key="index">-</span><span v-if="category.depth > 0">> </span>{{ category.name }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.parent_id" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Personalisatie <i class="mdi mdi-help-circle has-text-info" @click="isVisiblePersonalisationHelp = !isVisiblePersonalisationHelp"></i></label>

            <personalisation-scope-select
              :attachedPositionsImmutable="this.attachedPositions"
              :attachedDepartmentsImmutable="this.attachedDepartments"
              @updatePositions="updateAttachedPositions" 
              @updateDepartments="updateAttachedDepartments" />

            <article class="message is-info mt-4" v-if="isVisiblePersonalisationHelp">
              <div class="message-body">
                  <p>Je kunt één of meerdere functies of afdelingen aan een categorie koppelen. Alleen lezers met deze functies of afdelingen zien de categorie in hun persoonlijke weergave. Ze kunnen de categorie wel benaderen in de weergave voor iedereen. De {{ category.name }} categorie is zichtbaar voor een lezer als:</p>
                  <div class="content">
                    <ul>
                      <li>Er geen functies of afdelingen zijn gekoppeld;</li>
                      <li>Als de functie van de lezer is gekoppeld;</li>
                      <li>Als de afdeling van de lezer is gekoppeld;</li>
                      <li>Als de functie van de lezer onder een gekoppelde afdeling valt.</li>
                    </ul>
                  </div>
              </div>
            </article>
          </div>
          <div class="field">
            <p>
              <span class="icon has-text-danger is-pulled-right" v-if="isPositionRestricted">
                <i class="mdi mdi-lock"></i>
              </span> 
              <span class="icon has-text-primary is-pulled-right" v-if="!isPositionRestricted">
                <i class="mdi mdi-lock-open"></i>
              </span> 
              <strong>Sluit de toegang af</strong> voor lezers die niet binnen de personalisatie vallen <i class="mdi mdi-help-circle has-text-info" v-tooltip="'De volledige categorie wordt onbenaderbaar voor alle lezers die niet binnen de personalisatie vallen.'"></i>
            </p>
            <toggle-button
              :sync="true"
              v-model="isPositionRestricted"
              :width="58"
              :height="25"
              :labels="{checked: 'Ja', unchecked: 'Nee'}" 
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
          </div>
          <div class="field">
            <label class="label">Icoon <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Kies het icoon waarmee deze categorie in de App en op het Web wordt aangeduid'"></i></label>
            <div class="control is-expanded">
              <input type="hidden" v-model="category.icon" />
              <a class="button is-outlined" v-on:click="iconPickModal">
                <span class="icon">
                  <i :class="'mdi mdi-' + category.icon"></i>
                </span>
                <span>Wijzig icoon</span>
              </a>
              <p v-for="(error, index) in errors.icon" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Kleur <i class="mdi mdi-help-circle has-text-info" v-tooltip="'De kleur waarmee de categorie en/of het categorie icoon wordt aangeduid'"></i></label>
            <div class="control is-expanded">
              <verte picker="square" model="hex" menuPosition="top" v-model="category.color"></verte>
              <p v-for="(error, index) in errors.color" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Afbeelding <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Kies de afbeelding waarmee deze categorie in de App en op het Web wordt aangeduid'"></i></label>
            <div class="control is-expanded">
              
              <a class="button is-danger is-outlined mr-2" v-on:click="deleteImage" v-if="category.has_image && !imageShouldDelete">
                <span class="icon">
                  <i class="mdi mdi-delete-forever"></i>
                </span>
                <span>Afbeelding verwijderen</span>
              </a>

              <a class="button is-danger is-outlined mr-2" v-on:click="unDeleteImage" v-if="category.has_image && imageShouldDelete">
                <span class="icon">
                  <i class="mdi mdi-undo"></i>
                </span>
                <span>Verwijdering annuleren</span>
              </a>

              <image-picker-modal @pick="selectImage" />

              <p v-for="(error, index) in errors.image" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field" v-if="$root.$data.writer.isAdmin">
            <label class="label">Schrijvers in beheer <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Koppel de schrijvers die rechten hebben om deze categorie, met onderliggende categorieën en de informatie daarbinnen, te mogen beheren. Geen selectie geeft alle schrijvers toegang.'"></i></label>
            <div class="control">
              <v-select ref="vselect" multiple
                :options="writers"
                label="name"
                v-model="attachedWriters">

                <template slot="option" slot-scope="option">
                    {{ option.arrow }} {{ option.name }}
                </template>

                <no-entity-autocomplete
                  title="Schrijver niet gevonden"
                  message="Probeer andere zoektermen."
                  icon="account-edit"
                  slot="no-options" />

              </v-select>
            </div>
          </div>
          <div class="field">
            <label class="label">Status</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="category.status" v-bind:disabled="disabled">
                  <option v-for="(status, index) in statusses" :value="index" :key="index">{{ status }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.status" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-5">
        <category-relations
          :category="category"
          :attachedDocuments="attachedDocuments"
          :attachedAttachments="attachedAttachments"
          :attachedSubcategories="attachedSubcategories"
          :disabled="disabled" />
      </div>

      <savebar
        :entity="category"
        entityName="Categorie">
      </savebar>

    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';

  .v-select {
    &.dropdown {
      margin-right: 0 !important;
    }
    .dropdown-toggle {
      border-radius: 0 3px 3px 0;
      border-color: $border;
    }
  }
</style>

<script>

import savebar from '../savebar'
import categoryRelations from './childs/category-relations'
import archiveModal from '../common/archive-modal'
import personalisationScopeSelect from '../common/personalisation-scope-select'
import iconPickerModal from '../common/icon-picker-modal'
import imagePickerModal from '../common/image-picker-modal'
import Verte from 'verte'
import ToggleButton from 'vue-js-toggle-button/src/Button'
import noEntityAutocomplete from '../common/no-entity-autocomplete'
import vSelect from 'vue-select'

export default {
  name: 'categoryEdit',
  data: function () {
    return {
      category: {},
      categories: [],
      writers: [],
      attachedPositions: [],
      attachedDepartments: [],
      attachedSubcategories: [],
      attachedDocuments: [],
      attachedAttachments: [],
      attachedWriters: [],
      isPositionRestricted: false,
      imageShouldDelete: false,
      errors: {},
      disabled: false,
      statusses: {
        'active': 'Actief',
        'archived': 'Gearchiveerd'
      },
      isVisiblePersonalisationHelp: false,
    }
  },
  methods: {
    getCategory: function (categoryId) {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('category/' + categoryId + '?include=documents,positions,departments,attachments,writers').then((response) => {
        this.category = response.data.data

        this.attachedPositions = []
        for (let position of this.category.positions.data) {
          this.attachedPositions.push({
            id: position.id,
            name: position.name })
        }

        this.attachedDepartments = []
        for (let department of this.category.departments.data) {
          this.attachedDepartments.push({
            id: department.id,
            name: department.name })
        }

        this.attachedWriters = []
        for (let writer of this.category.writers.data) {
          this.attachedWriters.push({
            id: writer.id,
            name: writer.name })
        }

        this.attachedAttachments = []
        for (let attachment of this.category.attachments.data) {
          if (attachment.status === 'active') {
            this.attachedAttachments.push({
              id: attachment.id,
              name: attachment.name,
              updated_at: attachment.updated_at
            })
          }
        }

        this.attachedDocuments = []
        for (let document of this.category.documents.data) {
          if (document.status === 'published') {
            this.attachedDocuments.push({
              id: document.id,
              version: document.version,
              name: document.name,
              writer: document.writer.data.name,
              updated_at: document.updated_at })
          }
        }

        this.attachedSubcategories = []
        for (let subcategory of this.category.descendants) {
          if (subcategory.status === 'active') {
            this.attachedSubcategories.push({
              id: subcategory.id,
              name: subcategory.name})
          }
        }

        this.isPositionRestricted = Boolean(this.category.is_position_restricted)

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    updateCategory: function () {
      this.$bus.$emit('savingEvent', true)
      this.disabled = true

      // delete the categories' image on request
      if (this.imageShouldDelete) {
        this.category.image_should_delete = true
      }

      this.$api.put('category/' + this.category.id, this.category).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Controleer de invoervelden')
        } else {
          this.category = response.data.data

          // handle attachment of positions through entity abstraction layer
          var selectedPositions = []
          for (let position of this.attachedPositions) {
            selectedPositions.push(position.id)
          }
          this.$entity.reattach(this.category, { 'positions': selectedPositions }, 'Functies', '/category/' + this.category.id + '/positions')

          var selectedDepartments = []
          for (let department of this.attachedDepartments) {
            selectedDepartments.push(department.id)
          }
          this.$entity.reattach(this.category, { 'departments': selectedDepartments }, 'Afdelingen', '/category/' + this.category.id + '/departments')

          if (this.$root.$data.writer.isAdmin) {
            // handle attachment of writers through entity abstraction layer
            var selectedWriters = []
            for (let writer of this.attachedWriters) {
              selectedWriters.push(writer.id)
            }
            this.$entity.reattach(this.category, { 'writers': selectedWriters }, 'Schrijvers', '/category/' + this.category.id + '/writers')
          }

          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Categorie ' + this.category.name + ' is succesvol aangepast')
          this.errors = {}
          this.$router.push('/categories')
        }
      }, () => {
        this.$bus.$emit('savingEvent', false)
        this.disabled = false
      })
    },
    getCategories: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('categories/flat?limit=100&status=active').then((response) => {
        this.categories = response.data.data
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getWriters: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('writers?limit=100').then((response) => {
        this.writers = response.data.data
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    archiveCategory: function (category) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Categorie', category.id, '/category')
    },
    updateAttachedPositions: function (selectedPositions) {
      this.attachedPositions = selectedPositions
    },
    updateAttachedDepartments: function (selectedDepartments) {
      this.attachedDepartments = selectedDepartments
    },
    iconPickModal: function () {
      this.$bus.$emit('askIconPickerModalEvent', true)
    },
    selectIcon: function (icon) {
      // set reactive
      this.$set(this.category, 'icon', icon)
    },
    selectImage: function (image) {
      this.category.image_base64 = image
      this.imageShouldDelete = false
    },
    deleteImage: function () {
      this.imageShouldDelete = true
    },
    unDeleteImage: function () {
      this.imageShouldDelete = false
    }
  },
  mounted: function () {
    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', this.updateCategory)
    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (category) => this.archiveCategory(category))
    // listen for icon pick event
    this.$bus.$on('askIconPickEvent', (icon) => this.selectIcon(icon))

    this.getCategories()
    this.getCategory(this.$route.params.id)
    this.getWriters()
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askSaveEvent')
    this.$bus.$off('askEntityArchiveEvent')
    this.$bus.$off('askIconPickEvent')
  },
  components: {
    'savebar': savebar,
    'category-relations': categoryRelations,
    'archive-modal': archiveModal,
    'personalisation-scope-select': personalisationScopeSelect,
    'icon-picker-modal': iconPickerModal,
    'image-picker-modal': imagePickerModal,
    'verte': Verte,
    'toggle-button': ToggleButton,
    'v-select': vSelect,
    'no-entity-autocomplete': noEntityAutocomplete
  },
  watch: {
    '$route' (to) {
      this.getCategory(to.params.id)
    },
    isPositionRestricted: function (status) {
      this.category.is_position_restricted = status ? 1 : 0
    }
  }
}
</script>
