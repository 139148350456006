<template lang="html">
  <div class="modal" v-bind:class="{ 'is-active': visible }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <p>Je staat op het punt om versie <strong>{{ entity.version }}</strong> van <strong>{{ entity.name }}</strong> als laatste versie te markeren.</p>
        <hr />
        <article class="message is-small is-info">
          <div class="message-body">
            Er kan slechts &eacute;&eacute;n laatste versie bestaan. Versie <strong>{{ entity.version }}</strong> van <strong>{{ entity.name }}</strong> wordt
            als je doorgaat als eerste aan de lezer getoond. Eventueel andere gepubliceerde versies blijven wel voor de lezer oproepbaar.
          </div>
        </article>
        <hr />
        <a class="button is-primary" v-on:click="askMarkLatestVersion(entity)" v-bind:class="{ 'is-disabled': marking }">
          <span class="icon">
            <i class="mdi mdi-radiobox-marked"></i>
          </span>
          <span v-if="!marking">Als laatste versie markeren</span>
          <span v-else>Bezig met markeren</span>
        </a>
        <a class="button" v-on:click="close()">
          <span class="icon">
            <i class="mdi mdi-cancel"></i>
          </span>
          <span>Annuleer</span>
        </a>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
  </div>
</template>

<script>

export default {
  name: 'latestVersionModal',
  data: function () {
    return {
      marking: false,
      visible: false,
      entity: {}
    }
  },
  methods: {
    askMarkLatestVersion: function (entity) {
      this.$bus.$emit('askEntityMarkLatestVersionEvent', entity)
      this.marking = true
    },
    close: function () {
      this.visible = false
    },
    spawnModal: function (entity) {
      this.entity = entity
      this.visible = true
    },
    setMarking: function (state) {
      this.marking = state
      if (state === false) {
        this.visible = false
      }
    }
  },
  mounted: function () {
    // listen for ask for modal event
    this.$bus.$on('askEntityLatestVersionModalEvent', (entity) => this.spawnModal(entity))
    this.$bus.$on('markingLatestVersionEvent', (state) => this.setMarking(state))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askEntityLatestVersionModalEvent')
    this.$bus.$off('markingLatestVersionEvent')
  }
}
</script>
