<template lang="html">
  <div class="box">

    <index-bar
      createPath="/writers/create"
      createText="Schrijver toevoegen"
      :activeStatus="statusFilter"
      :statusses="statusses"
      :showSearch=true />

    <archive-modal
      :entity="this.tobeArchivedWriter"
      :entityName="this.tobeArchivedWriter.name" 
      entityType='writer' />

    <delete-modal-writer
      :entity="this.tobeDeletedWriter"
      :entityName="this.tobeDeletedWriter.name" 
    />

    <table class="table is-fullwidth" v-if="writers.length >= 1">
      <thead>
        <tr>
          <th class="avatar"></th>
          <th><abbr title="Naam">Naam</abbr></th>
          <th><abbr title="Artikelen">Artikelen</abbr></th>
          <th><abbr title="Bijlagen">Bijlagen</abbr></th>
          <th><abbr title="Beheerder">Beheerder</abbr></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="writer in writers" v-on:click="editWriter(writer)" :key="writer.id">
          <td>
            <avatar
              :username="writer.name"
              class="avatar"
              v-if="writer.name"
              :size="35"
              backgroundColor="#1B9DC1"
              color="#FFFFFF">
            </avatar>
          </td>
          <td>{{ writer.name }}</td>
          <td><span class="tag is-info" v-show="writer.documents.data.length > 0"> {{ writer.documents.data.length }}</span></td>
          <td><span class="tag is-info" v-show="writer.attachments.data.length > 0"> {{ writer.attachments.data.length }}</span></td>
          <td><i class="mdi mdi-check" v-if="writer.is_admin === 1" v-tooltip="'Deze gebruiker is beheerder van dit account'"></i></td>
          <td>
            <div class="is-pulled-right">
              <div class="field has-addons">
                <p class="control">
                  <router-link :to="'/writer/'+ writer.id" class="button">
                    <span class="icon">
                      <i class="mdi mdi-pencil"></i>
                    </span>
                    
                  </router-link>
                </p>
                <p class="control">
                  <a class="button is-danger" v-on:click.stop="archiveWriterModal(writer)" v-if="writer.status !== 'archived' && writer.id !== loggedInWriterId">
                    <span class="icon">
                      <i class="mdi mdi-cancel"></i>
                    </span>
                    <span>De-activeren</span>
                  </a>
                  <a class="button is-danger" v-if="writer.status !== 'archived' && writer.id == loggedInWriterId" disabled>
                    <span class="icon">
                      <i class="mdi mdi-cancel"></i>
                    </span>
                    <span>De-activeren</span>
                  </a>
                  <a class="button is-danger" v-on:click.stop="deleteWriterModal(writer)" v-if="writer.status == 'archived'">
                    <span class="icon">
                      <i class="mdi mdi-delete-forever"></i>
                    </span>
                    <span>Verwijderen</span>
                  </a>
                </p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <no-entity
      title="Geen schrijvers gevonden"
      button="Schrijver toevoegen"
      createPath="/writers/create"
      message="Probeer andere zoektermen of voeg een nieuw schrijver toe."
      icon="account-edit"
      :count="writers.length" />

    <index-pagination
      :pages="pages"
      :currentPage="pageFilter" />

  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import indexBar from '../index-bar'
import indexPagination from '../index-pagination'
import archiveModal from '../common/archive-modal'
import deleteModalWriter from '../common/delete-modal-writer'
import noEntity from '../common/no-entity'
import Avatar from 'vue-avatar'

export default {
  name: 'writers',
  data: function () {
    return {
      writers: [],
      tobeArchivedWriter: {},
      tobeDeletedWriter: {},
      pages: 1,
      pageFilter: 1,
      keywordsFilter: '',
      statusFilter: 'active',
      statusses: {
        'active': 'Actief',
        'archived': 'Gearchiveerd'
      }
    }
  },
  methods: {
    searchWriters: function () {
      this.$bus.$emit('loadingEvent', true)

      this.$api.get('writers?name=' + this.keywordsFilter + '*&status=' + this.statusFilter + '&page=' + this.pageFilter + '&include=documents,attachments').then((response) => {
        this.writers = response.data.data

        this.pages = response.data.meta.pagination.total_pages
        this.pageFilter = response.data.meta.pagination.current_page

        this.$bus.$emit('loadingEvent', false)
      }, () => {
        this.$bus.$emit('loadingEvent', false)
      })
    },
    setPageFilter: function (page) {
      this.pageFilter = page
    },
    setKeywordFilter: function (keywords) {
      this.keywordsFilter = keywords
      this.pageFilter = 1
    },
    setStatusFilter: function (status) {
      this.statusFilter = status
      this.pageFilter = 1
    },
    editWriter: function (writer) {
      // navigate to writer edit
      this.$router.push({ name: 'writerEdit', params: { id: writer.id } })
    },
    archiveWriterModal: function (writer) {
      this.tobeArchivedWriter = writer
      this.$bus.$emit('askEntityArchiveModalEvent', true)
    },
    archiveWriter: function (writer) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Schrijver', writer.id, '/writer')
    },
    deleteWriterModal: function (writer) {
      this.tobeDeletedWriter = writer
      this.$bus.$emit('askEntityDeleteWriterModalEvent', true)
    },
    deleteWriter: function (writer, substituteWriter) {

      // handle delete through entity abstraction layer
      if (substituteWriter) {
        this.$entity.delete('Schrijver', writer.id, '/writer', substituteWriter.id)
      } else {
        this.$entity.delete('Schrijver', writer.id, '/writer')
      }
    }
  },
  mounted: function () {
    this.searchWriters()

    // listen for search event emitted from search bar
    this.$bus.$on('askSearchEvent', (keywords) => this.setKeywordFilter(keywords))
    // listen for page change event emitted from a paginator
    this.$bus.$on('askPageChangeEvent', (page) => this.setPageFilter(page))
    // listen for status filter change event emitted from a search bar
    this.$bus.$on('askStatusChangeEvent', (status) => this.setStatusFilter(status))

    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (writer) => this.archiveWriter(writer))
    // listen for archived event
    this.$bus.$on('entityArchivedEvent', () => this.searchWriters())

    // listen for delete event
    this.$bus.$on('askEntityDeleteEvent', (writer, substituteWriter) => this.deleteWriter(writer, substituteWriter))
    // listen for deleted event
    this.$bus.$on('entityDeletedEvent', () => this.searchWriters())
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askArchiveTagEvent')
    this.$bus.$off('askPageChangeEvent')
    this.$bus.$off('askSearchEvent')

    this.$bus.$off('askEntityArchiveEvent')
    this.$bus.$off('entityArchivedEvent')

    this.$bus.$off('askEntityDeleteEvent')
    this.$bus.$off('entityDeletedEvent')
  },
  watch: {
    keywordsFilter: function () {
      // perform new search if keywords change
      this.searchWriters()
    },
    pageFilter: function () {
      // perform new search if page is changed
      this.searchWriters()
    },
    statusFilter: function () {
      // perform new search if page is changed
      this.searchWriters()
    }
  },
  computed: {
    loggedInWriterId: function () {
      return parseInt(localStorage.getItem('writerId'))
    }
  },
  components: {
    'index-bar': indexBar,
    'index-pagination': indexPagination,
    'archive-modal': archiveModal,
    'delete-modal-writer': deleteModalWriter,
    'no-entity': noEntity,
    'avatar': Avatar
  }
}
</script>
