<template lang="html">
  <div id="position">
    <div class="columns">
      <div class="column is-7">
        <div class="box">
          <div class="field">
            <label class="label title is-4 has-text-weight-light">{{ position.name }}</label>
            <div class="control">
              <input class="input" type="text" ref="name" v-model="position.name" placeholder="Titel van de functie"
                v-bind:class="{ 'is-danger': errors.name }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="createPosition()">
            </div>
            <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Afdeling</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="position.department_id" v-bind:disabled="disabled">
                  <option v-for="(department, index) in departments" :value="department.id" :key="index">{{ department.name }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.department_id" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-5"></div>
      <savebar
        :entity="position"
        entityName="Functie">
      </savebar>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import savebar from '../savebar'

export default {
  name: 'positionCreate',
  data: function () {
    return {
      position: {},
      errors: {},
      departments: [],
      disabled: false
    }
  },
  methods: {
    createPosition: function () {
      this.$bus.$emit('savingEvent', true)
      this.disabled = true
      this.$api.post('positions', this.position).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Controleer de invoervelden')
        } else {
          this.position = response.data.data
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Functie ' + this.position.name + ' is succesvol toegevoegd')
          this.errors = {}
          this.$router.push('/positions')
        }
      }, () => {
        this.$bus.$emit('savedEvent', false)
        this.disabled = false
      })
    },
    getDepartments: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('departments?status=active&limit=100').then((response) => {
        for (let department of response.data.data) {
          this.departments.push({
            id: department.id,
            name: department.name
          })

          // also include subdepartments
          for (let subdepartment of department.descendants) {
            this.departments.push({
              id: subdepartment.id,
              name: subdepartment.name
            })
          }
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    }
  },
  mounted: function () {
    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', this.createPosition)

    this.getDepartments()

    this.$refs.name.focus()
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askSaveEvent')
  },
  components: {
    'savebar': savebar
  }
}
</script>
