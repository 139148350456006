<script>

import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: 'statisticsChart',
  props: [
    'chartData'
  ],
  data: function () {
    return {
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 1
            }
          }]
        }
      }
    }
  },
  extends: Line,
  mixins: [reactiveProp],
  mounted: function () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>