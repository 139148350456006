<template lang="html">
  <div class="box is-paddingless" v-if="!disabled">
    <h1>Koppelingen</h1>
    <div class="tabs">
      <ul>
        <li v-on:click="toggleTab('attachments')" v-bind:class="{ 'is-active': activeTab == 'attachments' }">
          <a>
            <span>Bijlage <span class="tag is-info" v-show="attachedAttachments.length > 0"> {{ attachedAttachments.length }}</span></span>
          </a>
        </li>
        <li v-on:click="toggleTab('documents')" v-bind:class="{ 'is-active': activeTab == 'documents' }">
          <a>
            <span>Gerelateerde artikelen <span class="tag is-info" v-show="attachedDocuments.length > 0"> {{ attachedDocuments.length }}</span></span>
          </a>
        </li>
        <li v-on:click="toggleTab('comments')" v-bind:class="{ 'is-active': activeTab == 'comments' }">
          <a>
            <span>Commentaar <span class="tag is-info" v-show="attachedComments.length > 0"> {{ attachedComments.length }}</span></span>
          </a>
        </li>
      </ul>
    </div>

    <div class="tab is-paddingless" v-show="activeTab == 'attachments'">
      <table class="table is-fullwidth" v-show="attachedAttachments.length > 0">
        <thead>
          <tr>
            <th><abbr title="Naam">Naam</abbr></th>
            <th></th>
          </tr>
        </thead>
        <transition-group name="slide-fade" tag="tbody">
          <tr v-for="attachment in attachedAttachments" v-bind:key="attachment.id">
            <td>{{ attachment.name }} <span class="tag ml-1">Versie {{ attachment.version }}</span> <span class="tag ml-1" :class="{'is-primary': attachment.status === 'active'}">{{ statusses[attachment.status] }}</span></td>
            <td>
              <div class="field has-addons">
                <p class="control">
                  <router-link class="button is-small is-pulled-right" :to="'/attachment/'+ attachment.id">
                    Bewerk
                  </router-link>
                </p>
                <p class="control">
                  <a class="button is-small is-danger is-outlined" @click="detachAttachment(attachment)" v-tooltip="'Ontkoppel deze bijlage'">Ontkoppelen</a>
                </p>
              </div>
            </td>
          </tr>
        </transition-group>
      </table>

      <article class="message is-info" v-show="attachedAttachments.length === 0">
        <div class="message-body">
          Geen bijlages gekoppeld aan {{ document.name }}
        </div>
      </article>

      <div class="field">
        <v-select ref="vselect"
          :options="attachments"
          placeholder="Zoek en koppel een bestaande bijlage"
          label="name"
          @input="attachAttachment">

          <template v-slot:option="attachment">
            {{ attachment.name }} <span class="tag ml-1">Versie {{ attachment.version }}</span> <span class="tag ml-1" :class="{'is-primary': attachment.status === 'active'}">{{ statusses[attachment.status] }}</span>
          </template>

          <no-entity-autocomplete
            title="Bijlage niet gevonden"
            message="Probeer andere zoektermen of voeg een nieuwe bijlage toe."
            icon="paperclip"
            slot="no-options" />

        </v-select>
      </div>
      <hr />
      <div class="field">
        <a class="button is-primary" v-on:click="attachmentCreateModal(document)">
          <span class="icon">
            <i class="mdi mdi-plus-circle-outline"></i>
          </span>
          <span>Nieuwe bijlage aanmaken</span>
        </a>
      </div>

    </div>

    <div class="tab is-paddingless" v-show="activeTab == 'documents'">
      <table class="table is-fullwidth" v-show="attachedDocuments.length > 0">
        <thead>
          <tr>
            <th><abbr title="Naam">Naam</abbr></th>
            <th></th>
          </tr>
        </thead>
        <transition-group name="slide-fade" tag="tbody">
          <tr v-for="document in attachedDocuments" v-bind:key="document.id">
            <td>{{ document.name }}</td>
            <td>
              <div class="field has-addons">
                <p class="control">
                  <router-link class="button is-small is-pulled-right" :to="'/document/'+ document.id">
                    Bewerk
                  </router-link>
                </p>
                <p class="control">
                  <a class="button is-small is-danger is-outlined" @click="detachDocument(document)" v-tooltip="'Ontkoppel dit artikel'">Ontkoppelen</a>
                </p>
              </div>
            </td>
          </tr>
        </transition-group>
      </table>

      <article class="message is-info" v-show="attachedDocuments.length === 0">
        <div class="message-body">
          Geen gerelateerde artikelen gekoppeld aan {{ document.name }}
        </div>
      </article>

      <div class="field">
        <v-select ref="vselect"
          :options="documents"
          placeholder="Zoek en koppel een bestaand artikel"
          label="name"
          @input="attachDocument">

          <no-entity-autocomplete
            title="Artikel niet gevonden"
            message="Probeer andere zoektermen."
            icon="file-document"
            slot="no-options" />

        </v-select>
      </div>
      <hr />
      <div class="field">
        <a class="button is-primary" v-on:click="attachmentCreateModal(document)">
          <span class="icon">
            <i class="mdi mdi-plus-circle-outline"></i>
          </span>
          <span>Nieuwe bijlage aanmaken</span>
        </a>
      </div>

    </div>

    <div class="tab is-paddingless" v-show="activeTab == 'comments'">

      <comment v-for="(comment) in attachedComments"
        v-bind:key="comment.id"
        :comment="comment"/>

      <article class="message is-info" v-show="attachedComments.length === 0">
        <div class="message-body">
          Geen commentaar voor {{ document.name }}
        </div>
      </article>

      <div class="field has-addons">
        <p class="control">
          <router-link class="button" to="/comments">
            <span class="icon">
              <i class="mdi mdi-eye"></i>
            </span>
            <span>Alle commentaren tonen</span>
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../../assets/scss/documents/document-relations.scss';
</style>

<script>

import comment from '../../common/comment'
import vSelect from 'vue-select'
import noEntityAutocomplete from '../../common/no-entity-autocomplete'

export default {
  name: 'documentRelations',
  props: [
    'document',
    'attachedAttachments',
    'attachedComments',
    'attachedVersions',
    'attachedDocuments'
  ],
  data: function () {
    return {
      attachments: [],
      documents: [],
      relatedNotifications: [],
      activeTab: 'attachments',
      disabled: true,
      statusses: {
        'active': 'Gepubliceerd',
        'published': 'Gepubliceerd',
        'draft': 'In concept',
        'archived': 'Gearchiveerd'
      }
    }
  },
  methods: {
    toggleTab: function (tabName) {
      this.activeTab = tabName
    },
    getAttachments: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('attachments?limit=1000&status!=archived').then((response) => {
        for (let attachment of response.data.data) {
          this.attachments.push({
            id: attachment.id,
            name: attachment.name,
            version: attachment.version,
            status: attachment.status })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getDocuments: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('documents?limit=1000&status=published').then((response) => {
        for (let document of response.data.data) {
          this.documents.push({
            id: document.id,
            name: document.name })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    attachAttachment: function (attachment) {
      this.$emit('attach-attachment', attachment)
    },
    detachAttachment: function (attachment) {
      this.$emit('detach-attachment', attachment)
    },
    attachDocument: function (document) {
      this.$emit('attach-document', document)
    },
    detachDocument: function (document) {
      this.$emit('detach-document', document)
    },
    versionDocumentModal: function () {
      this.$bus.$emit('askEntityVersionModalEvent', true)
    },
    attachmentCreateModal: function () {
      this.$bus.$emit('askAttachmentCreateModalEvent', true)
    },
    appendAndAttachAttachment: function (attachment) {
      // add newly created attachment to attachment list
      this.attachments.push(attachment)
      // attach newly created attachment
      this.attachAttachment(attachment)
    }
  },
  mounted: function () {
    // listen for create events for attachments
    this.$bus.$on('attachmentCreatedEvent', this.appendAndAttachAttachment)

    this.getAttachments()
    this.getDocuments()
  },
  beforeDestroy: function () {
    // stop listening
    this.$bus.$off('attachmentCreatedEvent', this.appendAndAttachAttachment)
  },
  components: {
    'comment': comment,
    'v-select': vSelect,
    'no-entity-autocomplete': noEntityAutocomplete
  }
}
</script>
