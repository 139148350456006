import Vue from 'vue'
import Router from 'vue-router'

import home from '../components/home/home'

import login from '../components/login/login'
import twofactor from '../components/login/twofactor'
import passwordForgot from '../components/login/password-forgot'
import passwordReset from '../components/login/password-reset'

import writers from '../components/writers/writers'
import writerEdit from '../components/writers/writer-edit'
import writerCreate from '../components/writers/writer-create'

import readers from '../components/readers/readers'
import readerEdit from '../components/readers/reader-edit'
import readerCreate from '../components/readers/reader-create'

import positions from '../components/positions/positions'
import positionEdit from '../components/positions/position-edit'
import positionCreate from '../components/positions/position-create'

import documents from '../components/documents/documents'
import documentEdit from '../components/documents/document-edit'

import categories from '../components/categories/categories'
import categoryEdit from '../components/categories/category-edit'
import categoryCreate from '../components/categories/category-create'

import departments from '../components/departments/departments'
import departmentEdit from '../components/departments/department-edit'
import departmentCreate from '../components/departments/department-create'

import attachments from '../components/attachments/attachments'
import attachmentEdit from '../components/attachments/attachment-edit'
import attachmentCreate from '../components/attachments/attachment-create'

import tags from '../components/tags/tags'
import tagEdit from '../components/tags/tag-edit'
import tagCreate from '../components/tags/tag-create'

import comments from '../components/comments/comments'
import commentEdit from '../components/comments/comment-edit'

import notifications from '../components/notifications/notifications'
import notificationCreate from '../components/notifications/notification-create'

import account from '../components/account/account'

Vue.use(Router)

// application routes
const routes = [
  { path: '/', component: home },

  { path: '/login', component: login },
  { path: '/twofactor', name: 'twofactor', component: twofactor },
  { path: '/password-forgot', name: 'passwordForgot', component: passwordForgot },
  { path: '/password-reset', name: 'passwordReset', component: passwordReset },

  { path: '/writers', component: writers },
  { path: '/writer/:id', name: 'writerEdit', component: writerEdit },
  { path: '/writers/create', component: writerCreate },

  { path: '/readers', component: readers },
  { path: '/reader/:id', name: 'readerEdit', component: readerEdit },
  { path: '/readers/create', component: readerCreate },

  { path: '/positions', component: positions },
  { path: '/position/:id', name: 'positionEdit', component: positionEdit },
  { path: '/positions/create', component: positionCreate },

  { path: '/documents', name: 'documents', component: documents, props: true },
  { path: '/document/:id', name: 'documentEdit', component: documentEdit },

  { path: '/categories', component: categories },
  { path: '/category/:id', name: 'categoryEdit', component: categoryEdit },
  { path: '/categories/create', component: categoryCreate },

  { path: '/departments', component: departments },
  { path: '/department/:id', name: 'departmentEdit', component: departmentEdit },
  { path: '/departments/create', component: departmentCreate },

  { path: '/attachments', name: 'attachments', component: attachments, props: true },
  { path: '/attachment/:id', name: 'attachmentEdit', component: attachmentEdit },
  { path: '/attachments/create', name: 'attachmentCreate', component: attachmentCreate, params: { documentId: true } },

  { path: '/tags', component: tags },
  { path: '/tag/:id', name: 'tagEdit', component: tagEdit },
  { path: '/tags/create', component: tagCreate },

  { path: '/comments', component: comments },
  { path: '/comment/:id', name: 'commentEdit', component: commentEdit },

  { path: '/notifications', component: notifications },
  { path: '/notifications/create', component: notificationCreate },

  { path: '/account', component: account }
]

// export router instance
export default new Router({
  mode: 'history',
  routes,
  linkActiveClass: 'is-active'
})
