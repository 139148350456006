<template lang="html">
  <div class="card is-rounded">
    <header class="card-header">
      <p class="card-header-title">
        <router-link to="/tags" class="has-text-dark">
          <span class="icon is-medium">
            <i class="mdi mdi-tag-outline has-text-grey"></i>
          </span>
          <span class="has-text-weight-light is-size-4">Thema's</span>
        </router-link>
      </p>
    </header>
    <div class="card-content">
      <div class="columns is-gapless">
        <div class="column has-text-centered">
          <router-link to="/tags">
            <span class="tag is-rounded is-medium">{{ tags.length }}</span>
            <p class="has-text-grey">actief</p>
          </router-link>
        </div>
        <div class="column has-text-centered">
          <router-link to="/tags">
            <span class="tag is-rounded is-medium is-primary">{{ browsableTags }}</span>
            <p class="has-text-grey">zichtbaar voor lezers</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'tagsPersonal',
  props: [
    'tags'
  ],
  computed: {
    browsableTags: function () {
      var browsableTags = 0
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].is_browsable) {
          browsableTags++
        }
      }
      return browsableTags
    }
  }
}
</script>