<template lang="html">
  <div class="box">

    <index-bar
      createPath="/tags/create"
      createText="Thema toevoegen"
      foldEntity="document"
      foldEntityName="artikelen & bijlages"
      :activeStatus="statusFilter"
      :statusses="statusses" />

    <archive-modal
      :entity="this.tobeArchivedTag"
      :entityName="this.tobeArchivedTag.name"
      entityType='tag' />

    <delete-modal
      :entity="this.tobeDeletedTag"
      :entityName="this.tobeDeletedTag.name"
      entityType='tag' />

    <table class="table is-fullwidth" v-if="tags.length >= 1">
      <thead>
        <tr>
          <th><abbr title="Naam">Naam</abbr></th>
          <th width="30"><abbr title="Icoon">Icoon</abbr></th>
          <th></th>
          <th><abbr title="Zichtbaar">Zichtbaarheid</abbr></th>
          <th><abbr title="Artikelen">Artikelen &amp; bijlagen</abbr></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="tag in tags">

          <tr v-on:click="editTag(tag)" :key="'tag' + tag.id">
            <td>{{ tag.name }}</td>
            <td><span class="tag has-text-white" :style="'background-color: ' + tag.color"><i :class="'mdi mdi-' + tag.icon"></i></span></td>
            <td></td>
            <td>
              <i class="mdi mdi-check" v-if="tag.is_browsable === 1" v-tooltip="'Dit thema is zichtbaar in de App en op de Desktop'"></i>
            </td>
            <td>
              <span class="tag is-danger" v-show="tag.documents.data.length + tag.attachments.data.length === 0" v-tooltip="'Dit thema heeft geen artikelen gekoppeld en is daarmee onzichtbaar'">0</span>
              <span class="tag is-info" v-show="tag.documents.data.length + tag.attachments.data.length > 0"> {{ tag.documents.data.length  + tag.attachments.data.length }}</span>
            </td>
            <td>
              <div class="is-pulled-right">
                <div class="field has-addons">
                  <p class="control">
                    <router-link :to="'/tag/'+ tag.id" class="button">
                      <span class="icon">
                        <i class="mdi mdi-pencil"></i>
                      </span>

                    </router-link>
                  </p>
                  <p class="control">
                    <a class="button is-danger" v-on:click.stop="archiveTagModal(tag)" v-show="tag.status === 'active'">
                      <span class="icon">
                        <i class="mdi mdi-archive"></i>
                      </span>

                    </a>
                  </p>
                  <p class="control">
                    <a class="button is-danger" v-on:click.stop="deleteTagModal(tag)" v-show="tag.status === 'archived'">
                      <span class="icon">
                        <i class="mdi mdi-delete-forever"></i>
                      </span>
                      <span>Verwijder</span>
                    </a>
                  </p>
                </div>
              </div>
            </td>
          </tr>
          <tr v-for="document in tag.documents.data" v-show="document.status !== 'archived' && isUnfoldedEntities" :key="'document' + document.id">
            <td>
              <i class="mdi mdi-file-document" :style="'margin-left: 1em'"></i> {{ document.name }}
            </td>
            <td></td>
            <td><span class="tag">Versie {{ document.version }}</span></td>
            <td>
              <span class="tag" :class="{'is-primary': document.status === 'published'}">{{ documentStatusses[document.status] }}</span>
            </td>
            <td colspan="2">
              <div class="is-pulled-right">
                <div class="field has-addons">
                  <p class="control">
                    <router-link :to="'/document/'+ document.id" class="button">
                      <span class="icon">
                        <i class="mdi mdi-pencil"></i>
                      </span>

                    </router-link>
                  </p>
                </div>
              </div>
            </td>
          </tr>
          <tr v-for="attachment in tag.attachments.data" v-show="attachment.status !== 'archived' && isUnfoldedEntities" :key="'attachment' + attachment.id">
            <td>
              <i class="mdi mdi-paperclip" :style="'margin-left: 1em'"></i> {{ attachment.name }}
            </td>
            <td></td>
            <td></td>
            <td>
              <span class="tag" :class="{'is-primary': attachment.status === 'active'}">{{ statusses[attachment.status] }}</span>
            </td>
            <td colspan="2">
              <div class="is-pulled-right">
                <div class="field has-addons">
                  <p class="control">
                    <router-link :to="'/attachment/'+ attachment.id" class="button">
                      <span class="icon">
                        <i class="mdi mdi-pencil"></i>
                      </span>

                    </router-link>
                  </p>
                </div>
              </div>
            </td>
          </tr>

        </template>
      </tbody>
    </table>

    <no-entity
      title="Geen thema's gevonden"
      button="Thema toevoegen"
      createPath="/tags/create"
      message="Probeer andere zoektermen of voeg een nieuw thema toe."
      icon="tag-outline"
      :count="tags.length" />

    <index-pagination
      :pages="pages"
      :currentPage="pageFilter" />

  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import indexBar from '../index-bar'
import indexPagination from '../index-pagination'
import archiveModal from '../common/archive-modal'
import deleteModal from '../common/delete-modal'
import noEntity from '../common/no-entity'

export default {
  name: 'tags',
  data: function () {
    return {
      tags: [],
      tobeArchivedTag: {},
      tobeDeletedTag: {},
      pages: 1,
      pageFilter: 1,
      keywordsFilter: '',
      statusFilter: 'active',
      statusses: {
        'active': 'Actief',
        'archived': 'Gearchiveerd'
      },
      documentStatusses: {
        'published': 'Gepubliceerd',
        'draft': 'In concept',
        'archived': 'Gearchiveerd'
      },
      isUnfoldedEntities: false
    }
  },
  methods: {
    searchTags: function () {
      this.$bus.$emit('loadingEvent', true)

      this.$api.get('tags?sort=-index,name&name=' + this.keywordsFilter + '*&status=' + this.statusFilter + '&page=' + this.pageFilter + '&include=documents,attachments').then((response) => {
        this.tags = response.data.data

        this.pages = response.data.meta.pagination.total_pages
        this.pageFilter = response.data.meta.pagination.current_page

        this.$bus.$emit('loadingEvent', false)
      }, () => {
        this.$bus.$emit('loadingEvent', false)
      })
    },
    archiveTagModal: function (tag) {
      this.tobeArchivedTag = tag
      this.$bus.$emit('askEntityArchiveModalEvent', true)
    },
    archiveTag: function (tag) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Thema', tag.id, '/tag')
    },
    deleteTagModal: function (tag) {
      this.tobeDeletedTag = tag
      this.$bus.$emit('askEntityDeleteModalEvent', true)
    },
    deleteTag: function (tag) {
      // handle delete through entity abstraction layer
      this.$entity.delete('Thema', tag.id, '/tag')
    },
    editTag: function (tag) {
      // navigate to document edit
      this.$router.push({ name: 'tagEdit', params: { id: tag.id } })
    },
    setPageFilter: function (page) {
      this.pageFilter = page
    },
    setKeywordFilter: function (keywords) {
      this.keywordsFilter = keywords
      this.pageFilter = 1
    },
    setStatusFilter: function (status) {
      this.statusFilter = status
      this.pageFilter = 1
    },
    foldEntities: function (status) {
      this.isUnfoldedEntities = status
    }
  },
  mounted: function () {
    this.searchTags()

    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (tag) => this.archiveTag(tag))
    // listen for archived event
    this.$bus.$on('entityArchivedEvent', () => this.searchTags())

    // listen for delete event
    this.$bus.$on('askEntityDeleteEvent', (tag) => this.deleteTag(tag))
    // listen for deleted event
    this.$bus.$on('entityDeletedEvent', () => this.searchTags())

    // listen for search event emitted from search bar
    this.$bus.$on('askSearchEvent', (keywords) => this.setKeywordFilter(keywords))
    // listen for page change event emitted from a paginator
    this.$bus.$on('askPageChangeEvent', (page) => this.setPageFilter(page))
    // listen for status filter change event emitted from a search bar
    this.$bus.$on('askStatusChangeEvent', (status) => this.setStatusFilter(status))
    // listen for fold/unfold event
    this.$bus.$on('askFoldEvent', (status) => this.foldEntities(status))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askPageChangeEvent')
    this.$bus.$off('askSearchEvent')
    this.$bus.$off('askStatusChangeEvent')
    this.$bus.$off('askFoldEvent')

    this.$bus.$off('askEntityArchiveEvent')
    this.$bus.$off('entityArchivedEvent')

    this.$bus.$off('askEntityDeleteEvent')
    this.$bus.$off('entityDeletedEvent')
  },
  watch: {
    keywordsFilter: function () {
      // perform new search if keywords change
      this.searchTags()
    },
    pageFilter: function () {
      // perform new search if page is changed
      this.searchTags()
    },
    statusFilter: function () {
      // perform new search if page is changed
      this.searchTags()
    }
  },
  components: {
    'index-bar': indexBar,
    'index-pagination': indexPagination,
    'archive-modal': archiveModal,
    'delete-modal': deleteModal,
    'no-entity': noEntity
  }
}
</script>
