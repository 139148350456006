<template lang="html">
  <span>
    <div class="modal" v-bind:class="{ 'is-active': visible }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <div class="field">
            <label class="label">Kies afbeelding <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Blader op de computer naar de gewenste afbeelding. Gebruik bij voorkeur een afbeelding groter dan 750x750 pixels.'"></i></label>
            <div class="control">
              <div class="file">
                <label class="file-label">
                  <input 
                    class="file-input" 
                    ref="input"
                    type="file"
                    name="image"
                    accept="image/*"
                    @change="setImage"
                  >
                  <a class="button is-outlined">
                    <span class="icon">
                      <i class="mdi mdi-folder-open"></i>
                    </span>
                    <span>Kies bestand</span>
                  </a>
                </label>
              </div>
            </div>
          </div>

          <hr />

          <vue-cropper
            ref="cropper"
            :aspect-ratio="16 / 9"
            :src="imgSrc"
            preview=".preview"
            v-show="imgSrc !== null"
          />

          <div class="field has-addons mt-2" v-if="imgSrc !== null">
            <p class="control">
              <button class="button" @click.prevent="zoom(0.2)">
                <span class="icon">
                  <i class="mdi mdi-magnify-plus-outline"></i>
                </span>
              </button>
            </p>
            <p class="control">
              <button class="button" @click.prevent="zoom(-0.2)">
                <span class="icon">
                  <i class="mdi mdi-magnify-minus-outline"></i>
                </span>
              </button>
            </p>
            <p class="control">
              <button class="button" @click.prevent="move(-10, 0)">
                <span class="icon">
                  <i class="mdi mdi-chevron-left"></i>
                </span>
              </button>
            </p>
            <p class="control">
              <button class="button" @click.prevent="move(10, 0)">
                <span class="icon">
                  <i class="mdi mdi-chevron-right"></i>
                </span>
              </button>
            </p>
            <p class="control">
              <button class="button" @click.prevent="move(0, -10)">
                <span class="icon">
                  <i class="mdi mdi-chevron-up"></i>
                </span>
              </button>
            </p>
            <p class="control">
              <button class="button" @click.prevent="move(0, 10)">
                <span class="icon">
                  <i class="mdi mdi-chevron-down"></i>
                </span>
              </button>
            </p>
            <p class="control">
              <button class="button" @click.prevent="rotate(90)">
                <span class="icon">
                  <i class="mdi mdi-rotate-right"></i>
                </span>
              </button>
            </p>
            <p class="control">
              <button class="button" @click.prevent="rotate(-90)">
                <span class="icon">
                  <i class="mdi mdi-rotate-left"></i>
                </span>
              </button>
            </p>
            <p class="control">
              <button class="button is-primary" @click.prevent="cropImage">
                <span class="icon">
                  <i class="mdi mdi-crop"></i>
                </span>
                <span>Uitsnijden</span>
              </button>
            </p>
            <p class="control">
              <button class="button is-danger" @click.prevent="reset">
                <span class="icon">
                  <i class="mdi mdi-autorenew"></i>
                </span>
                <span>Reset</span>
              </button>
            </p>
          </div>

          <div v-if="cropImg !== null">
            <hr />
            <label class="label">Resultaat</label> 
            <img :src="cropImg"/>
          </div>

          <a class="button is-primary" v-on:click="save()" v-if="cropImg !== null">
            <span class="icon">
              <i class="mdi mdi-content-save"></i>
            </span>
            <span>Opslaan</span>
          </a>

          <a class="button" v-on:click="close()">
            <span class="icon">
              <i class="mdi mdi-close"></i>
            </span>
            <span>Sluiten</span>
          </a>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
    </div>
    <a class="button is-outlined" v-on:click="open()">
      <span class="icon">
        <i class="mdi mdi-image"></i>
      </span>
      <span>Wijzig afbeelding</span>
    </a>
  </span>
</template>

<script>

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'imagePickerModal',
  data: function () {
    return {
      visible: false,
      imgSrc: null,
      cropImg: null,
      data: null
    }
  },
  methods: {
    close: function () {
      this.visible = false
      this.imgSrc = null
      this.data = null
      this.cropImg = null
    },
    open: function () {
      this.visible = true
    },
    cropImage () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL('image/png')
    },
    getCropBoxData () {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4)
    },
    getData () {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4)
    },
    move (offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY)
    },
    reset () {
      this.$refs.cropper.reset()
    },
    rotate (deg) {
      this.$refs.cropper.rotate(deg)
    },
    setCropBoxData () {
      if (!this.data) return
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data))
    },
    setData () {
      if (!this.data) return
      this.$refs.cropper.setData(JSON.parse(this.data))
    },
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Selecteer een afbeelding')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, je browser ondersteunt deze functionaliteit niet.')
      }
    },
    showFileChooser () {
      this.$refs.input.click()
    },
    zoom (percent) {
      this.$refs.cropper.relativeZoom(percent)
    },
    save () {
      this.$emit('pick', this.cropImg)

      // close the modal
      this.close()
    }
  },
  mounted: function () {},
  beforeDestroy () {},
  watch: {},
  components: {
    'vue-cropper': VueCropper
  }
}
</script>