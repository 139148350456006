<template lang="html">
  <div class="box is-paddingless" v-if="!disabled">
    <h1><span class="tag is-warning mr-2">BETA</span> Versies
      <a class="button is-pulled-right is-clearfix" style="margin-bottom: 1em" @click="toggleFold">
        <span class="icon" v-if="isFolded">
          <i class="mdi mdi-chevron-down"></i>
        </span>
        <span v-if="isFolded">Toon versies <span class="tag is-info" v-show="versions.length > 0"> {{ versions.length }}</span></span>
        <span class="icon" v-if="!isFolded">
          <i class="mdi mdi-chevron-up"></i>
        </span>
        <span v-if="!isFolded">Verberg versies</span>
      </a>
    </h1>
    <table class="table is-fullwidth" v-if="!isFolded">
      <thead>
        <tr>
          <th>Nr.</th>
          <th>Schrijver</th>
          <th>Status</th>
          <th>Laatste versie <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Als er meerdere versies gepubliceerd zijn dient &eacute;&eacute;n bijlage als meest recent gemarkeerd te worden. Deze versie van de bijlage wordt als eerste getoond aan de lezers.'"></i></th>
          <th>Laatste wijziging</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="version in versions" :key="version.id" :class="{'has-background-primary-light': version.version === attachment.version}">
          <td><span class="tag">{{ version.version }}</span></td>
          <td>{{ version.writer }}</td>
          <td><span class="tag" :class="{'is-primary': version.status === 'active'}">{{ statusses[version.status] }}</span></td>
          <td>
            <i class="mdi mdi-radiobox-blank" v-if="!version.is_latest_version && version.status === 'active'" @click="latestVersionAttachmentModal(version)"></i>
            <i class="mdi mdi-radiobox-marked" v-if="version.is_latest_version"></i>
          </td>
          <td>{{ version.updated_at | moment("DD-MM-YYYY") }}</td>
          <td>
            <router-link class="button is-small is-pulled-right" :to="'/attachment/'+ version.id" v-show="version.version !== attachment.version">
              Bewerk
            </router-link>
            <span class="tag is-primary is-pulled-right" v-show="version.version === attachment.version">Deze versie</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="field" v-if="!isFolded">
      <a class="button is-primary" v-on:click="versionAttachmentModal(attachment)">
        <span class="icon">
          <i class="mdi mdi-plus-circle-multiple-outline"></i>
        </span>
        <span>Nieuwe versie aanmaken</span>
      </a>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../../assets/scss/documents/document-versions.scss';
</style>

<script>

export default {
  name: 'attachmentVersions',
  props: [
    'attachment',
    'attachedVersions'
  ],
  data: function () {
    return {
      versions: [],
      relatedNotifications: [],
      activeTab: 'versions',
      disabled: false,
      statusses: {
        'active': 'Gepubliceerd',
        'draft': 'In concept',
        'archived': 'Gearchiveerd',
      },
      isFolded: true
    }
  },
  methods: {
    toggleTab: function (tabName) {
      this.activeTab = tabName
    },
    getVersions: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('attachment/' + this.$route.params.id + '/versions').then((response) => {
        
        this.versions.splice(0)

        for (let version of response.data.data) {
          this.versions.push({
            id: version.id,
            version: version.version,
            is_latest_version: version.is_latest_version,
            name: version.name,
            writer: version.writer.data.name,
            status: version.status,
            updated_at: version.updated_at })
        }

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    versionAttachmentModal: function () {
      this.$bus.$emit('askEntityVersionModalEvent', true)
    },
    latestVersionAttachmentModal: function (attachment) {
      this.$bus.$emit('askEntityLatestVersionModalEvent', attachment)
    },
    toggleFold: function () {
      if (this.isFolded) {
        this.isFolded = false
      } else {
        this.isFolded = true
      }
    },
  },
  mounted: function () {
    // listen for save event from the attachment to reload its versions
    this.$bus.$on('savingEvent', this.getVersions)
    this.$bus.$on('entityVersionedEvent', this.getVersions)
    this.$bus.$on('entityMarkedLatestVersionEvent', this.getVersions)

    this.getVersions()
  },
  beforeDestroy: function () {
    // stop listening
    this.$bus.$off('savingEvent', this.getVersions)
    this.$bus.$off('entityVersionedEvent', this.getVersions)
    this.$bus.$off('entityMarkedLatestVersionEvent', this.getVersions)
  },
  components: {}
}
</script>
