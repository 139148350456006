<template lang="html">
  <div class="box">
    <article class="media">
      <div class="media-left">

        <span class="icon is-large has-text-grey-light" v-if="notification.writer_id === null">
          <i class="mdi mdi-message-settings-variant"></i>
        </span>

        <avatar
          :username="notification.writer.data.name"
          class="avatar"
          v-if="notification.writer_id !== null"
          :size="35"
          backgroundColor="#1B9DC1"
          color="#FFFFFF">
        </avatar>

      </div>
      <div class="media-content">
        <div class="content">
            <strong v-if="notification.writer_id === null">Systeembericht</strong>
            <strong v-if="notification.writer_id !== null">{{ notification.writer.data.name }}</strong>
            <small>{{ notification.created_at | moment("from", "now") }}</small>
            <i class="mdi mdi-cellphone-iphone is-pulled-right" v-if="notification.type === 'push'"></i>
            <i class="mdi mdi-email-outline is-pulled-right" v-if="notification.type === 'mail'"></i>
            <hr />
            <strong>{{ notification.title }}</strong>
            <br />
            {{ notification.content }}
        </div>
      </div>
    </article>
  </div>
</template>

<script>

import Avatar from 'vue-avatar'

export default {
  name: 'notification',
  props: [
    'notification'
  ],
  methods: {},
  components: {
    'avatar': Avatar
  }
}
</script>
