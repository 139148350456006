 <template lang="html">
  <div class="box is-paddingless">
    <h1>Notificaties
      <a class="button is-pulled-right is-clearfix" style="margin-bottom: 1em" @click="toggleFold">
        <span class="icon" v-if="isFolded">
          <i class="mdi mdi-chevron-down"></i>
        </span>
        <span v-if="isFolded">Toon notificatiecentrum</span>
        <span class="icon" v-if="!isFolded">
          <i class="mdi mdi-chevron-up"></i>
        </span>
        <span v-if="!isFolded">Verberg notificatiecentrum</span>
      </a>
    </h1>

    <div class="tabs" v-if="!isFolded">
      <ul>
        <li v-on:click="toggleType('push')" v-bind:class="{ 'is-active': activeType == 'push' }">
          <a>
            <span>Push notificatie sturen</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="tab" v-show="activeType == 'push' && !isFolded">
      <div class="field">
        <p>Verstuur een <strong>push notificatie</strong> over dit artikel aan medewerkers met de geselecteerde functie(s).</p>
      </div>
      <hr />

      <position-select
        :attachedPositionsImmutable="notifyPositions"
        @update="updateNotifyPositions"/>

      <div class="field">
        <label class="label">Type notificatie</label>
        <div class="control is-expanded">
          <div class="select is-fullwidth">
            <select v-model="notification.template" v-bind:class="{ 'is-danger': errors.template }">
              <option v-for="(template, type) in templates" :value="type" :key="type">{{ template.name }}</option>
            </select>
          </div>
        </div>
        <p v-for="(error, index) in errors.template" class="help is-danger" :key="index">{{ error }}</p>
      </div>

      <div class="field">
        <label class="label">Titel</label>
        <div class="control">
          <input class="input" type="text" v-model="notification.title" placeholder="Titel van notificatie" v-bind:class="{ 'is-danger': errors.title }">
        </div>
        <p v-for="(error, index) in errors.title" class="help is-danger" :key="index">{{ error }}</p>
      </div>

      <div class="field">
        <label class="label">Bericht</label>
        <div class="control">
          <textarea
            class="textarea"
            v-model="notification.content"
            v-bind:class="{ 'is-danger': errors.content }"
            placeholder="Notificatie tekst"
            rows="4">
          </textarea>
        </div>
        <p v-for="(error, index) in errors.content" class="help is-danger" :key="index">{{ error }}</p>
      </div>

      <div class="field">
        <p v-if="isPublished">
          <span class="icon has-text-primary is-pulled-right" v-if="shouldNotify">
            <i class="mdi mdi-bell-ring"></i>
          </span>
          <span class="icon has-text-grey-light is-pulled-right" v-if="!shouldNotify">
            <i class="mdi mdi-bell-off"></i>
          </span>
          Bevestig het versturen van deze notificatie
        </p>
        <span v-if="isPublished">
          <toggle-button :value="false"
            :sync="true"
            v-model="shouldNotify"
            :width="105"
            :height="25"
            :labels="{checked: 'Bevestigd', unchecked: 'Bevestigen'}"
            :color="{checked: '#00c4a7', unchecked: '#cccccc'}"
            :disabled="!isPublished" />
        </span>
        <hr  v-if="shouldNotify && isPublished" />
        <a class="button is-primary" v-if="shouldNotify && isPublished" v-bind:class="{ 'is-disabled': sending }" v-on:click="sendNotifications()">
          <span class="icon">
            <i class="mdi mdi-bell-outline"></i>
          </span>
          <span v-show="!sending">Verstuur push notificaties</span>
          <span v-show="sending === true">Bezig met versturen</span>
        </a>
      </div>
    </div>

    <div class="tab" v-show="activeType == 'mail' && !isFolded">
      <div class="field">
        <p>Verstuur een <strong>e-mail notificatie</strong> over dit artikel aan medewerkers met de geselecteerde functie(s).</p>
      </div>
      <hr />

      <position-select
        :attachedPositionsImmutable="notifyPositions"
        @update="updateNotifyPositions"/>

      <div class="field">
        <label class="label">Type notificatie</label>
        <div class="control is-expanded">
          <div class="select is-fullwidth">
            <select v-model="notification.template">
              <option v-for="(template, type) in templates" :value="type" :key="type">{{ template.name }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Titel</label>
        <div class="control">
          <input class="input" type="text" v-model="notification.title" placeholder="Titel van notificatie" v-bind:class="{ 'is-danger': errors.title }">
        </div>
        <p v-for="(error, index) in errors.title" class="help is-danger" :key="index">{{ error }}</p>
      </div>

      <div class="field">
        <label class="label">Bericht</label>
        <div class="control">
          <textarea
            class="textarea"
            v-model="notification.content"
            v-bind:class="{ 'is-danger': errors.content }"
            placeholder="Notificatie tekst"
            rows="4">
          </textarea>
        </div>
        <p v-for="(error, index) in errors.content" class="help is-danger" :key="index">{{ error }}</p>
      </div>

      <div class="field">
        <p v-if="isPublished">
          <span class="icon has-text-primary is-pulled-right" v-if="shouldNotify">
            <i class="mdi mdi-email"></i>
          </span>
          <span class="icon has-text-grey-light is-pulled-right" v-if="!shouldNotify">
            <i class="mdi mdi-email-outline"></i>
          </span>
          Bevestig het versturen van deze notificatie
        </p>
        <span v-if="isPublished">
          <toggle-button :value="false"
            :sync="true"
            v-model="shouldNotify"
            :width="105"
            :height="25"
            :labels="{checked: 'Bevestigd', unchecked: 'Bevestigen'}"
            :color="{checked: '#00c4a7', unchecked: '#cccccc'}"
            :disabled="!isPublished" />
        </span>
        <hr  v-if="shouldNotify && isPublished" />
        <a class="button is-primary" v-if="shouldNotify && isPublished" v-bind:class="{ 'is-disabled': sending }" v-on:click="sendNotifications()">
          <span class="icon">
            <i class="mdi mdi-email-outline"></i>
          </span>
          <span v-show="!sending">Verstuur e-mail notificaties</span>
          <span v-show="sending === true">Bezig met versturen</span>
        </a>
      </div>
    </div>

    <div class="tab" v-if="!isFolded && !isPublished">
      <article class="message is-info">
        <div class="message-body">
          <p>Publicatie van dit artikel is vereist alvorens een notificatie kan worden verzonden.</p>
        </div>
      </article>
    </div>

  </div>
</template>

<script>

import ToggleButton from 'vue-js-toggle-button/src/Button'
import positionSelect from '../../common/position-select'

export default {
  name: 'documentNotifications',
  props: [
    'document'
  ],
  data: function () {
    return {
      isFolded: true,
      notification: {},
      templates: {
        update: {
          name: 'Wijziging artikel',
          template: 'Het artikel "{name}" is zojuist bijgewerkt met voor jou relevante wijzigingen.'
        },
        new: {
          name: 'Nieuw artikel',
          template: 'Er is een voor jou relevant nieuw artikel geplaatst: "{name}".'
        },
        alert: {
          name: 'Algemeen',
          template: 'Wil je het artikel "{name}" nog eens bekijken?'
        }
      },
      errors: {},
      shouldNotify: false,
      activeType: 'push',
      notifyPositions: [],
      sending: false
    }
  },
  methods: {
    sendNotifications: function () {
      // compose the notification
      this.notification.document_id = this.document.id
      this.notification.positions = []
      this.notification.type = this.activeType
      if (this.notifyPositions.length > 0) {
        for (let position of this.notifyPositions) {
          this.notification.positions.push(position.id)
        }
      }

      this.$bus.$emit('savingEvent', true)
      // start updating
      this.sending = true
      this.$api.post('notifications', this.notification).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.sending = false
          this.errors = response.data.errors
          this.$noty.warning('Notificaties niet verzonden, er ging helaas iets mis.')
        } else {
          this.$bus.$emit('savingEvent', false)
          this.sending = false
          this.$noty.success('Notificaties voor ' + this.document.name + ' zijn verzonden')
          this.errors = {}
        }
      }, (error) => {
        this.$bus.$emit('savingEvent', false)
        this.sending = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    toggleFold: function () {
      if (this.isFolded) {
        this.isFolded = false
      } else {
        this.isFolded = true
      }
    },
    toggleType: function (type) {
      this.activeType = type
    },
    updateNotifyPositions: function (selectedPositions) {
      this.notifyPositions = selectedPositions
    },
    fillNotificationTemplateVars: function (template) {
      return template.replace('{name}', this.document.name)
    }
  },
  mounted: function () {
    // set attachedPositions to nothing to trigger select component
    this.notifyPositions = []
  },
  computed: {
    // returns all steps exluding the current (to link to)
    isPublished: function () {
      if (this.document.status === 'published') return true

      return false
    }
  },
  watch: {
    document: function (to) {
      if (to.positions && to.positions.data.length > 0) {
        this.notifyPositions = []
        for (let position of to.positions.data) {
          this.notifyPositions.push({
            id: position.id,
            name: position.name })
        }
      }
    },
    'notification': {
      handler: function (to) {
        if (to.template) {
          this.notification.content = this.fillNotificationTemplateVars(this.templates[to.template].template)
          this.notification.title = this.templates[to.template].name
        }
      },
      deep: true
    }
  },
  components: {
    'toggle-button': ToggleButton,
    'position-select': positionSelect
  }
}
</script>
