<template lang="html">
  <div class="card is-rounded">
    <header class="card-header">
      <p class="card-header-title">
        <router-link to="/documents" class="has-text-dark">
          <span class="icon is-medium">
            <i class="mdi mdi-file-document has-text-grey"></i>
          </span>
          <span class="has-text-weight-light is-size-4">Artikelen</span>
        </router-link>
      </p>
    </header>
    <div class="card-content">
      <div class="columns is-gapless">
        <div class="column has-text-centered">
          <router-link :to="{ name: 'documents', params: { initialOwnershipFilter: 'me' } }">
            <span class="tag is-rounded is-medium">{{ documents.length }}</span>
            <p class="has-text-grey">op jouw naam</p>
          </router-link>
        </div>
        <div class="column has-text-centered">
          <router-link :to="{ name: 'documents', params: { initialOwnershipFilter: 'me', initialIsDueFilter: true } }">
            <span class="tag is-rounded is-medium is-warning">{{ documentsDue.length }}</span>
            <p class="has-text-grey">verlopen binnenkort</p>
          </router-link>
        </div>
        <div class="column has-text-centered">
          <router-link :to="{ name: 'documents', params: { initialOwnershipFilter: 'me', initialIsOverDueFilter: true } }">
            <span class="tag is-rounded is-medium is-danger">{{ documentsOverDue.length }}</span>
            <p class="has-text-grey">reeds verlopen</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'documentsPersonal',
  props: [
    'documents',
    'documentsDue',
    'documentsOverDue'
  ]
}
</script>