<template lang="html">
  <div class="box">

    <index-bar
      createPath="/categories/create"
      createText="Categorie toevoegen"
      foldEntity="document"
      foldEntityName="artikelen & bijlages"
      :activeStatus="statusFilter"
      :statusses="statusses" />

    <archive-modal
      :entity="this.tobeArchivedCategory"
      :entityName="this.tobeArchivedCategory.name"
      entityType='category' />

    <delete-modal
      :entity="this.tobeDeletedCategory"
      :entityName="this.tobeDeletedCategory.name"
      entityType='category' />

    <table class="table is-fullwidth" v-if="categories.length >= 1">
      <thead>
        <tr>
          <th><abbr title="Naam">Naam</abbr></th>
          <th width="150"></th>
          <th width="30"><abbr title="Icoon">Icoon</abbr></th>
          <th><abbr title="Artikelen">Artikelen</abbr></th>
          <th><abbr title="Personalisatie">Personalisatie</abbr></th>
          <th><abbr title="Afgeschermd">Afgeschermd</abbr></th>
          <th><abbr title="Beheerders">Beheerders</abbr></th>
          <th></th>
        </tr>
      </thead>
        <template v-for="(category, index) in categories">
          <tr style="display: table-row" v-on:click="editCategory(category)" :key="'category' + category.id">
            <td>
              <i class="mdi mdi-chevron-right" :style="'margin-left:' + category.depth + 'em'"></i> {{ category.name }}
            </td>
            <td></td>
            <td><span class="tag has-text-white" :style="'background-color: ' + category.color"><i :class="'mdi mdi-' + category.icon"></i></span></td>
            <td><span class="tag is-info" v-show="category.documents.data.length > 0"> {{ category.documents.data.length }}</span></td>
            <td>
              <span class="icon" v-show="category.positions.data.length === 0 && category.departments.data.length === 0" v-tooltip="'Deze categorie is gericht op alle lezers'">
                <i class="mdi mdi-infinity"></i>
              </span>
              <entity-relations-badge :entities="category.positions" v-if="category.positions.data.length > 0" />
              <entity-relations-badge :entities="category.departments" v-if="category.departments.data.length > 0" />
            </td>
            <td>
              <i class="mdi mdi-lock has-text-danger" v-if="category.is_position_restricted" v-tooltip="'Deze categorie is alleen te openen voor lezers met een van de gekozen functies of afdelingen.'"></i>
              <i class="mdi mdi-lock-open has-text-primary" v-if="!category.is_position_restricted" v-tooltip="'Deze categorie is voor alle lezers te openen.'"></i>
            </td>
            <td>
              <span class="icon" v-show="category.writers.data.length === 0" v-tooltip="'Deze categorie wordt beheerd door alle schrijvers'">
                <i class="mdi mdi-infinity"></i>
              </span>
              <span class="tag is-info" v-show="category.writers.data.length > 0"> {{ category.writers.data.length }}</span>
            </td>
            <td>
              <div class="is-pulled-right">
                <div class="field has-addons">
                  <p class="control" v-if="$root.$data.writer.isAdmin">
                    <a class="button"
                      v-on:click="moveUpCategory(category)"
                      v-if="category.status == 'active' && canMoveUp(index)"
                      v-tooltip="'Verplaats deze categorie naar boven'"
                      @click.stop
                      v-bind:disabled="disabled">
                      <span class="icon">
                        <i class="mdi mdi-arrow-up"></i>
                      </span>
                    </a>
                  </p>
                  <p class="control" v-if="$root.$data.writer.isAdmin">
                    <a class="button"
                      v-on:click="moveDownCategory(category)"
                      v-if="category.status == 'active' && canMoveDown(index)"
                      v-tooltip="'Verplaats deze categorie naar beneden'"
                      @click.stop
                      v-bind:disabled="disabled">
                      <span class="icon">
                        <i class="mdi mdi-arrow-down"></i>
                      </span>
                    </a>
                  </p>
                  <p class="control">
                    <router-link :to="'/category/'+ category.id" class="button">
                      <span class="icon">
                        <i class="mdi mdi-pencil"></i>
                      </span>

                    </router-link>
                  </p>
                  <div class="dropdown control is-hoverable">
                    <div class="dropdown-trigger">
                      <button class="button" aria-haspopup="true" aria-controls="dropdown-menu3">
                      <span class="icon">
                        <i class="mdi mdi-plus-circle-outline"></i>
                      </span>
                      </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                      <div class="dropdown-content">
                        <a class="dropdown-item" v-on:click.stop="createDocumentInCategory(category)">
                          Artikel toevoegen
                        </a>
                        <a class="dropdown-item" v-on:click.stop="createAttachmentInCategory(category)">
                          Bijlage toevoegen
                        </a>
                      </div>
                    </div>
                  </div>
                  <p class="control">
                    <a class="button is-danger" v-on:click.stop="archiveCategoryModal(category)" v-show="category.status !== 'archived'">
                      <span class="icon">
                        <i class="mdi mdi-archive"></i>
                      </span>

                    </a>
                  </p>
                  <p class="control">
                    <a class="button is-danger" v-on:click.stop="deleteCategoryModal(category)" v-show="category.status == 'archived'">
                      <span class="icon">
                        <i class="mdi mdi-delete-forever"></i>
                      </span>
                      <span>Verwijderen</span>
                    </a>
                  </p>
                </div>
              </div>
            </td>
          </tr>
          <tr v-for="document in category.documents.data" v-show="document.status === 'published' && isUnfolded" :key="'category' + category.id + '.document.' + document.id">
            <td>
              <i class="mdi mdi-file-document" :style="'margin-left:' + (parseInt(category.depth) + 1) + 'em'"></i> {{ document.name }}
            </td>
            <td><span class="tag">Versie {{ document.version }}</span></td>
            <td></td>
            <td colspan="2">
              <span class="tag" :class="{'is-primary': document.status === 'published'}">{{ documentStatusses[document.status] }}</span>
            </td>
            <td>
              <i class="mdi mdi-lock has-text-danger" v-if="document.is_position_restricted"></i>
            </td>
            <td colspan="3">
              <div class="is-pulled-right">
                <div class="field has-addons">
                  <p class="control">
                    <router-link :to="'/document/'+ document.id" class="button">
                      <span class="icon">
                        <i class="mdi mdi-pencil"></i>
                      </span>

                    </router-link>
                  </p>
                </div>
              </div>
            </td>
          </tr>
          <tr v-for="attachment in category.attachments.data" v-show="attachment.status === 'active' && isUnfolded" :key="'category' + category.id + '.attachment.' + attachment.id">
            <td>
              <i class="mdi mdi-paperclip" :style="'margin-left:' + (parseInt(category.depth) + 1) + 'em'"></i> {{ attachment.name }}
            </td>
            <td><span class="tag">Versie {{ attachment.version }}</span></td>
            <td></td>
            <td colspan="2">
              <span class="tag" :class="{'is-primary': attachment.status === 'active'}">{{ attachmentStatusses[attachment.status] }}</span>
            </td>
            <td>
              <i class="mdi mdi-lock has-text-danger" v-if="attachment.is_position_restricted"></i>
            </td>
            <td colspan="3">
              <div class="is-pulled-right">
                <div class="field has-addons">
                  <p class="control">
                    <router-link :to="'/attachment/'+ attachment.id" class="button">
                      <span class="icon">
                        <i class="mdi mdi-pencil"></i>
                      </span>

                    </router-link>
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </template>
    </table>

    <no-entity
      title="Geen categorieen gevonden"
      button="Categorie toevoegen"
      createPath="/categories/create"
      message="Probeer andere zoektermen of voeg een categorie toe."
      icon="folder-open"
      :count="categories.length" />

    <div class="dropdown is-hoverable is-primary">
      <div class="dropdown-trigger">
        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu3">
          <i class="mdi mdi-cloud-download mr-2"></i> Download structuur
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu3" role="menu">
        <div class="dropdown-content">
          <a class="dropdown-item" v-on:click.stop="downloadStructurePdf">
            <i class="mdi mdi-file-pdf"></i> In PDF formaat
          </a>
          <a class="dropdown-item" v-on:click.stop="downloadStructureExcel">
            <i class="mdi mdi-file-excel"></i> In Excel formaat
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import indexBar from '../index-bar'
import archiveModal from '../common/archive-modal'
import deleteModal from '../common/delete-modal'
import noEntity from '../common/no-entity'
import entityRelationsBadge from '../common/entity-relations-badge'
import * as Cookies from 'tiny-cookie'

export default {
  name: 'categories',
  data: function () {
    return {
      categories: [],
      tobeArchivedCategory: {},
      tobeDeletedCategory: {},
      isUnfolded: false,
      documentStatusses: {
        'published': 'Gepubliceerd',
        'draft': 'In concept',
        'archived': 'Gearchiveerd'
      },
      attachmentStatusses: {
        'active': 'Gepubliceerd',
        'archived': 'Gearchiveerd'
      },
      statusFilter: 'active',
      statusses: {
        'active': 'Actief',
        'archived': 'Gearchiveerd'
      },
      disabled: false,
    }
  },
  methods: {
    getCategories: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('categories/flat?include=documents,attachments,positions,departments&limit=100&sort=-index&status=' + this.statusFilter).then((response) => {
        this.categories = response.data.data

        this.disabled = false
        this.$bus.$emit('loadingEvent', false)
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    editCategory: function (category) {
      // navigate to document edit
      this.$router.push({ name: 'categoryEdit', params: { id: category.id } })
    },
    archiveCategoryModal: function (category) {
      this.tobeArchivedCategory = category
      this.$bus.$emit('askEntityArchiveModalEvent', true)
    },
    archiveCategory: function (category) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Categorie', category.id, '/category')
    },
    deleteCategoryModal: function (category) {
      this.tobeDeletedCategory = category
      this.$bus.$emit('askEntityDeleteModalEvent', true)
    },
    deleteCategory: function (category) {
      // handle deleting through entity abstraction layer
      this.$entity.delete('Categorie', category.id, '/category')
    },
    fold: function (status) {
      this.isUnfolded = status
    },
    setStatusFilter: function (status) {
      this.statusFilter = status
    },
    moveUpCategory: function (category) {
      // move up and reload
      this.$bus.$emit('savingEvent', true)
      this.disabled = true
      this.$api.get('category/' + category.id + '/up').then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.warning('Categorie kon niet verplaatst worden, hij staat al bovenaan deze categorie.')
        } else {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Categorie ' + category.name + ' is succesvol verplaatst')

          // reload categories
          this.getCategories()
        }
      }, () => {
        this.$bus.$emit('savingEvent', false)
        this.disabled = false
      })
    },
    moveDownCategory: function (category) {
      // move up and reload
      this.$bus.$emit('savingEvent', true)
      this.disabled = true
      this.$api.get('category/' + category.id + '/down').then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.warning('Categorie kon niet verplaatst worden, hij staat al onderaan deze categorie.')
        } else {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Categorie ' + category.name + ' is succesvol verplaatst')

          // reload categories
          this.getCategories()
        }
      }, () => {
        this.$bus.$emit('savingEvent', false)
        this.disabled = false
      })
    },
    canMoveUp: function (index) {
      let category = this.categories[index]
      let categoryAbove = this.categories[index - 1]

      // return false if the category above is higher in hierarchy
      if (categoryAbove && categoryAbove.depth < category.depth) {
        return false
      }

      // walk over categories above to check if there are any same depth categories to move to
      for (let step = index - 1; step >= 0; step--) {
        if (this.categories[step] && this.categories[step].depth === category.depth) {
          return true
        }
      }

      return false
    },
    canMoveDown: function (index) {
      let category = this.categories[index]
      let categoryBelow = this.categories[index + 1]

      // return false if the category below is higher in hierarchy
      if (categoryBelow && categoryBelow.depth < category.depth) {
        return false
      }

      // walk over categories below to check if there are any same depth categories to move to
      for (let step = index + 1; step <= this.categories.length; step++) {
        if (this.categories[step] && this.categories[step].depth === category.depth) {
          return true
        }
      }

      return false
    },
    downloadStructurePdf: function () {
      var token = Cookies.get('access_token')
      window.location.href = process.env.VUE_APP_API_URL + '/categories/file?access_token=' + token
    },
    downloadStructureExcel: function () {
      var token = Cookies.get('access_token')
      window.location.href = process.env.VUE_APP_API_URL + '/categories/structure/file?access_token=' + token
    },
    createDocumentInCategory: function (category) {
       this.$bus.$emit('savingEvent', true)

      // create new document in draft and attach to category
      let newDocument = {}
      newDocument.name = 'Nieuw document'
      newDocument.status = 'draft'

      // post to api and forward
      this.$api.post('documents', newDocument).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Artikel niet aangemaakt, controleer de invoervelden')
        } else {
          newDocument = response.data.data
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Artikel ' + newDocument.name + ' is succesvol toegevoegd')

          // attach to selected category
          this.$entity.reattach(newDocument, { 'categories': [category.id] }, 'Categorieen', '/document/' + newDocument.id + '/categories')

          this.errors = {}
          this.$router.push('/document/' + newDocument.id)
        }
      }, (error) => {
        this.$bus.$emit('savedEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    createAttachmentInCategory: function (category) {
      this.$router.push({ name: 'attachmentCreate', params: { preselectedCategory: category } })
    }
  },
  mounted: function () {
    this.getCategories()

    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (category) => this.archiveCategory(category))
    // listen for archived event
    this.$bus.$on('entityArchivedEvent', () => this.getCategories())

    // listen for delete event
    this.$bus.$on('askEntityDeleteEvent', (category) => this.deleteCategory(category))
    // listen for deleted event
    this.$bus.$on('entityDeletedEvent', () => this.getCategories())

    // listen for fold/unfold event
    this.$bus.$on('askFoldEvent', (status) => this.fold(status))
    // listen for status filter change event emitted from a search bar
    this.$bus.$on('askStatusChangeEvent', (status) => this.setStatusFilter(status))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askEntityArchiveEvent')
    this.$bus.$off('entityArchivedEvent')

    this.$bus.$off('askEntityDeleteEvent')
    this.$bus.$off('entityDeletedEvent')

    this.$bus.$off('askFoldEvent')
    this.$bus.$off('askStatusChangeEvent')
  },
  watch: {
    statusFilter: function () {
      // perform new get if status is changed
      this.getCategories()
    }
  },
  components: {
    'index-bar': indexBar,
    'archive-modal': archiveModal,
    'delete-modal': deleteModal,
    'no-entity': noEntity,
    'entity-relations-badge': entityRelationsBadge
  }
}
</script>
