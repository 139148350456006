<template lang="html">
  <div>
    <article class="message" v-if="count === 0 && !loading">
      <div class="message-body has-text-centered">
        <div class="content">
          <span class="icon is-large">
            <i :class="`mdi mdi-36px mdi-${icon}`"></i>
          </span>
          <h1 class="is-size-4 has-text-weight-light mt-1">{{title}}</h1>
          <p>{{message}}</p>
        </div>
        <router-link class="button is-outlined" :to="createPath" v-if="createPath">
          <span class="icon">
            <i class="mdi mdi-plus-circle-outline"></i>
          </span>
          <span>{{button}}</span>
        </router-link>
      </div>
    </article>
    <div class="has-text-centered p-6" v-if="loading">
        <span class="spinner is-large m-6"></span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'noEntity',
  props: [
    'title',
    'button',
    'icon',
    'message',
    'createPath',
    'count'
  ],
  data: function () {
    return {
      loading: false
    }
  },
  created: function () {
    this.$bus.$on('globalLoadingEvent', this.onLoadingEvent)
  },
  methods: {
    onLoadingEvent: function (state) {
      this.loading = state
    },
  }
}
</script>
