<template lang="html">
  <div id="account">
    <div class="columns">
      <div class="column is-6">
        <div class="card is-rounded">
          <header class="card-header">
            <p class="card-header-title">
              Huidig pakket
            </p>
          </header>
          <div class="card-content" v-if="!loading">
            <i class="mdi mdi-package-variant mdi-24px has-text-primary"></i>
            <span class="title is-5 has-text-weight-light m-l-sm">{{ account.tier_name }}</span>
          </div>
          <div class="card-content is-paddingless">
            <table class="table is-fullwidth" v-if="!loading">
              <thead>
                <tr>
                  <th></th>
                  <th width="230">Gebruik</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lezers</td>
                  <td>
                    <span class="is-block">{{ account.usage_readers }} van {{ account.limit_readers }}</span>
                    <progress class="progress is-primary is-small m-t-sm" :value="readersUsage" max="100">15%</progress>
                  </td>
                </tr>
                <tr>
                  <td>Schrijvers</td>
                  <td>
                    <span class="is-block">{{ account.usage_writers }}  van {{ account.limit_writers }}</span>
                    <progress class="progress is-primary is-small m-t-sm" :value="writersUsage" max="100">3%</progress>
                  </td>
                </tr>
                <tr>
                  <td>Artikelen</td>
                  <td>
                    <span class="is-block">{{ account.usage_documents }} van {{ account.limit_documents }}</span>
                    <progress class="progress is-primary is-small m-t-sm" :value="documentsUsage" max="100">15%</progress>
                  </td>
                </tr>
                <tr>
                  <td>Opslagruimte</td>
                  <td>
                    <span class="is-block">{{ account.usage_storage }} van {{ account.limit_storage }}GB</span>
                    <progress class="progress is-primary is-small m-t-sm" :value="storageUsage" max="100">15%</progress>
                  </td>
                </tr>
                <tr>
                  <td>Dienstverleningsniveau</td>
                  <td>
                    <i class="mdi mdi-shield-half-full mdi-24px has-text-primary"></i>
                    <span class="m-l-sm">{{ account.sla_name }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card is-rounded mt-5">
          <header class="card-header">
            <p class="card-header-title">
              Huidige kosten
            </p>
          </header>
          <div class="card-content is-paddingless">
            <table class="table is-fullwidth" v-if="!loading">
              <tbody>
                <tr>
                  <td>Basisprijs</td>
                  <td>
                    <money-format :value="account.price_tier / 100"
                      locale="nl"
                      currency-code="EUR">
                    </money-format>
                  </td>
                </tr>
                <tr>
                  <td>Prijs voor {{ account.usage_readers }} lezers</td>
                  <td>
                    <money-format :value="account.price_readers * account.usage_readers / 100"
                      locale="nl"
                      currency-code="EUR">
                    </money-format>
                  </td>
                </tr>
                <tr>
                  <td>Prijs voor dienstverleningsniveau</td>
                  <td>
                    <money-format :value="account.price_sla / 100"
                      locale="nl"
                      currency-code="EUR">
                    </money-format>
                  </td>
                </tr>
                <tr>
                  <td>Totaal</td>
                  <td>
                    <strong>
                      <money-format :value="totalPrice"
                        locale="nl"
                        currency-code="EUR">
                      </money-format>
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="card is-rounded">
          <header class="card-header">
            <p class="card-header-title">
              Vormgeving
            </p>
          </header>
          <div class="card-content">
            <div class="columns">
              <div class="column">
                <span class="title is-5 has-text-weight-light"><i class="mdi mdi-cellphone"></i> App</span>
                <hr />
                <div class="field">
                  <label class="label">Achtergrond <i class="mdi mdi-help-circle has-text-info" v-tooltip="'De kleur van de achtergrond in de App'"></i></label>
                  <div class="control is-expanded">
                    <verte picker="square" model="hex" menuPosition="top" v-model="account.app_background_color"></verte>
                    <p v-for="(error, index) in errors.app_background_color" :key="index" class="help is-danger">{{ error }}</p>
                  </div>
                </div>
                <div class="field">
                  <label class="label">Knoppen <i class="mdi mdi-help-circle has-text-info" v-tooltip="'De kleur van de primaire knoppen in de App'"></i></label>
                  <div class="control is-expanded">
                    <verte picker="square" model="hex" menuPosition="top" v-model="account.app_button_color"></verte>
                    <p v-for="(error, index) in errors.app_button_color" :key="index" class="help is-danger">{{ error }}</p>
                  </div>
                </div>
              </div>
              <div class="column">
                <span class="title is-5 has-text-weight-light"><i class="mdi mdi-desktop-mac"></i> Web</span>
                <hr />
                <div class="field">
                  <label class="label">Achtergrond <i class="mdi mdi-help-circle has-text-info" v-tooltip="'De kleur van de achtergrond in de Web versie'"></i></label>
                  <div class="control is-expanded">
                    <verte picker="square" model="hex" menuPosition="top" v-model="account.web_background_color"></verte>
                    <p v-for="(error, index) in errors.web_background_color" :key="index" class="help is-danger">{{ error }}</p>
                  </div>
                </div>
                <div class="field">
                  <label class="label">Knoppen <i class="mdi mdi-help-circle has-text-info" v-tooltip="'De kleur van de primaire knoppen in de Web versie'"></i></label>
                  <div class="control is-expanded">
                    <verte picker="square" model="hex" menuPosition="top" v-model="account.web_button_color"></verte>
                    <p v-for="(error, index) in errors.web_button_color" :key="index" class="help is-danger">{{ error }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card is-rounded mt-5">
          <header class="card-header">
            <p class="card-header-title">
              Externe koppelingen
            </p>
          </header>
          <div class="card-content">
            <span class="title is-5 has-text-weight-light">AFAS</span>
            <article class="message is-info mt-4">
              <div class="message-body">
                  <p>Bij het instellen van de externe koppeling met AFAS worden <strong>afdelingen</strong>, <strong>lezers</strong> en <strong>functies</strong> (of een deel hiervan) automatisch iedere ochtend opgehaald uit de gekoppelde AFAS omgeving. Je kunt kiezen of je alleen de lezers of de lezers met afdeling en/of functie wilt ophalen. Hierbij gebeurt het volgende:</p>
                  <div class="content">
                    <ul>
                      <li><i>nieuwe</i> lezers worden aangemaakt in Handboek en ontvangen, optioneel, een uitnodigingsmail om een wachtwoord voor Handboek in te stellen.</li>
                      <li>van <i>bestaande</i> lezers in Handboek worden de gegevens bijgewerkt naar de aktuele informatie in AFAS.</li>
                      <li><i>bestaande</i> lezers in Handboek die niet meer in AFAS bekend zijn worden gedeactiveerd.</li>
                      <li><i>nieuwe</i> functies worden aangemaakt in Handboek en gekoppeld aan de ingestelde standaard afdeling.</li>
                      <li>van <i>bestaande</i> functies worden de gegevens bijgewerkt naar de aktuele informatie in AFAS.</li>
                      <li><i>bestaande</i> functies in Handboek die niet meer in AFAS bekend zijn worden gearchiveerd.</li>
                      <li><i>nieuwe</i> afdelingen worden aangemaakt in Handboek.</li>
                      <li>van <i>bestaande</i> afdelingen worden de gegevens bijgewerkt naar de aktuele informatie in AFAS.</li>
                      <li><i>bestaande</i> afdelingen in Handboek die niet meer in AFAS bekend zijn worden gearchiveerd.</li>
                    </ul>
                  </div>
                  <p><strong>Let op:</strong> bij het instellen van de koppeling worden <i>handmatig aangemaakte</i> lezers en functies in Handboek niet aangetast door de koppeling.</p>
              </div>
            </article>

            <div class="field">
              <label class="label">Gebruikersnaam <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Gebruik de AFAS omgevingsnaam van een productie omgeving. Gebruik hierbij alleen de cijfers. Bijvoorbeeld 12345 bij een productie omgeving met de naam O12345AA'"></i></label>
              <div class="control">
                <input class="input" type="text" v-model="account.pull_source_username" placeholder="Gebruikersnaam" v-bind:class="{ 'is-danger': errors.pull_source_username }">
              </div>
              <p v-for="(error, index) in errors.pull_source_username" class="help is-danger" :key="index">{{ error }}</p>
            </div>

            <div class="field">
              <label class="label">Token <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Gebruik de door AFAS gegenereerde token'"></i></label>
              <div class="control">
                <input class="input" type="text" v-model="account.pull_source_token" placeholder="Token" v-bind:class="{ 'is-danger': errors.pull_source_token }">
              </div>
              <p v-for="(error, index) in errors.pull_source_token" class="help is-danger" :key="index">{{ error }}</p>
            </div>

            <div class="field">
              <label class="label">Locatie voor ophalen van <i>lezers</i> <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Gebruik het gedeelte achter de basis URL van de koppeling. Bijvoorbeeld: ProfitRestServices/connectors/Profit_Employees'"></i></label>
              <div class="control">
                <input class="input" type="text" v-model="account.pull_readers_endpoint" placeholder="Locatie voor ophalen van lezers" v-bind:class="{ 'is-danger': errors.pull_readers_endpoint }">
              </div>
              <p class="has-text-grey mt-1">Bijvoorbeeld: ProfitRestServices/connectors/Profit_Employees</p>
              <p v-for="(error, index) in errors.pull_readers_endpoint" class="help is-danger" :key="index">{{ error }}</p>
            </div>

            <div class="field">
              <p>
                <span class="icon has-text-primary is-pulled-right" v-if="isPullDepartmentsEnabled">
                  <i class="mdi mdi-account-multiple mdi-18px"></i>
                </span> 
                <span class="icon has-text-grey-light is-pulled-right" v-if="!isPullDepartmentsEnabled">
                  <i class="mdi mdi-account-multiple mdi-18px"></i>
                </span> 
                <label class="label">Ophalen van afdelingen inschakelen</label>
              </p>
              <toggle-button
                :sync="true"
                v-model="isPullDepartmentsEnabled"
                :width="58"
                :height="25"
                :labels="{checked: 'Ja', unchecked: 'Nee'}" 
                :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
            </div>

            <div class="field" v-if="isPullDepartmentsEnabled">
              <label class="label">Locatie voor ophalen van <i>afdelingen</i> <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Gebruik het gedeelte achter de basis URL van de koppeling. Bijvoorbeeld: ProfitRestServices/connectors/Profit_Employees'"></i></label>
              <div class="control">
                <input class="input" type="text" v-model="account.pull_departments_endpoint" placeholder="Locatie voor ophalen van lezers" v-bind:class="{ 'is-danger': errors.pull_departments_endpoint }">
              </div>
              <p class="has-text-grey mt-1">Bijvoorbeeld: ProfitRestServices/connectors/Profit_Employees</p>
              <p v-for="(error, index) in errors.pull_departments_endpoint" class="help is-danger" :key="index">{{ error }}</p>
            </div>

            <div class="field">
              <p>
                <span class="icon has-text-primary is-pulled-right" v-if="isPullPositionsEnabled">
                  <i class="mdi mdi-account-card-details mdi-18px"></i>
                </span> 
                <span class="icon has-text-grey-light is-pulled-right" v-if="!isPullPositionsEnabled">
                  <i class="mdi mdi-account-card-details mdi-18px"></i>
                </span> 
                <label class="label">Ophalen van functies inschakelen</label>
              </p>
              <toggle-button
                :sync="true"
                v-model="isPullPositionsEnabled"
                :width="58"
                :height="25"
                :labels="{checked: 'Ja', unchecked: 'Nee'}" 
                :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
            </div>

            <div class="field" v-if="isPullPositionsEnabled">
              <label class="label">Locatie voor ophalen van <i>functies</i> <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Gebruik het gedeelte achter de basis URL van de koppeling. Bijvoorbeeld: ProfitRestServices/connectors/Profit_Employees'"></i></label>
              <div class="control">
                <input class="input" type="text" v-model="account.pull_positions_endpoint" placeholder="Locatie voor ophalen van lezers" v-bind:class="{ 'is-danger': errors.pull_positions_endpoint }">
              </div>
              <p class="has-text-grey mt-1">Bijvoorbeeld: ProfitRestServices/connectors/Profit_Employees</p>
              <p v-for="(error, index) in errors.pull_positions_endpoint" class="help is-danger" :key="index">{{ error }}</p>
            </div>

            <div class="field" v-if="isPullPositionsEnabled">
              <label class="label">Standaard afdeling voor nieuwe <i>functies</i> <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Nieuw opgehaalde functies uit de koppeling worden automatisch aan de hier ingestelde afdeling gekoppeld.'"></i></label>
              <div class="control is-expanded">
                <div class="select is-fullwidth">
                  <select v-model="account.pull_positions_default_department" v-bind:disabled="disabled">
                    <option v-for="(department, index) in departments" :value="department.id" :key="index">{{ department.name }}</option>
                  </select>
                </div>
                <p v-for="(error, index) in errors.pull_positions_default_department" class="help is-danger" :key="index">{{ error }}</p>
              </div>
            </div>

            <div class="field">
              <p>
                <span class="icon has-text-primary is-pulled-right" v-if="isPullInviteMailEnabled">
                  <i class="mdi mdi-email mdi-18px"></i>
                </span> 
                <span class="icon has-text-grey-light is-pulled-right" v-if="!isPullInviteMailEnabled">
                  <i class="mdi mdi-email-outline mdi-18px"></i>
                </span> 
                <label class="label">Uitnodigingsmail inschakelen <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Bij het uitschakelen van de automatische uitnodigingsmail wordt bij synchronisatie een willekeurig wachtwoord ingesteld.'"></i></label>
              </p>
              <toggle-button
                :sync="true"
                v-model="isPullInviteMailEnabled"
                :width="58"
                :height="25"
                :labels="{checked: 'Ja', unchecked: 'Nee'}" 
                :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
            </div>

            <div class="field">
              <p>
                <span class="icon has-text-primary is-pulled-right" v-if="isPullEnabled">
                  <i class="mdi mdi-cloud-sync mdi-18px"></i>
                </span> 
                <span class="icon has-text-grey-light is-pulled-right" v-if="!isPullEnabled">
                  <i class="mdi mdi-cloud-off-outline mdi-18px"></i>
                </span> 
                <label class="label">Koppeling inschakelen</label>
              </p>
              <toggle-button
                :sync="true"
                v-model="isPullEnabled"
                :width="58"
                :height="25"
                :labels="{checked: 'Ja', unchecked: 'Nee'}" 
                :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
            </div>

          </div>
        </div>
      </div>

      <savebar
        :entity="account"
        :entityName="'account'"
        :hideArchive="true">
      </savebar>

    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import MoneyFormat from 'vue-money-format'
import Verte from 'verte'
import savebar from '../savebar'
import ToggleButton from 'vue-js-toggle-button/src/Button'

export default {
  name: 'account',
  data: function () {
    return {
      account: {},
      departments: [],
      errors: {},
      loading: false,
      isPullEnabled: false,
      isPullPositionsEnabled: false,
      isPullDepartmentsEnabled: false,
      isPullInviteMailEnabled: false,
    }
  },
  methods: {
    getAccount: function () {
      this.$bus.$emit('loadingEvent', true)
      this.loading = true
      this.$api.get('account').then((response) => {
        this.account = response.data.data
        this.$bus.$emit('loadingEvent', false)
        this.loading = false

        this.isPullEnabled = Boolean(this.account.is_pull_enabled)
        this.isPullPositionsEnabled = Boolean(this.account.is_pull_positions_enabled)
        this.isPullDepartmentsEnabled = Boolean(this.account.is_pull_departments_enabled)
        this.isPullInviteMailEnabled = Boolean(this.account.is_pull_invite_mail_enabled)

      }, () => {
        this.$bus.$emit('loadingEvent', false)
        this.loading = false
      })
    },
    updateAccount: function () {
      this.$bus.$emit('savingEvent', true)

      this.$api.put('account', this.account).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.loading = false
          this.errors = response.data.errors
          this.$noty.warning('Controleer de invoervelden')
        } else {
          this.account = response.data.data

          this.$bus.$emit('savingEvent', false)
          this.$noty.success('Account instellingen succesvol toegevoegd')
          this.errors = {}
        }
      }, () => {
        this.$bus.$emit('savingEvent', false)
        this.loading = false
      })
    },
    getDepartments: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('departments?limit=100').then((response) => {
        for (let department of response.data.data) {
          this.departments.push({
            id: department.id,
            name: department.name
          })

          // also include subdepartments
          for (let subdepartment of department.descendants) {
            this.departments.push({
              id: subdepartment.id,
              name: subdepartment.name
            })
          }
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, () => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      })
    },
  },
  computed: {
    readersUsage: function () {
      if (this.account.limit_readers && this.account.usage_readers) {
        return Math.round((this.account.usage_readers / this.account.limit_readers) * 100)
      }
      return 0
    },
    writersUsage: function () {
      if (this.account.limit_writers && this.account.usage_writers) {
        return Math.round((this.account.usage_writers / this.account.limit_writers) * 100)
      }
      return 0
    },
    documentsUsage: function () {
      if (this.account.limit_documents && this.account.usage_documents) {
        return Math.round((this.account.usage_documents / this.account.limit_documents) * 100)
      }
      return 0
    },
    storageUsage: function () {
      if (this.account.limit_storage && this.account.usage_storage) {
        return Math.round((this.account.usage_storage / this.account.limit_storage) * 100)
      }
      return 0
    },
    totalPrice: function () {
      return (this.account.price_tier + (this.account.price_readers * this.account.usage_readers) + this.account.price_sla) / 100
    }
  },
  mounted: function () {
    this.getAccount()
    this.getDepartments()

    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', this.updateAccount)
  },
  watch: {
    isPullEnabled: function (status) {
      this.account.is_pull_enabled = status ? 1 : 0
    },
    isPullPositionsEnabled: function (status) {
      this.account.is_pull_positions_enabled = status ? 1 : 0
    },
    isPullDepartmentsEnabled: function (status) {
      this.account.is_pull_departments_enabled = status ? 1 : 0
    },
    isPullInviteMailEnabled: function (status) {
      this.account.is_pull_invite_mail_enabled = status ? 1 : 0
    },
  },
  beforeDestroy: function () {
    // stop listening
    this.$bus.$off('askSaveEvent')
  },
  components: {
    'money-format': MoneyFormat,
    'verte': Verte,
    'savebar': savebar,
    'toggle-button': ToggleButton
  }
}
</script>
