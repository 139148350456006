 <template lang="html">
  <div class="box is-paddingless" v-if="comment.reader">
    <h1>Reactie versturen</h1>

    <div class="tabs">
      <ul>
        <li v-on:click="toggleType('push')" v-bind:class="{ 'is-active': notification.type == 'push' }">
          <a>
            <span>Per push notificatie</span>
          </a>
        </li>
        <li v-on:click="toggleType('mail')" v-bind:class="{ 'is-active': notification.type == 'mail' }">
          <a>
            <span>Per e-mail</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="tab" v-show="notification.type == 'push'">

      <div v-if="comment.reader.data.push_id !== ''">
        <div class="field">
          <p>Verstuur een reactie op dit commentaar per <strong>push notificatie</strong>.</p>
        </div>
        <hr />

        <div class="field">
          <label class="label">Titel</label>
          <div class="control">
            <input class="input" type="text" v-model="notification.title" placeholder="Titel van notificatie" v-bind:class="{ 'is-danger': errors.title }">
          </div>
          <p v-for="(error, index) in errors.title" class="help is-danger" :key="index">{{ error }}</p>
        </div>

        <div class="field">
          <label class="label">Bericht</label>
          <div class="control">
            <textarea
              class="textarea"
              v-model="notification.content"
              v-bind:class="{ 'is-danger': errors.content }"
              placeholder="Reactie"
              rows="4">
            </textarea>
          </div>
          <p class="help">{{ contentLength }} van {{ maxContentLength }} tekens gebruikt</p>
          <p v-for="(error, index) in errors.content" class="help is-danger" :key="index">{{ error }}</p>
        </div>

        <div class="field">
          <a class="button is-primary" v-bind:class="{ 'is-disabled': sending }" v-on:click="sendNotification()">
            <span class="icon">
              <i class="mdi mdi-bell-outline"></i>
            </span>
            <span v-show="!sending">Verstuur push notificatie</span>
            <span v-show="sending === true">Bezig met versturen</span>
          </a>
        </div>

      </div>
      <article class="message is-info" v-if="comment.reader.data.push_id === ''">
        <div class="message-body">
          <span>
            {{ comment.reader.data.name }} kan helaas geen push notificaties ontvangen. Je kunt per e-mail reageren.
          </span>
        </div>
      </article>

    </div>

    <div class="tab" v-show="notification.type == 'mail'">
      <div class="field">
        <p>Verstuur een reactie op dit commentaar per <strong>e-mail</strong>.</p>
      </div>
      <hr />

      <div class="field">
        <label class="label">Titel</label>
        <div class="control">
          <input class="input" type="text" v-model="notification.title" placeholder="Titel van notificatie" v-bind:class="{ 'is-danger': errors.title }">
        </div>
        <p v-for="(error, index) in errors.title" class="help is-danger" :key="index">{{ error }}</p>
      </div>

      <div class="field">
        <label class="label">Bericht</label>
        <div class="control">
          <textarea
            class="textarea"
            v-model="notification.content"
            v-bind:class="{ 'is-danger': errors.content }"
            placeholder="Reactie"
            rows="4">
          </textarea>
        </div>
        <p v-for="(error, index) in errors.content" class="help is-danger" :key="index">{{ error }}</p>
      </div>

      <div class="field">
        <a class="button is-primary" v-bind:class="{ 'is-disabled': sending }" v-on:click="sendNotification()">
          <span class="icon">
            <i class="mdi mdi-email-outline"></i>
          </span>
          <span v-show="!sending">Verstuur e-mail</span>
          <span v-show="sending === true">Bezig met versturen</span>
        </a>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'commentNotification',
  props: [
    'comment'
  ],
  data: function () {
    return {
      isFolded: true,
      notification: {
        content: '',
        title: 'Reactie op commentaar',
        type: 'push'
      },
      maxContentLength: 150,
      errors: {},
      shouldNotify: false,
      sending: false
    }
  },
  methods: {
    sendNotification: function () {
      // compose the notification
      this.notification.comment_id = this.comment.id
      this.notification.reader_id = this.comment.reader_id

      this.$bus.$emit('savingEvent', true)
      // start updating
      this.sending = true
      this.$api.post('notifications', this.notification).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.sending = false
          this.errors = response.data.errors
          this.$noty.error('Reactie niet verzonden, er ging helaas iets mis.')
        } else {
          this.$bus.$emit('savingEvent', false)
          this.$emit('sent')
          this.sending = false
          this.$noty.success('Reactie op dit commentaar is verzonden')
          this.errors = {}
          this.notification.content = ''
        }
      }, (error) => {
        this.$bus.$emit('savingEvent', false)
        this.sending = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    toggleType: function (type) {
      this.notification.type = type
    }
  },
  computed: {
    // returns all steps exluding the current (to link to)
    contentLength: function () {
      return this.notification.content.length
    }
  },
  components: {}
}
</script>
