<template lang="html">
	<nav class="pagination is-small" role="navigation" aria-label="pagination" v-show="pages > 1">
    <ul class="pagination-list">
      <li v-for="n in pages" v-on:click="askPageChange(n)" :key="n">
        <a class="pagination-link" aria-current="n" :aria-label="n" v-bind:class="{ 'is-current': isCurrentPage(n) }" v-bind:disabled="loading">{{ n }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>

export default {
  name: 'indexPagination',
  data: function () {
    return {
      saving: false,
      keywords: '',
      loading: false
    }
  },
  props: [
    'pages',
    'currentPage'
  ],
  methods: {
    askPageChange: function (page) {
      this.$bus.$emit('askPageChangeEvent', page)
    },
    isCurrentPage: function (page) {
      if (page === this.currentPage) return true
    },
    onLoadingEvent: function (state) {
      this.loading = state
    },
  },
  mounted: function () {
    this.$bus.$on('loadingEvent', this.onLoadingEvent)
  },
  beforeDestroy: function () {
    // stop listening
    this.$bus.$off('loadingEvent', this.onLoadingEvent)
  }
}
</script>