<template lang="html">
  <div id="attachment">

    <archive-modal
      :entity="this.attachment"
      :entityName="this.attachment.name"
      entityType='attachment' />

    <version-modal
      :entity="this.attachment"
      :entityName="this.attachment.name" />

    <latest-version-modal />

    <div class="columns">
      <div class="column is-7">
        <div class="box">
          <div class="field">
            <label class="label title is-4 has-text-weight-light">{{ attachment.name }}
              <br /><span class="tag mt-2">Versie {{ attachment.version }}</span> <span class="tag mt-2 ml-1" :class="{'is-primary': attachment.status === 'active'}">{{ statusses[attachment.status] }}</span>
            </label>
            <div class="control">
              <input class="input" @focus="$event.target.select()" v-model="attachment.name" type="text" required placeholder="Naam van de bijlage"
                v-bind:class="{ 'is-danger': errors.name }"
                v-bind:disabled="disabled">
            </div>
            <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field" v-show="files.length > 0">
            <label class="label">Bestandsnaam</label>
            <div class="control">
              <input v-for="(file, index) in files" class="input" v-model="file.name" type="text" placeholder="Bestandsnaam van de bijlage"
                v-bind:disabled="true" :key="index">
            </div>
          </div>
          <div class="field">
            <label class="label">Bestand <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Handboek App opent alleen JPG, PNG, MP4 en PDF bestanden. Via Handboek Web kun je ook Excel en Word bestanden downloaden.'"></i></label>
            <file-upload
              ref="upload"
              v-model="files"
              :data="attachment"
              :timeout="3600 * 1000"
              :post-action="getUploadUrl()"
              :extensions="allowedExtensions"
              :headers="{'Authorization': 'Bearer ' + getToken() }">

              <a class="button is-outlined">
                <span class="icon">
                  <i class="mdi mdi-folder-open"></i>
                </span>
                <span>Kies bestand</span>
              </a>

            </file-upload>

            <div class="columns">
              <div class="column">
                <progress v-if="uploadProgress" ref="progress" class="progress is-large is-info" v-bind:class="{ 'is-danger': uploadError, 'is-success': uploadComplete }" :value="uploadProgress" max="100">{{ uploadProgress }}</progress>
              </div>
              <div class="column is-2">
                <div class="tags has-addons" v-if="isUploading">
                  <span class="tag is-info is-rounded" v-bind:class="{ 'is-danger': uploadError, 'is-success': uploadComplete }">{{ uploadSpeed }}</span>
                  <span class="tag is-rounded">kb/s</span>
                </div>
              </div>
            </div>

            <p v-show="!isUploading && !uploadError" class="help is-info">Na het kiezen van een bestand wordt <strong>{{ attachment.filename }}</strong> overschreven. De maximale grootte van een bijlage is 1GB. We adviseren bijlages zo klein mogelijk te houden.</p>
            <p v-show="!isUploading" v-for="(error, index) in errors.file" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Themas</label>
            <div class="control">
              <v-select ref="vselect" multiple
                :options="tags"
                label="name"
                v-model="attachedTags">

                <no-entity-autocomplete
                  title="Thema niet gevonden"
                  message="Probeer andere zoektermen of voeg een nieuw thema toe via het hoofdmenu."
                  icon="tag-outline"
                  slot="no-options" />

              </v-select>
            </div>
          </div>

          <div class="field">
            <label class="label">Categorie&euml;n <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Koppel de bijlage aan relevante categorie&euml;n zodat lezers er naar toe kunnen bladeren.'"></i></label>
            <div class="control">
              <v-select ref="vselect" multiple
                :options="categories"
                label="name"
                v-model="attachedCategories">

                <template slot="option" slot-scope="option">
                    {{ option.arrow }} {{ option.name }}
                </template>

                <no-entity-autocomplete
                  title="Categorie niet gevonden"
                  message="Probeer andere zoektermen of voeg een nieuwe categorie toe via het hoofdmenu."
                  icon="folder-outline"
                  slot="no-options" />

              </v-select>
            </div>
          </div>
          <div class="field">
            <label class="label">Personalisatie <i class="mdi mdi-help-circle has-text-info" @click="isVisiblePersonalisationHelp = !isVisiblePersonalisationHelp"></i></label>

            <personalisation-scope-select
              :attachedPositionsImmutable="this.attachedPositions"
              :attachedDepartmentsImmutable="this.attachedDepartments"
              @updatePositions="updateAttachedPositions"
              @updateDepartments="updateAttachedDepartments" />

            <article class="message is-info mt-4" v-if="isVisiblePersonalisationHelp">
              <div class="message-body">
                  <p>Je kunt één of meerdere functies of afdelingen aan een bijlage koppelen. Alleen lezers met deze functies of afdelingen zien deze bijlage in hun persoonlijke weergave. Ze kunnen de bijlage wel benaderen in de weergave voor iedereen. De {{ attachment.name }} bijlage is zichtbaar voor een lezer als:</p>
                  <div class="content">
                    <ul>
                      <li>Er geen functies of afdelingen zijn gekoppeld;</li>
                      <li>Als de functie van de lezer is gekoppeld;</li>
                      <li>Als de afdeling van de lezer is gekoppeld;</li>
                      <li>Als de functie van de lezer onder een gekoppelde afdeling valt.</li>
                    </ul>
                  </div>
              </div>
            </article>

          </div>
          <div class="field">
            <p>
              <span class="icon has-text-danger is-pulled-right" v-if="isPositionRestricted">
                <i class="mdi mdi-lock"></i>
              </span>
              <span class="icon has-text-primary is-pulled-right" v-if="!isPositionRestricted">
                <i class="mdi mdi-lock-open"></i>
              </span>
              <strong>Sluit de toegang af</strong> voor lezers die niet binnen de personalisatie vallen <i class="mdi mdi-help-circle has-text-info" v-tooltip="'De volledige bijlage wordt onbenaderbaar voor alle lezers die niet binnen de personalisatie vallen.'"></i>
            </p>
            <toggle-button
              :sync="true"
              v-model="isPositionRestricted"
              :width="58"
              :height="25"
              :labels="{checked: 'Ja', unchecked: 'Nee'}"
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
          </div>

          <div class="field">
            <label class="label">Geldigheid <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Op basis van de geldigheidsdatum worden notificaties over het verlopen van de bijlage verstuurd.'"></i></label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <datepicker
                  v-model="attachment.due_at"
                  name="due_at"
                  language="nl"
                  input-class="input"
                  :format="dueDateFormatter">
                </datepicker>
                <p v-for="(error, index) in errors.due_at" class="help is-danger" :key="index">{{ error }}</p>
              </div>
              <div class="control">
                <a class="button is-outlined" v-on:click="resetDueDate()">
                  <span class="icon">
                    <i class="mdi mdi-close"></i>
                  </span>
                  <span>Geldigheid opheffen</span>
                </a>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">
              Oriëntatie richting <i class="mdi mdi-help-circle has-text-info" v-tooltip="'PDF documenten worden op basis van de oriëntatie richting weergegeven in Handboek Web &amp; Backoffice. In de App wordt de oriëntatierichting genegeerd.'"></i>
              <span class="icon has-text-primary is-pulled-right">
                <i class="mdi mdi-phone-rotate-landscape" v-if="attachment.orientation === 'landscape'"></i>
                <i class="mdi mdi-phone-rotate-portrait" v-if="attachment.orientation === 'portrait'"></i>
              </span>
            </label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="attachment.orientation" v-bind:disabled="disabled">
                  <option v-for="(orientation, index) in orientations" :value="index" :key="index">{{ orientation }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.orientation" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="field">
            <label class="label">Zoekwoorden</label>
            <div class="control">
              <input class="input" v-model="attachment.keywords" type="text" placeholder="Zoekwoorden voor deze bijlage"
                v-bind:class="{ 'is-danger': errors.keywords }"
                v-bind:disabled="disabled">
            </div>
            <p v-for="(error, index) in errors.keywords" class="help is-danger" :key="index">{{ error }}</p>
          </div>

          <div class="field">
            <label class="label">Status</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="attachment.status" v-bind:disabled="disabled">
                  <option v-for="(status, index) in statusses" :value="index" :key="index">{{ status }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.status" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-5">

        <attachment-statistics
          :attachment="attachment" />

        <attachment-versions
          :attachment="attachment"
          :attachedVersions="attachedVersions" />

        <attachment-relations
          :attachment="attachment"
          :attachedDocuments="attachedDocuments"
          :disabled="disabled" />

      </div>
      <savebar
        :entity="attachment"
        entityName="Bijlage">
      </savebar>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
  @import '../../assets/scss/attachments/attachment-create.scss';
</style>

<script>

import savebar from '../savebar'
import vSelect from 'vue-select'
import attachmentRelations from './childs/attachment-relations'
import attachmentStatistics from './childs/attachment-statistics'
import attachmentVersions from './childs/attachment-versions'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import archiveModal from '../common/archive-modal'
import versionModal from '../common/version-modal'
import latestVersionModal from '../common/latest-version-modal'
import FileUpload from 'vue-upload-component'
import * as Cookies from 'tiny-cookie'
import noEntityAutocomplete from '../common/no-entity-autocomplete'
import personalisationScopeSelect from '../common/personalisation-scope-select'
import ToggleButton from 'vue-js-toggle-button/src/Button'

export default {
  name: 'attachmentEdit',
  data: function () {
    return {
      attachment: {},
      attachedTags: [],
      attachedDocuments: [],
      attachedPositions: [],
      attachedDepartments: [],
      attachedCategories: [],
      attachedVersions: [],
      errors: {},
      disabled: false,
      files: [],
      tags: [],
      categories: [],
      statusses: {
        'active': 'Gepubliceerd',
        'draft': 'In concept',
        'archived': 'Gearchiveerd'
      },
      orientations: {
        'portrait': 'Portret',
        'landscape': 'Landschap'
      },
      uploadError: false,
      uploadComplete: false,
      isUploading: false,
      isPositionRestricted: false,
      allowedExtensions: ['mp4', 'pdf', 'jpg', 'xls', 'xlsx', 'xlsm', 'doc', 'docx', 'zip', 'rdp', 'png'],
      isVisiblePersonalisationHelp: false
    }
  },
  methods: {
    getAttachment: function (attachmentId) {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('attachment/' + attachmentId + '?include=tags,documents,versions,positions,departments,categories').then((response) => {
        this.attachment = response.data.data

        this.attachedVersions = []
        for (let version of this.attachment.versions.data) {
          this.attachedVersions.push({
            id: version.id,
            version: version.version,
            is_latest_version: version.is_latest_version,
            name: version.name,
            writer: version.writer.data.name,
            status: version.status,
            updated_at: version.updated_at })
        }

        this.attachedTags = []
        for (let tag of this.attachment.tags.data) {
          this.attachedTags.push({
            id: tag.id,
            name: tag.name })
        }

        this.attachedPositions = []
        for (let position of this.attachment.positions.data) {
          this.attachedPositions.push({
            id: position.id,
            name: position.name })
        }

        this.attachedDepartments = []
        for (let department of this.attachment.departments.data) {
          this.attachedDepartments.push({
            id: department.id,
            name: department.name })
        }

        this.attachedCategories = []
        for (let category of this.attachment.categories.data) {
          this.attachedCategories.push({
            id: category.id,
            name: category.name + ' (' + category.id + ')' })
        }

        this.attachedDocuments = []
        for (let document of this.attachment.documents.data) {
          if (document.status === 'published') {
            this.attachedDocuments.push({
              id: document.id,
              version: document.version,
              name: document.name,
              writer: document.writer.data.name,
              updated_at: document.updated_at })
          }
        }

        this.isPositionRestricted = Boolean(this.attachment.is_position_restricted)

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getTags: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('tags?limit=100').then((response) => {
        for (let tag of response.data.data) {
          this.tags.push({
            id: tag.id,
            name: tag.name })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getCategories: function () {
      this.$bus.$emit('loadingEvent', true)
      this.categories = []
      this.disabled = true
      this.$api.get('categories/flat?limit=100&status=active').then((response) => {
        for (let category of response.data.data) {
          // markup arrow hierarchy structure
          let arrow = ''

          if (category.depth > 1) {
            for (var i = 0; i < category.depth; i++) {
              arrow = arrow + '-'
            }
            arrow = arrow + '>'
          }

          this.categories.push({
            id: category.id,
            name: category.name + ' (' + category.id + ')',
            depth: 0,
            arrow: arrow
          })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getToken: function () {
      return Cookies.get('access_token')
    },
    getUploadUrl: function () {
      return process.env.VUE_APP_API_URL + '/attachment/' + this.attachment.id
    },
    resetDueDate: function () {
      this.attachment.due_at = null
    },
    dueDateFormatter: function (date) {
      this.attachment.due_at = moment(date).format('YYYY-MM-DD HH:mm:ss')
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    updateAttachmentReplace: function () {
      if (this.files.length >= 1 && this.$refs.upload) {
        // append other form data
        this.$refs.upload.value[0].data = this.attachment
        // trigger upload & reset errors
        this.$refs.upload.active = true
        this.uploadError = false
        this.errors.file = false
        this.$bus.$emit('savingEvent', true)
      }
    },
    updateAttachment: function () {
      this.$bus.$emit('savingEvent', true)
      this.disabled = true
      this.$api.post('attachment/' + this.attachment.id, this.attachment).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Controleer de invoervelden')
        } else {
          this.$bus.$emit('savingEvent', false)

          // update attachment tags in separate api call
          this.updateAttachmentTags()

          // update attachment positions in separate api call
          this.updateAttachmentPositions()

          // update attachment departments in separate api call
          this.updateAttachmentDepartments()

          // update attachment positions in separate api call
          this.updateAttachmentCategories()

          this.disabled = false
          this.$noty.success('Bijlage ' + this.attachment.name + ' is succesvol opgeslagen')
          this.errors = {}
        }
      }, (error) => {
        this.$bus.$emit('savingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    updateAttachmentTags: function () {
      // attach selected tags
      if (this.attachedTags) {
        var selectedTags = []
        for (let tag of this.attachedTags) {
          selectedTags.push(tag.id)
        }
        this.$entity.reattach(this.attachment, { 'tags': selectedTags }, 'Thema\'s', '/attachment/' + this.attachment.id + '/tags')
      }
    },
    updateAttachmentPositions: function () {
      // attach selected positions
      if (this.attachedPositions) {
        var selectedPositions = []
        for (let position of this.attachedPositions) {
          selectedPositions.push(position.id)
        }
        this.$entity.reattach(this.attachment, { 'positions': selectedPositions }, 'Functies', '/attachment/' + this.attachment.id + '/positions')
      }
    },
    updateAttachmentDepartments: function () {
      // attach selected departments
      if (this.attachedDepartments) {
        var selectedDepartments = []
        for (let department of this.attachedDepartments) {
          selectedDepartments.push(department.id)
        }
        this.$entity.reattach(this.attachment, { 'departments': selectedDepartments }, 'Afdelingen', '/attachment/' + this.attachment.id + '/departments')
      }
    },
    updateAttachmentCategories: function () {
      // attach selected categories
      if (this.attachedCategories) {
        var selectedCategories = []
        for (let category of this.attachedCategories) {
          selectedCategories.push(category.id)
        }
        this.$entity.reattach(this.attachment, { 'categories': selectedCategories }, 'Categorieen', '/attachment/' + this.attachment.id + '/categories')
      }
    },
    toggleUploading: function (status) {
      this.isUploading = status
    },
    archiveAttachment: function (attachment) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Bijlage', attachment.id, '/attachment')
    },
    versionAttachment: function (attachment) {
      // handle versioning through entity abstraction layer
      this.$entity.version('Bijlage', attachment.id, '/attachment')
    },
    markLatestVersionAttachment: function (attachment) {
      // handle latest version marking through entity abstraction layer
      this.$entity.markLatestVersion('Bijlage', attachment.id, '/attachment')
    },
    goToNewVersion: function (attachment) {
      this.$router.push({ name: 'attachmentEdit', params: { id: attachment.id } })
    },
    saveAttachment: function () {
      // if a replacement file is set, update through upload API call, otherwise trough regular call
      if (this.files.length >= 1 && this.$refs.upload) {
        this.updateAttachmentReplace()
      } else {
        this.updateAttachment()
      }
    },
    updateAttachedPositions: function (selectedPositions) {
      this.attachedPositions = selectedPositions
    },
    updateAttachedDepartments: function (selectedDepartments) {
      this.attachedDepartments = selectedDepartments
    }
  },
  mounted: function () {
    this.$bus.$on('askSaveEvent', this.saveAttachment)
    this.$bus.$on('savingEvent', this.toggleUploading)
    this.$bus.$on('askEntityArchiveEvent', (attachment) => this.archiveAttachment(attachment))

    // listen for raise version event
    this.$bus.$on('askEntityRaiseVersionEvent', (attachment) => this.versionAttachment(attachment))
    // listen for mark latest version event
    this.$bus.$on('askEntityMarkLatestVersionEvent', (attachment) => this.markLatestVersionAttachment(attachment))
    // listen for new created version event
    this.$bus.$on('entityVersionedEvent', (attachment) => this.goToNewVersion(attachment))
    // listen for marked latest version event
    this.$bus.$on('entityMarkedLatestVersionEvent', () => this.getAttachment(this.$route.params.id))

    this.getTags()
    this.getCategories()
    this.getAttachment(this.$route.params.id)
  },
  computed: {
    // returns all steps exluding the current (to link to)
    uploadProgress: function () {
      if (this.files.length >= 1) return parseInt(this.files[0].progress)

      return 0
    },
    // returns upload speed if there is one running
    uploadSpeed: function () {
      if (this.files.length >= 1) return parseInt(this.files[0].speed / 1024)

      return 0
    }
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askSaveEvent')
    this.$bus.$off('askEntityArchiveEvent')
    this.$bus.$off('askEntityRaiseVersionEvent')
    this.$bus.$off('askEntityMarkLatestVersionEvent')
    this.$bus.$off('entityVersionedEvent')
    this.$bus.$off('entityMarkedLatestVersionEvent')
    this.$bus.$off('savingEvent')
  },
  watch: {
    'files' (files) {
      // grab the first item of the files (we have single file upload)
      let attachment = files[0]

      // handle succesfull upload
      if (attachment && attachment.success) {
        // handle possible validation problems (for file or other fields)
        if (attachment.response.code && attachment.response.code > 200) {
          this.errors = attachment.response.errors
          this.files = []
        } else {
          this.uploadComplete = true
          // upload complete, all set through upload api call, finish by setting tags
          this.updateAttachmentTags()
          // update current attachment object
          this.attachment = attachment.response.data
        }
        // reset upload component
        this.$refs.upload.clear()

        // emit not saving anymore
        this.$bus.$emit('savingEvent', false)
      }

      // handle upload errors
      if (attachment && attachment.error.length >= 2) {
        switch (attachment.error) {
          // denied response, in case of validation error
          case 'denied':
            this.errors = attachment.response.errors
            this.files = []
            break

          // denied based on extension filter
          case 'extension':
            this.uploadError = true
            this.errors.file = ['Het gekozen bestandstype wordt niet ondersteunt, upload PDF, MP4, JPG, Excel, Word bestanden.']
            this.files = []
            break

          // all other responses
          default:
            this.uploadError = true
            this.errors.file = ['Het uploaden is helaas misgegaan. Probeer een kleiner bestand te uploaden of gebruik een snellere verbinding.']
            this.files = []
            break
        }

        // emit not saving anymore
        this.$bus.$emit('savingEvent', false)
      }
    },
    isPositionRestricted: function (status) {
      this.attachment.is_position_restricted = status ? 1 : 0
    },
    '$route' (to) {
      this.getAttachment(to.params.id)
    }
  },
  components: {
    'savebar': savebar,
    'v-select': vSelect,
    'attachment-relations': attachmentRelations,
    'attachment-statistics': attachmentStatistics,
    'attachment-versions': attachmentVersions,
    'datepicker': Datepicker,
    'archive-modal': archiveModal,
    'file-upload': FileUpload,
    'no-entity-autocomplete': noEntityAutocomplete,
    'version-modal': versionModal,
    'latest-version-modal': latestVersionModal,
    'personalisation-scope-select': personalisationScopeSelect,
    'toggle-button': ToggleButton
  }
}
</script>
