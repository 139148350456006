<template lang="html">
  <div id="category">

    <icon-picker-modal />

    <div class="columns">
      <div class="column is-7">
        <div class="box">
          <div class="field">
            <label class="label title is-4 has-text-weight-light">{{ category.name }}</label>
            <div class="control">
              <input class="input" type="text" ref="name" v-model="category.name" placeholder="Naam van de categorie"
                  v-bind:class="{ 'is-danger': errors.name }"
                  v-on:keyup.enter="createCategory()">
            </div>
            <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Omschrijving</label>
            <div class="control">
              <textarea class="textarea" v-model="category.description" placeholder="Omschrijving van de categorie"
                v-bind:class="{ 'is-danger': errors.description }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="createCategory()">
              </textarea>
            </div>
            <p v-for="(error, index) in errors.description" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Bovenliggende categorie <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Je kunt een subcategorie en daarmee een hierarchie maken door een bovenliggende categorie te kiezen.'"></i></label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="category.parent_id" v-bind:disabled="disabled">
                  <option :value="null">Geen</option>
                  <option v-for="(category, index) in categories" :key="index" :value="category.id"><span v-for="index in category.depth" :key="index">-</span><span v-if="category.depth > 0">> </span>{{ category.name }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.parent_id" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Personalisatie <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Je kunt één of meerdere functies of afdelingen aan een categorie koppelen. Alleen lezers met deze functies of afdelingen zien de categorie in hun persoonlijke weergave.'"></i></label>

            <personalisation-scope-select
              :attachedPositionsImmutable="this.attachedPositions"
              :attachedDepartmentsImmutable="this.attachedDepartments"
              @updatePositions="updateAttachedPositions"
              @updateDepartments="updateAttachedDepartments" />

          </div>
          <div class="field">
            <p>
              <span class="icon has-text-danger is-pulled-right" v-if="isPositionRestricted">
                <i class="mdi mdi-lock"></i>
              </span>
              <span class="icon has-text-primary is-pulled-right" v-if="!isPositionRestricted">
                <i class="mdi mdi-lock-open"></i>
              </span>
              <strong>Beperk de toegang</strong> tot lezers met de gekozen functies of van de gekozen afdelingen <i class="mdi mdi-help-circle has-text-info" v-tooltip="'De volledige categorie wordt verborgen voor alle lezers die niet een van de gekozen functies hebben.'"></i>
            </p>
            <toggle-button
              :sync="true"
              v-model="isPositionRestricted"
              :width="58"
              :height="25"
              :labels="{checked: 'Ja', unchecked: 'Nee'}"
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
          </div>
          <div class="field">
            <label class="label">Icoon <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Kies het icoon waarmee deze categorie in de App en op het Web wordt aangeduid'"></i></label>
            <div class="control is-expanded">
              <input type="hidden" v-model="category.icon" />
              <a class="button is-outlined" v-on:click="iconPickModal">
                <span class="icon">
                  <i :class="'mdi mdi-' + category.icon"></i>
                </span>
                <span>Wijzig icoon</span>
              </a>
              <p v-for="(error, index) in errors.icon" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Kleur <i class="mdi mdi-help-circle has-text-info" v-tooltip="'De kleur waarmee de categorie en/of het categorie icoon wordt aangeduid'"></i></label>
            <div class="control is-expanded">
              <verte picker="square" model="hex" menuPosition="top" v-model="category.color"></verte>
              <p v-for="(error, index) in errors.color" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Afbeelding <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Kies het icoon waarmee deze categorie in de App en op het Web wordt aangeduid'"></i></label>
            <div class="control is-expanded">
              <a class="button is-danger is-outlined mr-2" v-on:click="deleteImage" v-if="category.image_base64">
                <span class="icon">
                  <i class="mdi mdi-delete-forever"></i>
                </span>
                <span>Afbeelding verwijderen</span>
              </a>

              <image-picker-modal @pick="selectImage" />

              <p v-for="(error, index) in errors.image" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field" v-if="$root.$data.writer.isAdmin">
            <label class="label">Schrijvers in beheer <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Koppel de schrijvers die rechten hebben om deze categorie, met onderliggende categorieën en de informatie daarbinnen, te mogen beheren. Geen selectie geeft alle schrijvers toegang.'"></i></label>
            <div class="control">
              <v-select ref="vselect" multiple
                :options="writers"
                label="name"
                v-model="attachedWriters">

                <template slot="option" slot-scope="option">
                    {{ option.arrow }} {{ option.name }}
                </template>

                <no-entity-autocomplete
                  title="Schrijver niet gevonden"
                  message="Probeer andere zoektermen."
                  icon="account-edit"
                  slot="no-options" />

              </v-select>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-5"></div>
      <savebar
        :entity="category"
        entityName="Afdeling">
      </savebar>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import savebar from '../savebar'
import personalisationScopeSelect from '../common/personalisation-scope-select'
import iconPickerModal from '../common/icon-picker-modal'
import imagePickerModal from '../common/image-picker-modal'
import Verte from 'verte'
import ToggleButton from 'vue-js-toggle-button/src/Button'
import noEntityAutocomplete from '../common/no-entity-autocomplete'
import vSelect from 'vue-select'

export default {
  name: 'categoryCreate',
  data: function () {
    return {
      category: {
        icon: 'book-open-variant',
        image_base64: null,
        color: '#00d1b2',
        is_position_restricted: false
      },
      writers: [],
      attachedWriters: [],
      attachedPositions: [],
      attachedDepartments: [],
      isPositionRestricted: false,
      categories: [],
      positions: [],
      errors: {},
      disabled: false
    }
  },
  methods: {
    createCategory: function () {
      this.$bus.$emit('savingEvent', true)
      this.disabled = true
      this.$api.post('categories', this.category).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Controleer de invoervelden')
        } else {
          if (this.attachedPositions.length > 0) {
            // handle attachment of positions through entity abstraction layer
            var selectedPositions = []
            for (let position of this.attachedPositions) {
              selectedPositions.push(position.id)
            }
            this.$entity.attach({}, { 'positions': selectedPositions }, 'Functies', '/category/' + response.data.data.id + '/positions')
          }

          if (this.attachedDepartments.length > 0) {
            // handle attachment of departments through entity abstraction layer
            var selectedDepartments = []
            for (let department of this.attachedDepartments) {
              selectedDepartments.push(department.id)
            }
            this.$entity.attach({}, { 'departments': selectedDepartments }, 'Afdelingen', '/category/' + response.data.data.id + '/departments')
          }

          if (this.attachedWriters.length > 0 && this.$root.$data.writer.isAdmin) {
            // handle attachment of writers through entity abstraction layer
            var selectedWriters = []
            for (let writer of this.attachedWriters) {
              selectedWriters.push(writer.id)
            }
            this.$entity.attach({}, { 'writers': selectedWriters }, 'Schrijvers', '/category/' + response.data.data.id + '/writers')
          }

          this.category = response.data.data
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Categorie ' + this.category.name + ' is succesvol toegevoegd')
          this.errors = {}
          this.$router.push('/categories')
        }
      }, () => {
        this.$bus.$emit('savingEvent', false)
        this.disabled = false
      })
    },
    getCategories: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('categories/flat?limit=100&status=active').then((response) => {
        this.categories = response.data.data
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getWriters: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('writers?limit=100').then((response) => {
        this.writers = response.data.data
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    updateAttachedPositions: function (selectedPositions) {
      this.attachedPositions = selectedPositions
    },
    updateAttachedDepartments: function (selectedDepartments) {
      this.attachedDepartments = selectedDepartments
    },
    iconPickModal: function () {
      this.$bus.$emit('askIconPickerModalEvent', true)
    },
    selectIcon: function (icon) {
      // set reactive
      this.$set(this.category, 'icon', icon)
    },
    selectImage: function (image) {
      this.category.image_base64 = image
    },
    deleteImage: function () {
      this.category.image_base64 = null
    }
  },
  mounted: function () {
    this.getCategories()
    this.getWriters()

    // set attachedPositions to nothing to trigger select component
    this.attachedPositions = []

    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', this.createCategory)
    // listen for icon pick event
    this.$bus.$on('askIconPickEvent', (icon) => this.selectIcon(icon))

    this.$refs.name.focus()
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askSaveEvent')
    this.$bus.$off('askIconPickEvent')
  },
  components: {
    'savebar': savebar,
    'personalisation-scope-select': personalisationScopeSelect,
    'icon-picker-modal': iconPickerModal,
    'image-picker-modal': imagePickerModal,
    'verte': Verte,
    'toggle-button': ToggleButton,
    'v-select': vSelect,
    'no-entity-autocomplete': noEntityAutocomplete
  },
  watch: {
    isPositionRestricted: function (status) {
      this.category.is_position_restricted = status ? 1 : 0
    }
  }
}
</script>
