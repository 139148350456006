<template lang="html">
  <div id="notification">
    <div class="box is-paddingless">
      <h1>Notificatie versturen
        <a class="button is-pulled-right is-clearfix" style="margin-bottom: 1em" @click="toggleFoldGeneral">
          <span class="icon" v-if="isFoldedGeneral">
            <i class="mdi mdi-chevron-down"></i>
          </span>
          <span v-if="isFoldedGeneral">Toon alle velden</span>
          <span class="icon" v-if="!isFoldedGeneral">
            <i class="mdi mdi-chevron-up"></i>
          </span>
          <span v-if="!isFoldedGeneral">Verberg velden</span>
        </a>
      </h1>

      <div class="tabs">
        <ul>
          <li v-on:click="toggleType('push')" v-bind:class="{ 'is-active': notification.type == 'push' }">
            <a>
              <span>Per push notificatie</span>
            </a>
          </li>
          <li v-on:click="toggleType('mail')" v-bind:class="{ 'is-active': notification.type == 'mail' }">
            <a>
              <span>Per e-mail</span>
            </a>
          </li>
        </ul>
      </div>

      <div class="tab" v-show="notification.type == 'push'">

        <div>
          <div class="field">
            <p>Verstuur een bericht via een <strong>push notificatie</strong>. Houd er rekening mee dat niet iedere lezer push notificaties op het toestel heeft ingeschakeld.</p>
          </div>
          <hr />

          <label class="label m-l-sm">Functies <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Standaard krijgt iedere lezer een bericht. Selecteer bepaalde functies om alleen die lezers te notificeren.'"></i></label>
          <position-select @update="setSelectedPositions"/>

          <div class="columns" v-if="!isFoldedGeneral">
            <div class="column is-half">
              <div class="field">
                <label class="label">iOS versie <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Notificeer alleen lezers met een apparaat met een specifieke iOS versie.'"></i></label>
                <div class="control">
                  <input class="input" type="text" v-model="notification.ios_min_version" placeholder="Minimale iOS versie" v-bind:class="{ 'is-danger': errors.ios_min_version }">
                </div>
                <div class="control">
                  <input class="input" type="text" v-model="notification.ios_max_version" placeholder="Maximale iOS versie" v-bind:class="{ 'is-danger': errors.ios_max_version }">
                </div>
                <p v-for="(error, index) in errors.ios_min_version" class="help is-danger" :key="index">{{ error }}</p>
                <p v-for="(error, index) in errors.ios_max_version" class="help is-danger" :key="index">{{ error }}</p>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">Android versie <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Notificeer alleen lezers met een apparaat met een specifieke Android versie.'"></i></label>
                <div class="control">
                  <input class="input" type="text" v-model="notification.android_min_version" placeholder="Minimale Android versie" v-bind:class="{ 'is-danger': errors.android_min_version }">
                </div>
                <div class="control">
                  <input class="input" type="text" v-model="notification.android_max_version" placeholder="Maximale Android versie" v-bind:class="{ 'is-danger': errors.android_max_version }">
                </div>
                <p v-for="(error, index) in errors.android_min_version" class="help is-danger" :key="index">{{ error }}</p>
                <p v-for="(error, index) in errors.android_max_version" class="help is-danger" :key="index">{{ error }}</p>
              </div>
            </div>
          </div>


          <div class="field">
            <label class="label">Titel</label>
            <div class="control">
              <input class="input" type="text" v-model="notification.title" placeholder="Titel van notificatie" v-bind:class="{ 'is-danger': errors.title }">
            </div>
            <p v-for="(error, index) in errors.title" class="help is-danger" :key="index">{{ error }}</p>
          </div>

          <div class="field">
            <label class="label">Bericht</label>
            <div class="control">
              <textarea
                class="textarea"
                v-model="notification.content"
                v-bind:class="{ 'is-danger': errors.content }"
                placeholder="Bericht"
                rows="4">
              </textarea>
            </div>
            <p class="help">{{ contentLength }} van {{ maxContentLength }} tekens gebruikt</p>
            <p v-for="(error, index) in errors.content" class="help is-danger" :key="index">{{ error }}</p>
          </div>

          <div class="field">
            <toggle-button :value="false"
              :sync="true"
              v-model="shouldNotify"
              :width="105"
              :height="25"
              :labels="{checked: 'Bevestigd', unchecked: 'Bevestigen'}"
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
          </div>

          <hr v-if="shouldNotify" />

          <div class="field">
            <a class="button is-primary" v-if="shouldNotify" v-bind:class="{ 'is-disabled': sending }" v-on:click="sendNotification()">
              <span class="icon">
                <i class="mdi mdi-bell-outline"></i>
              </span>
              <span v-show="!sending">Verstuur push notificatie</span>
              <span v-show="sending === true">Bezig met versturen</span>
            </a>
          </div>

        </div>
      </div>

      <div class="tab" v-show="notification.type == 'mail'">
        <div class="field">
          <p>Verstuur een bericht per <strong>e-mail</strong>.</p>
        </div>
        <hr />

        <label class="label m-l-sm">Functies <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Standaard krijgt iedere lezer een bericht. Selecteer bepaalde functies om alleen die lezers te notificeren.'"></i></label>
        <position-select @update="setSelectedPositions"/>

        <div class="columns" v-if="!isFoldedGeneral">
          <div class="column is-half">
            <div class="field">
              <label class="label">iOS versie <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Notificeer alleen lezers met een apparaat met een specifieke iOS versie.'"></i></label>
              <div class="control">
                <input class="input" type="text" v-model="notification.ios_min_version" placeholder="Minimale iOS versie" v-bind:class="{ 'is-danger': errors.ios_min_version }">
              </div>
              <div class="control">
                <input class="input" type="text" v-model="notification.ios_max_version" placeholder="Maximale iOS versie" v-bind:class="{ 'is-danger': errors.ios_max_version }">
              </div>
              <p v-for="(error, index) in errors.ios_min_version" class="help is-danger" :key="index">{{ error }}</p>
              <p v-for="(error, index) in errors.ios_max_version" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Android versie <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Notificeer alleen lezers met een apparaat met een specifieke Android versie.'"></i></label>
              <div class="control">
                <input class="input" type="text" v-model="notification.android_min_version" placeholder="Minimale Android versie" v-bind:class="{ 'is-danger': errors.android_min_version }">
              </div>
              <div class="control">
                <input class="input" type="text" v-model="notification.android_max_version" placeholder="Maximale Android versie" v-bind:class="{ 'is-danger': errors.android_max_version }">
              </div>
              <p v-for="(error, index) in errors.android_min_version" class="help is-danger" :key="index">{{ error }}</p>
              <p v-for="(error, index) in errors.android_max_version" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Titel</label>
          <div class="control">
            <input class="input" type="text" v-model="notification.title" placeholder="Titel van notificatie" v-bind:class="{ 'is-danger': errors.title }">
          </div>
          <p v-for="(error, index) in errors.title" class="help is-danger" :key="index">{{ error }}</p>
        </div>

        <div class="field">
          <label class="label">Bericht</label>
          <div class="control">
            <textarea
              class="textarea"
              v-model="notification.content"
              v-bind:class="{ 'is-danger': errors.content }"
              placeholder="Bericht"
              rows="4">
            </textarea>
          </div>
          <p v-for="(error, index) in errors.content" class="help is-danger" :key="index">{{ error }}</p>
        </div>

        <div class="field">
          <toggle-button :value="false"
            :sync="true"
            v-model="shouldNotify"
            :width="105"
            :height="25"
            :labels="{checked: 'Bevestigd', unchecked: 'Bevestigen'}"
            :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
        </div>

        <hr v-if="shouldNotify" />

        <div class="field">
          <a class="button is-primary" v-if="shouldNotify" v-bind:class="{ 'is-disabled': sending }" v-on:click="sendNotification()">
            <span class="icon">
              <i class="mdi mdi-email-outline"></i>
            </span>
            <span v-show="!sending">Verstuur e-mail</span>
            <span v-show="sending === true">Bezig met versturen</span>
          </a>
        </div>
      </div>

    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import ToggleButton from 'vue-js-toggle-button/src/Button'
import positionSelect from '../common/position-select'

export default {
  name: 'notificationsCreate',
  data: function () {
    return {
      notification: {
        content: '',
        title: '',
        type: 'mail',
        positions: []
      },
      maxContentLength: 150,
      errors: {},
      shouldNotify: false,
      sending: false,
      isFoldedGeneral: true
    }
  },
  methods: {
    sendNotification: function () {
      this.$bus.$emit('savingEvent', true)
      // start updating
      this.sending = true
      this.$api.post('notifications', this.notification).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.sending = false
          this.errors = response.data.errors
          this.$noty.warning('Notificaties niet verzonden, er ging helaas iets mis.')
        } else {
          this.$bus.$emit('savingEvent', false)
          this.$emit('sent')
          this.sending = false
          this.shouldNotify = false
          this.$noty.success(response.data.count + ' notificatie(s) verzonden')
          this.errors = {}
          this.notification.content = ''
        }
      }, (error) => {
        this.$bus.$emit('savingEvent', false)
        this.sending = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    toggleType: function (type) {
      this.notification.type = type
    },
    toggleFoldGeneral: function () {
      if (this.isFoldedGeneral) {
        this.isFoldedGeneral = false
      } else {
        this.isFoldedGeneral = true
      }
    },
    setSelectedPositions: function (selectedPositions) {
      this.notification.positions = []
      for (let position of selectedPositions) {
        this.notification.positions.push(position.id)
      }
    }
  },
  computed: {
    // returns all steps exluding the current (to link to)
    contentLength: function () {
      return this.notification.content.length
    }
  },
  components: {
    'toggle-button': ToggleButton,
    'position-select': positionSelect
  }
}
</script>
