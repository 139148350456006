<template lang="html">
  <nav class="box has-background-light is-shadowless">
    <div class="is-flex is-justify-content-space-between">
      <div class="field has-addons m-0">
        <div class="control" v-bind:class="{ 'is-loading': loading }" >
          <input class="input" type="text" placeholder="Zoekwoorden" v-model="keywords">
        </div>
        <div class="control">
          <div class="select is-multiple">

            <v-select multiple
                      :options="categories"
                      label="name"
                      placeholder="Categorie"
                      class="v-select-navbar"
                      v-model="selectedCategories"
                      @input="askCategoryFilterChange">

              <template slot="option" slot-scope="option">
                {{ option.arrow }} {{ option.name }}
              </template>

              <no-entity-autocomplete
                title="Categorie niet gevonden"
                message="Probeer andere zoektermen."
                icon="folder-outline"
                slot="no-options" />
            </v-select>

          </div>
        </div>
        <div class="control">
          <div class="select is-multiple">

            <v-select multiple
                      :options="positions"
                      label="name"
                      placeholder="Functie"
                      class="v-select-navbar"
                      v-model="selectedPositions"
                      @input="askPositionFilterChange">

              <no-entity-autocomplete
                title="Functie niet gevonden"
                message="Probeer andere zoektermen."
                icon="account-card-details"
                slot="no-options" />
            </v-select>

          </div>
        </div>
        <div class="control">
          <div class="select is-multiple">

            <v-select multiple
                      :options="tags"
                      label="name"
                      placeholder="Thema"
                      class="v-select-navbar"
                      v-model="selectedTags"
                      @input="askTagFilterChange">

              <no-entity-autocomplete
                title="Thema niet gevonden"
                message="Probeer andere zoektermen."
                icon="tag-outline"
                slot="no-options" />
            </v-select>

          </div>
        </div>
        <div class="control">
          <div class="select">
            <select name="status" v-model="activeStatus" v-on:change="askStatusChange(activeStatus)">
              <option v-for="(status, key) in statusses" v-on:click="askStatusChange(key)" :value="key" :key="key">{{ status }}</option>
            </select>
          </div>
        </div>
        <div class="control">
          <div class="select">
            <select name="ownership" v-model="activeOwnership" v-on:change="askOwnershipChange(activeOwnership)">
              <option value="me">Mijn {{ entityName }}</option>
              <option value="all">Alle {{ entityName }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="is-flex is-justify-content-end">
        <div class="control ml-2">
          <p class="control">
            <router-link class="button is-primary" :to="createPath" v-if="createPath">
                <span class="icon">
                  <i class="mdi mdi-plus-circle-outline"></i>
                </span>
              <span>{{ createText }}</span>
            </router-link>
            <a class="button is-primary" v-on:click="createMethod" v-if="createMethod">
                <span class="icon">
                  <i class="mdi mdi-plus-circle-outline"></i>
                </span>
              <span>{{ createText }}</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

import _ from 'lodash'
import vSelect from 'vue-select'
import noEntityAutocomplete from '../common/no-entity-autocomplete'

export default {
  name: 'indexBar',
  data: function () {
    return {
      keywords: '',
      loading: false,
      categories: [],
      positions: [],
      tags: [],
      selectedCategories: [],
      selectedPositions: [],
      selectedTags: []
    }
  },
  props: [
    'createPath',
    'createMethod',
    'createText',
    'entityName',
    'activeStatus',
    'activeOwnership',
    'statusses'
  ],
  methods: {
    checkStatus: function (status) {
      if (this.activeStatus === status) {
        return true
      } else {
        return false
      }
    },
    checkOwnership: function (ownership) {
      if (this.activeOwnership === ownership) {
        return true
      } else {
        return false
      }
    },
    askSearch: function () {
      this.$bus.$emit('askSearchEvent', this.keywords)
    },
    askStatusChange: function (status) {
      this.$bus.$emit('askStatusChangeEvent', status)
    },
    askCategoryFilterChange: function () {
      this.$bus.$emit('askCategoryFilterChangeEvent', this.selectedCategories)
    },
    askPositionFilterChange: function () {
      this.$bus.$emit('askPositionFilterChangeEvent', this.selectedPositions)
    },
    askOwnershipChange: function (status) {
      this.$bus.$emit('askOwnershipChangeEvent', status)
    },
    askTagFilterChange: function () {
      this.$bus.$emit('askTagFilterChangeEvent', this.selectedTags)
    },
    onLoadingEvent: function (state) {
      this.loading = state
    },
    getCategories: function () {
      this.$bus.$emit('loadingEvent', true)
      this.categories = []
      this.disabled = true
      this.$api.get('categories/flat?limit=100&status=active').then((response) => {
        for (let category of response.data.data) {
          // markup arrow hierarchy structure
          let arrow = ''

          if (category.depth > 1) {
            for (var i = 0; i < category.depth; i++) {
              arrow = arrow + '-'
            }
            arrow = arrow + '>'
          }

          this.categories.push({
            id: category.id,
            name: category.name + ' (' + category.id + ')',
            depth: 0,
            arrow: arrow
          })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getPositions: function () {
      this.$bus.$emit('loadingEvent', true)
      this.positions = []
      this.disabled = true
      this.$api.get('positions?limit=100').then((response) => {
        for (let position of response.data.data) {
          this.positions.push({
            id: position.id,
            name: position.name
          })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getTags: function () {
      this.$bus.$emit('loadingEvent', true)
      this.positions = []
      this.disabled = true
      this.$api.get('tags?limit=100').then((response) => {
        for (let tag of response.data.data) {
          this.tags.push({
            id: tag.id,
            name: tag.name
          })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    }
  },
  mounted: function () {
    this.getCategories()
    this.getPositions()
    this.getTags()
  },
  created: function () {
    this.$bus.$on('loadingEvent', this.onLoadingEvent)
  },
  watch: {
    keywords: _.debounce(function () {
      this.askSearch()
    }, 1000)
  },
  components: {
    'v-select': vSelect,
    'no-entity-autocomplete': noEntityAutocomplete
  }
}
</script>
