<template lang="html">
  <div class="box">

    <index-bar
      createPath="/departments/create"
      createText="Afdeling toevoegen"
      foldEntity="position"
      foldEntityName="functies" 
      :activeStatus="statusFilter"
      :statusses="statusses" />

    <archive-modal
      :entity="this.tobeArchivedDepartment"
      :entityName="this.tobeArchivedDepartment.name" 
      entityType='department' />

    <delete-modal
      :entity="this.tobeDeletedDepartment"
      :entityName="this.tobeDeletedDepartment.name" 
      entityType='department' />

    <table class="table is-fullwidth" v-if="departments.length >= 1">
      <thead>
        <tr>
          <th><abbr title="Naam">Naam</abbr></th>
          <th><abbr title="Omschrijving">Omschrijving</abbr></th>
          <th><abbr title="Functies">Functies</abbr></th>
          <th><abbr title="Lezers">Lezers</abbr></th>
          <th><abbr title="Bron">Bron</abbr></th>
          <th></th>
        </tr>
      </thead>
      <tbody v-for="(department) in departments" :key="department.id">
        <tr v-on:click="editDepartment(department)">
          <td>
            <span v-show="department.descendants.length > 0"><i class="mdi mdi-chevron-down"></i> {{ department.name }}</span>
            <span v-show="department.descendants.length === 0"><i class="mdi mdi-chevron-right"></i> {{ department.name }}</span>
          </td>
          <td>{{ department.description }}</td>
          <td>
            <span class="tag is-info" v-show="department.positions.data.length > 0"> {{ department.positions.data.length }}</span>
            <span class="tag is-danger" v-show="department.positions.data.length === 0" v-tooltip="'Er zijn geen functies gekoppeld aan deze afdeling'">0</span>
          </td>
          <td>
            <span class="tag is-info"> {{ department.readers.data.length }}</span>
          </td>
          <td>
            <i class="mdi mdi-cloud-sync mdi-18px" v-if="department.external_id" v-tooltip="'Deze afdeling is opgehaald uit een externe koppeling'"></i>
            <i class="mdi mdi-account-edit mdi-18px" v-if="!department.external_id" v-tooltip="'Deze afdeling is handmatig aangemaakt'"></i>  
          </td>
          <td>
            <div class="is-pulled-right">
              <div class="field has-addons">
                <p class="control">
                  <router-link :to="'/department/'+ department.id" class="button">
                    <span class="icon">
                      <i class="mdi mdi-pencil"></i>
                    </span>
                    
                  </router-link>
                </p>
                <p class="control">
                  <a class="button is-danger" v-on:click.stop="archiveDepartmentModal(department)" v-show="department.status !== 'archived'">
                    <span class="icon">
                      <i class="mdi mdi-archive"></i>
                    </span>
                    
                  </a>
                </p>
                <p class="control">
                    <a class="button is-danger" v-on:click.stop="deleteDepartmentModal(department)" v-show="department.status == 'archived'">
                      <span class="icon">
                        <i class="mdi mdi-delete-forever"></i>
                      </span>
                      <span>Verwijderen</span>
                    </a>
                  </p>
              </div>
            </div>
          </td>
        </tr>
        <tr v-for="position in department.positions.data" v-show="position.status === 'active' && isUnfoldedPositions" :key="position.id">
          <td colspan="4">
            <i class="mdi mdi-account-card-details" :style="'margin-left: 1em'"></i> {{ position.name }}
          </td>
          <td>
            <div class="is-pulled-right">
              <div class="field has-addons">
                <p class="control">
                  <router-link :to="'/position/'+ position.id" class="button">
                    <span class="icon">
                      <i class="mdi mdi-pencil"></i>
                    </span>
                    
                  </router-link>
                </p>
              </div>
            </div>
          </td>
        </tr>
        <template v-for="subdepartment in department.descendants" v-show="subdepartment.status !== 'archived'">
          <tr v-on:click="editDepartment(subdepartment)" :key="subdepartment.id">
            <td><i class="mdi mdi-chevron-right" :style="'margin-left:' + subdepartment.depth + 'em'"></i> {{ subdepartment.name }}</td>
            <td>{{ subdepartment.description }}</td>
            <td>
              <span class="tag is-info" v-show="subdepartment.positions.length > 0"> {{ subdepartment.positions.length }}</span>
              <span class="tag is-danger" v-show="subdepartment.positions.length === 0" v-tooltip="'Er zijn geen functies gekoppeld aan deze afdeling'">0</span>
            </td>
            <td>
              <span class="tag is-info"> {{ subdepartment.readers.length }}</span>
            </td>
            <td>
              <div class="is-pulled-right">
                <div class="field has-addons">
                  <p class="control">
                    <router-link :to="'/department/'+ subdepartment.id" class="button">
                      <span class="icon">
                        <i class="mdi mdi-pencil"></i>
                      </span>
                      
                    </router-link>
                  </p>
                  <p class="control">
                    <a class="button is-danger" v-on:click.stop="archiveDepartmentModal(subdepartment)">
                      <span class="icon">
                        <i class="mdi mdi-archive"></i>
                      </span>
                      
                    </a>
                  </p>
                  <p class="control">
                    <a class="button is-danger" v-on:click.stop="deleteDepartmentModal(subdepartment)" v-show="subdepartment.status == 'archived'">
                      <span class="icon">
                        <i class="mdi mdi-delete-forever"></i>
                      </span>
                      <span>Verwijderen</span>
                    </a>
                  </p>
                </div>
              </div>
            </td>
          </tr>
          <tr v-for="position in subdepartment.positions" v-show="position.status === 'active' && isUnfoldedPositions && subdepartment.status !== 'archived'" :key="position.id">
            <td colspan="4">
              <i class="mdi mdi-account-card-details" :style="'margin-left:' + (subdepartment.depth + 1) + 'em'"></i> {{ position.name }}
            </td>
            <td>
              <div class="is-pulled-right">
                <div class="field has-addons">
                  <p class="control">
                    <router-link :to="'/position/'+ position.id" class="button">
                      <span class="icon">
                        <i class="mdi mdi-pencil"></i>
                      </span>
                      
                    </router-link>
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <no-entity
      title="Geen afdelingen gevonden"
      button="Afdeling toevoegen"
      createPath="/departments/create"
      message="Probeer andere zoektermen of voeg een afdeling toe."
      icon="account-multiple"
      :count="departments.length" />

  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import indexBar from '../index-bar'
import archiveModal from '../common/archive-modal'
import deleteModal from '../common/delete-modal'
import noEntity from '../common/no-entity'

export default {
  name: 'departments',
  data: function () {
    return {
      departments: [],
      statusFilter: 'active',
      statusses: {
        'active': 'Actief',
        'archived': 'Gearchiveerd'
      },
      tobeArchivedDepartment: {},
      tobeDeletedDepartment: {},
      isUnfoldedPositions: false
    }
  },
  methods: {
    getDepartments: function () {
      this.$bus.$emit('loadingEvent', true)

      this.$api.get('departments?include=positions&limit=100&status=' + this.statusFilter).then((response) => {
        this.departments = response.data.data

        this.$bus.$emit('loadingEvent', false)
      }, () => {
        this.$bus.$emit('loadingEvent', false)
      })
    },
    editDepartment: function (department) {
      // navigate to document edit
      this.$router.push({ name: 'departmentEdit', params: { id: department.id } })
    },
    archiveDepartmentModal: function (department) {
      this.tobeArchivedDepartment = department
      this.$bus.$emit('askEntityArchiveModalEvent', true)
    },
    archiveDepartment: function (department) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Afdeling', department.id, '/department')
    },
    deleteDepartmentModal: function (department) {
      this.tobeDeletedDepartment = department
      this.$bus.$emit('askEntityDeleteModalEvent', true)
    },
    deleteDepartment: function (department) {
      // handle delete through entity abstraction layer
      this.$entity.delete('Afdeling', department.id, '/department')
    },
    foldPositions: function (status) {
      this.isUnfoldedPositions = status
    },
    setStatusFilter: function (status) {
      this.statusFilter = status
    }
  },
  mounted: function () {
    this.getDepartments()

    // listen for fold/unfold event
    this.$bus.$on('askFoldEvent', (status) => this.foldPositions(status))
    // listen for status filter change event emitted from a search bar
    this.$bus.$on('askStatusChangeEvent', (status) => this.setStatusFilter(status))

    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (department) => this.archiveDepartment(department))
    // listen for archived event
    this.$bus.$on('entityArchivedEvent', () => this.getDepartments())

    // listen for delete event
    this.$bus.$on('askEntityDeleteEvent', (department) => this.deleteDepartment(department))
    // listen for deleted event
    this.$bus.$on('entityDeletedEvent', () => this.getDepartments())
  },
  watch: {
    statusFilter: function () {
      // perform new get if status is changed
      this.getDepartments()
    }
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askEntityArchiveEvent')
    this.$bus.$off('entityArchivedEvent')

    this.$bus.$off('askEntityDeleteEvent')
    this.$bus.$off('entityDeletedEvent')

    this.$bus.$off('askFoldEvent')
    this.$bus.$off('askStatusChangeEvent')
  },
  components: {
    'index-bar': indexBar,
    'archive-modal': archiveModal,
    'delete-modal': deleteModal,
    'no-entity': noEntity
  }
}
</script>
