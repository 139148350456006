<template lang="html">
  <div id="tag">

    <icon-picker-modal />

    <div class="columns">
      <div class="column is-7">
        <div class="box">
          <div class="field">
            <label class="label title is-4 has-text-weight-light">{{ tag.name }}</label>
            <div class="control">
              <input class="input" type="text" ref="name" v-model="tag.name" placeholder="Naam van het thema"
                v-bind:class="{ 'is-danger': errors.name }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="createTag()">
            </div>
            <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Zichtbaarheid <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Dit bepaalt of het thema zichtbaar is voor lezers in de App en op de Desktop'"></i> </label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="tag.is_browsable" v-bind:disabled="disabled">
                  <option value="0" selected="selected">Onzichtbaar</option>
                  <option value="1">Zichtbaar</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.status" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Icoon <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Kies het icoon waarmee dit thema in de App en op de Desktop wordt aangeduid'"></i></label>
            <div class="control is-expanded">
              <input type="hidden" v-model="tag.icon" />
              <a class="button is-outlined" v-on:click="iconPickModal">
                <span class="icon">
                  <i :class="'mdi mdi-' + tag.icon"></i>
                </span>
                <span>Wijzig icoon</span>
              </a>
              <p v-for="(error, index) in errors.icon" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Kleur <i class="mdi mdi-help-circle has-text-info" v-tooltip="'De kleur waarmee het thema en/of het thema icoon wordt aangeduid'"></i></label>
            <div class="control is-expanded">
              <verte picker="square" model="hex" menuPosition="top" v-model="tag.color"></verte>
              <p v-for="(error, index) in errors.color" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-5"></div>
      <savebar
        :entity="tag"
        entityName="Thema">
      </savebar>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import savebar from '../savebar'
import iconPickerModal from '../common/icon-picker-modal'
import Verte from 'verte'

export default {
  name: 'tagCreate',
  data: function () {
    return {
      tag: {
        is_browsable: 0,
        icon: 'book',
        color: '#00d1b2'
      },
      errors: {},
      disabled: false
    }
  },
  methods: {
    createTag: function () {
      this.$bus.$emit('savingEvent', true)
      this.disabled = true
      this.$api.post('tags', this.tag).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Controleer de invoervelden')
        } else {
          this.tag = response.data.data
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Thema ' + this.tag.name + ' is succesvol toegevoegd')
          this.errors = {}
          this.$router.push('/tags')
        }
      }, () => {
        this.$bus.$emit('savingEvent', false)
        this.disabled = false
      })
    },
    iconPickModal: function () {
      this.$bus.$emit('askIconPickerModalEvent', true)
    },
    selectIcon: function (icon) {
      // set reactive
      this.$set(this.tag, 'icon', icon)
    }
  },
  mounted: function () {
    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', this.createTag)
    // listen for icon pick event
    this.$bus.$on('askIconPickEvent', (icon) => this.selectIcon(icon))

    this.$refs.name.focus()
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askSaveEvent')
    this.$bus.$off('askIconPickEvent')
  },
  components: {
    'savebar': savebar,
    'icon-picker-modal': iconPickerModal,
    'verte': Verte
  }
}
</script>
