<template lang="html">
  <div class="box is-paddingless" v-if="!disabled">
    <h1>Versies
      <a class="button is-pulled-right is-clearfix" style="margin-bottom: 1em" @click="toggleFold">
        <span class="icon" v-if="isFolded">
          <i class="mdi mdi-chevron-down"></i>
        </span>
        <span v-if="isFolded">Toon versies <span class="tag is-info" v-show="versions.length > 0"> {{ versions.length }}</span></span>
        <span class="icon" v-if="!isFolded">
          <i class="mdi mdi-chevron-up"></i>
        </span>
        <span v-if="!isFolded">Verberg versies</span>
      </a>
    </h1>
    <table class="table is-fullwidth" v-if="!isFolded">
      <thead>
        <tr>
          <th>Nr.</th>
          <th>Schrijver</th>
          <th>Status</th>
          <th>Laatste versie <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Als er meerdere versies gepubliceerd zijn dient &eacute;&eacute;n artikel als meest recent gemarkeerd te worden. Deze versie van het artikel wordt als eerste getoond aan de lezers.'"></i></th>
          <th>Laatste wijziging</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="version in versions" :key="version.id" :class="{'has-background-primary-light': version.version === document.version}">
          <td><span class="tag">{{ version.version }}</span></td>
          <td>{{ version.writer }}</td>
          <td><span class="tag" :class="{'is-primary': version.status === 'published'}">{{ statusses[version.status] }}</span></td>
          <td>
            <i class="mdi mdi-radiobox-blank" v-if="!version.is_latest_version && version.status === 'published'" @click="latestVersionDocumentModal(version)"></i>
            <i class="mdi mdi-radiobox-marked" v-if="version.is_latest_version"></i>
          </td>
          <td>{{ version.updated_at | moment("DD-MM-YYYY") }}</td>
          <td>
            <router-link class="button is-small is-pulled-right" :to="'/document/'+ version.id" v-show="version.version !== document.version">
              Bewerk
            </router-link>
            <span class="tag is-primary is-pulled-right" v-show="version.version === document.version">Deze versie</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="field" v-if="!isFolded">
      <span v-tooltip="'Wijzigingen (door jou of een andere schrijver) moeten worden opgeslagen voor je een nieuwe versie kunt maken'" v-if="document.locked_writer_id">
        <a class="button is-primary" disabled>
          <span class="icon">
            <i class="mdi mdi-plus-circle-multiple-outline"></i>
          </span>
          <span>Nieuwe versie aanmaken</span>
        </a>
      </span>
      <a class="button is-primary" v-on:click="versionDocumentModal(document)" v-if="!document.locked_writer_id">
        <span class="icon">
          <i class="mdi mdi-plus-circle-multiple-outline"></i>
        </span>
        <span>Nieuwe versie aanmaken</span>
      </a>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../../assets/scss/documents/document-versions.scss';
</style>

<script>

export default {
  name: 'documentVersions',
  props: [
    'document',
    'attachedVersions'
  ],
  data: function () {
    return {
      versions: [],
      relatedNotifications: [],
      activeTab: 'versions',
      disabled: false,
      statusses: {
        'published': 'Gepubliceerd',
        'draft': 'In concept',
        'archived': 'Gearchiveerd',
        'visible': 'Zichtbaar'
      },
      isFolded: true
    }
  },
  methods: {
    toggleTab: function (tabName) {
      this.activeTab = tabName
    },
    getVersions: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('document/' + this.$route.params.id + '/versions').then((response) => {
        
        this.versions.splice(0)

        for (let version of response.data.data) {
          this.versions.push({
            id: version.id,
            version: version.version,
            is_latest_version: version.is_latest_version,
            name: version.name,
            writer: version.writer.data.name,
            status: version.status,
            updated_at: version.updated_at })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    versionDocumentModal: function () {
      this.$bus.$emit('askEntityVersionModalEvent', true)
    },
    latestVersionDocumentModal: function (document) {
      this.$bus.$emit('askEntityLatestVersionModalEvent', document)
    },
    toggleFold: function () {
      if (this.isFolded) {
        this.isFolded = false
      } else {
        this.isFolded = true
      }
    },
  },
  mounted: function () {
    // listen for save event from the document to reload its versions
    this.$bus.$on('savingEvent', this.getVersions)
    this.$bus.$on('entityVersionedEvent', this.getVersions)
    this.$bus.$on('entityMarkedLatestVersionEvent', this.getVersions)

    this.getVersions()
  },
  beforeDestroy: function () {
    // stop listening
    this.$bus.$off('savingEvent', this.getVersions)
    this.$bus.$off('entityVersionedEvent', this.getVersions)
    this.$bus.$off('entityMarkedLatestVersionEvent', this.getVersions)
  },
  components: {}
}
</script>
