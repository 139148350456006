<template lang="html">
  <transition name="slide-fade">
    <div class="box" v-if="!isHidden">
      <div class="columns">
        <div class="column is-twothird">
          <div class="field">
            <div class="control">
              <input class="input" type="text" placeholder="Paragraaf titel" ref="name"
                  v-model="paragraph.name"
                  v-bind:class="{ 'is-danger': errors.name }" />
              <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field has-addons is-pulled-right">
            <p class="control" v-if="paragraph.revisions && paragraph.revisions.length > 0">
              <a class="button" v-on:click="showParagraphRevisions()" v-tooltip="'Toon de wijzigingshistorie van deze paragraaf'">
                <span class="icon">
                  <i class="mdi mdi-history"></i>
                </span>
                <span class="tag is-info">{{ paragraph.revisions.length }}</span>
              </a>
            </p>
            <p class="control" v-if="index !== 0">
              <a class="button" v-on:click="moveUpParagraph()" v-tooltip="'Verplaats deze paragraaf naar boven'">
                <span class="icon">
                  <i class="mdi mdi-arrow-up"></i>
                </span>
              </a>
            </p>
            <p class="control" v-if="index + 1 !== count">
              <a class="button" v-on:click="moveDownParagraph()" v-tooltip="'Verplaats deze paragraaf naar beneden'">
                <span class="icon">
                  <i class="mdi mdi-arrow-down"></i>
                </span>
              </a>
            </p>
            <p class="control">
              <delete-confirmed-button :entity="paragraph" entityName="paragraaf" @delete="deleteParagraph()" />
            </p>
          </div>
        </div>
      </div>
      <div class="field" >

        <mavon-editor
          v-model="paragraph.content"
          :toolbars="editorConfig.toolbar"
          placeholder='Voer de paragraaf tekst in'
          language='en'
          :boxShadow=false />

        <p v-for="(error, index) in errors.content" class="help is-danger" :key="index">{{ error }}</p>

      </div>
      <div class="field">
        <label class="label">Afbeelding <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Kies de afbeelding die in deze paragraaf getoond wordt'"></i></label>
        <div class="control is-expanded">

          <a class="button is-danger is-outlined mr-2" v-on:click="deleteImage" v-if="paragraph.has_image && !imageShouldDelete">
            <span class="icon">
              <i class="mdi mdi-delete-forever"></i>
            </span>
            <span>Afbeelding verwijderen</span>
          </a>

          <a class="button is-danger is-outlined mr-2" v-on:click="unDeleteImage" v-if="paragraph.has_image && imageShouldDelete">
            <span class="icon">
              <i class="mdi mdi-undo"></i>
            </span>
            <span>Verwijdering annuleren</span>
          </a>

          <image-picker-modal @pick="selectImage" />

          <p v-for="(error, index) in errors.image" class="help is-danger" :key="index">{{ error }}</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
  @import '../../../assets/scss/documents/paragraph.scss';
</style>

<script>

import imagePickerModal from '../../common/image-picker-modal'
import deleteConfirmedButton from '../../common/delete-confirmed-button.vue'

export default {
  name: 'paragraph',
  // enable receival of full step in property
  props: [
    'paragraphImmutable',
    'index',
    'count'
  ],
  data: function () {
    return {
      editorConfig: {
        toolbar: {
          bold: true,
          italic: true,
          header: true,
          underline: false,
          strikethrough: true,
          ol: true,
          ul: true,
          undo: true,
          redo: true,
          link: true,
          navigation: false,
          fullscreen: false,
          subfield: true,
          preview: true
        }
      },
      paragraph: {},
      imageShouldDelete: false,
      errors: [],
      disabled: false,
      isHidden: false,
      changeCount: 0
    }
  },
  methods: {
    deleteParagraph: function () {
      if (this.paragraph.isSaved) {
        this.$api.delete('paragraph/' + this.paragraph.id).then((response) => {
          if (response.data.code && response.data.code > 200) {
            this.$bus.$emit('savingEvent', false)
            this.disabled = false
            this.errors = response.data.errors
            this.$noty.warning('Controleer de invoervelden')
          } else {
            this.$bus.$emit('savingEvent', false)
            this.disabled = false
            this.$noty.success('Paragraaf ' + this.paragraph.name + ' is verwijderd')
            this.errors = {}

            // emit destroy to document edit to remove from attached paragraphs
            this.$emit('destroy', this.index)
            // remove self (and listeners)
            this.$destroy()
            this.$el.parentNode.removeChild(this.$el)
            this.paragraph.isDeleted = true
          }
        }, (error) => {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$bus.$emit('apiErrorEvent', error)
        })
      } else {
        // emit destroy to document edit to remove from attached paragraphs
        this.$emit('destroy', this.index)
        // remove self (and listeners)
        this.$destroy()
        this.$el.parentNode.removeChild(this.$el)
        this.paragraph.isDeleted = true
      }
    },
    moveUpParagraph: function () {
      this.$emit('up', this.index)
    },
    moveDownParagraph: function () {
      this.$emit('down', this.index)
    },
    saveParagraph: function () {
      // delete the paragraphs' image on request
      if (this.imageShouldDelete) {
        this.paragraph.image_should_delete = true
      }

      if (!this.paragraph.isDeleted && this.changeCount > 1) {
        this.$bus.$emit('savingEvent', true)
        this.disabled = true

        // update existing paragraph, one saved already
        if (this.paragraph.isSaved) {

          this.$api.put('paragraph/' + this.paragraph.id, this.paragraph).then((response) => {
            if (response.data.code && response.data.code > 200) {
              this.$bus.$emit('savingEvent', false)
              this.disabled = false
              this.errors = response.data.errors
              this.$noty.warning('Controleer de invoervelden')
            } else {
              this.$bus.$emit('savingEvent', false)
              this.disabled = false
              this.changeCount = 1
              this.$noty.success('Paragraaf ' + this.paragraph.name + ' is succesvol opgeslagen')
              this.errors = {}
            }
          }, (error) => {
            this.$bus.$emit('savingEvent', false)
            this.disabled = false
            this.$bus.$emit('apiErrorEvent', error)
          })
        }

        // store new paragraph, one not saved yet
        if (!this.paragraph.isSaved) {
          this.$api.post('paragraphs', this.paragraph).then((response) => {
            if (response.data.code && response.data.code > 200) {
              this.$bus.$emit('savingEvent', false)
              this.disabled = false
              this.errors = response.data.errors
              this.$noty.warning('Controleer de invoervelden')
            } else {
              this.$bus.$emit('savingEvent', false)
              this.disabled = false
              this.changeCount = 1
              this.paragraph = response.data.data
              this.paragraph.isSaved = true
              this.$noty.success('Paragraaf ' + this.paragraph.name + ' is succesvol opgeslagen')
              this.errors = {}
            }
          }, (error) => {
            this.$bus.$emit('savingEvent', false)
            this.disabled = false
            this.$bus.$emit('apiErrorEvent', error)
          })
        }
      }
    },
    showParagraphRevisions: function () {
      this.$bus.$emit('askEntityRevisionModalEvent', {'revisions': this.paragraph.revisions, 'entityName': 'Paragraaf'})
    },
    selectImage: function (image) {
      this.paragraph.image_base64 = image
      this.imageShouldDelete = false
      this.changeCount++
    },
    deleteImage: function () {
      this.imageShouldDelete = true
      this.changeCount++
    },
    unDeleteImage: function () {
      this.imageShouldDelete = false
      this.changeCount++
    }
  },
  mounted: function () {
    // set paragraph
    this.paragraph = this.paragraphImmutable

    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', (paragraph) => this.saveParagraph(paragraph))

    // listen for image save event
    this.$bus.$on('askImagePickEvent', (image) => this.selectImage(image))
  },
  watch: {
    paragraph: {
      deep: true,
      handler () {
        this.changeCount++

        // emit change event
        this.$emit('change', this.index)
      }
    }
  },
  beforeDestroy: function () {
    // stop listening
    this.$bus.$off('askSaveEvent', (paragraph) => this.saveParagraph(paragraph))
    this.$bus.$off('askImagePickEvent', (image) => this.selectImage(image))
  },
  components: {
    'image-picker-modal': imagePickerModal,
    'delete-confirmed-button': deleteConfirmedButton
  }
}
</script>
