<template lang="html">
  <div class="modal" v-bind:class="{ 'is-active': visible }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <p>Weet je zeker dat je <strong>{{ entityName }}</strong> definitief wilt verwijderen?</p>

        <article class="message is-danger m-t-md">
          <div class="message-body">
            Het verwijderen van de schrijver kan niet ongedaan gemaakt worden.
          </div>
        </article>

        <hr />
        <p>Bestaande artikelen en bijlages moeten op naam van een andere schrijver worden gezet, nu {{ entityName }} wordt verwijderd.</p>
        <div class="field mt-2">
          <label class="label">Kies schrijver <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Kies de schrijver waar bestaande artikelen en bijlages naar moeten worden overgedragen nu deze schrijver wordt verwijderd.'"></i></label>
          <div class="control">
            <v-select ref="vselect"
              :options="writers"
              label="name"
              v-model="substituteWriter">

              <template slot="option" slot-scope="option">
                  {{ option.arrow }} {{ option.name }}
              </template>

              <no-entity-autocomplete
                title="Schrijver niet gevonden"
                message="Probeer andere zoektermen."
                icon="account-edit"
                slot="no-options" />

            </v-select>
          </div>
        </div>
        
        <a class="button is-danger" v-on:click="askDelete(entity)" v-bind:class="{ 'is-disabled': deleting }">
          <span class="icon">
            <i class="mdi mdi-delete-forever"></i>
          </span>
          <span v-if="!deleting">Definitief verwijderen</span>
          <span v-else>Bezig met verwijderen</span>
        </a>
        <a class="button" v-on:click="close()">
          <span class="icon">
            <i class="mdi mdi-cancel"></i>
          </span>
          <span>Annuleer</span>
        </a>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
  </div>
</template>

<script>

import noEntityAutocomplete from './no-entity-autocomplete'
import vSelect from 'vue-select'

export default {
  name: 'deleteModalWriter',
  props: {
    entity: {
      type: Object,
      default: () => ({})
    },
    entityName: String,
    entityType: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      deleting: false,
      visible: false,
      substituteWriter: null,
      writers: [],
    }
  },
  methods: {
    getWriters: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('writers?limit=100').then((response) => {
        this.writers = response.data.data
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    askDelete: function () {
      this.$bus.$emit('askEntityDeleteEvent', this.entity, this.substituteWriter)
      this.deleting = true
    },
    close: function () {
      this.visible = false
    },
    spawnModal: function () {
      this.visible = true
    },
    setDeleting: function (state) {
      this.deleting = state
      if (state === false) {
        this.visible = false
      }
    }
  },
  mounted: function () {
    this.getWriters()

    // listen for ask for modal event
    this.$bus.$on('askEntityDeleteWriterModalEvent', this.spawnModal)
    this.$bus.$on('deletingEvent', (state) => this.setDeleting(state))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askEntityDeleteWriterModalEvent')
    this.$bus.$off('deletingEvent')
  },
  components: {
    'v-select': vSelect,
    'no-entity-autocomplete': noEntityAutocomplete
  },
}
</script>