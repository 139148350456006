<template lang="html">
  <div id="reader">
    <div class="columns">
      <div class="column is-7">
        <div class="box">
          <div class="field">
            <label class="label title is-4 has-text-weight-light">{{ reader.name }}</label>
            <div class="control">
              <input class="input" type="text" ref="name" v-model="reader.name" placeholder="Naam van de lezer"
                v-bind:class="{ 'is-danger': errors.name }"
                v-on:keyup.enter="createReader()">
            </div>
            <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">E-mailadres</label>
            <div class="control">
              <input class="input" type="email" v-model="reader.email" placeholder="E-mailadres van de lezer"
                v-bind:class="{ 'is-danger': errors.email }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="createReader()">
            </div>
            <p v-for="(error, index) in errors.email" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Herstel e-mailadres <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Als een herstel e-mailadres opgegeven is worden hier wachtwoord reset meldingen heen gestuurd i.p.v. naar het login e-mailadres.'"></i></label>
            <div class="control">
              <input class="input" type="email_recovery" v-model="reader.email_recovery" placeholder="Herstel e-mailadres van de lezer"
                v-bind:class="{ 'is-danger': errors.email_recovery }"
                v-on:keyup.enter="updateReader()">
            </div>
            <p v-for="(error, index) in errors.email_recovery" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <p>
              <span class="icon has-text-primary is-pulled-right" v-if="presetPassword">
                <i class="mdi mdi-key"></i>
              </span>
              <span class="icon has-text-grey-light is-pulled-right" v-if="!presetPassword">
                <i class="mdi mdi-email"></i>
              </span>
              Stel zelf het wachtwoord voor de lezer in <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Als je het wachtwoord niet handmatig instelt krijgt de lezer een email waarmee hij zelf een wachtwoord kan kiezen.'"></i>
            </p>
            <toggle-button
              :sync="true"
              v-model="presetPassword"
              :width="58"
              :height="25"
              :labels="{checked: 'Ja', unchecked: 'Nee'}"
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
          </div>
          <div class="field" v-if="presetPassword">
            <label class="label">Wachtwoord</label>
            <div class="control">
              <input class="input" type="password" v-model="reader.password" placeholder="Wachtwoord van de lezer"
                v-bind:class="{ 'is-danger': errors.email }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="createReader()">
            </div>
            <p v-for="(error, index) in errors.password" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Mobiel telefoonnummer</label>
            <div class="control">
              <input class="input" type="text" v-model="reader.cellphone" placeholder="Mobiel telefoonnummer van de lezer"
                v-bind:class="{ 'is-danger': errors.cellphone }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="createReader()">
            </div>
            <p class="has-text-grey">Gebruik een 13-15 cijferig telefoonnummer, met landcode. Bijvoorbeeld: 0031612345678.</p>
            <p v-for="(error, index) in errors.cellphone" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Afdeling</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="reader.department_id" v-bind:disabled="disabled">
                  <option v-for="(department, index) in departments" :value="department.id" :key="index">{{ department.name }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.department_id" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Functies <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Je kunt één of meerdere functies aan een lezer koppelen'"></i></label>
            <div class="control">
              <v-select ref="vselect" multiple
                :options="positions"
                label="name"
                v-model="reader.positions">

                <no-entity-autocomplete
                  title="Functie niet gevonden"
                  message="Probeer andere zoektermen of voeg een nieuwe functie toe via het hoofdmenu."
                  icon="account-card-details"
                  slot="no-options" />

              </v-select>
            </div>
          </div>

          <article class="message is-info">
            <div class="message-body">
              <span>
                <strong>Let op:</strong> na het toevoegen van een lezer bent u extra maandelijkse kosten van
                <money-format
                  class="is-inline"
                  v-if="account.price_readers"
                  :value="account.price_readers / 100"
                  locale="nl"
                  currency-code="EUR">
               </money-format> verschuldigd.
              </span>
            </div>
          </article>

          <article class="message is-warning" v-if="account.limit_readers > 0 && account.limit_readers === account.usage_readers">
            <div class="message-body">
              <span>
                <strong>Let op:</strong> het maximaal aantal van {{ account.limit_readers }} lezers binnen uw {{ account.tier_name }} pakket is bereikt. Bij het toevoegen
                van deze lezer wordt, aan het einde van deze maand, uw pakket gewijzigd.
              </span>
            </div>
          </article>
        </div>
      </div>
      <div class="column is-5"></div>
      <savebar
        :entity="reader"
        entityName="Lezer">
      </savebar>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import savebar from '../savebar'
import vSelect from 'vue-select'
import noEntityAutocomplete from '../common/no-entity-autocomplete'
import MoneyFormat from 'vue-money-format'
import ToggleButton from 'vue-js-toggle-button/src/Button'

export default {
  name: 'readerCreate',
  data: function () {
    return {
      reader: {},
      account: {},
      positions: [],
      departments: [],
      errors: {},
      disabled: false,
      presetPassword: false
    }
  },
  methods: {
    createReader: function () {
      this.$bus.$emit('savingEvent', true)
      this.disabled = true
      this.$api.post('readers', this.reader).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Controleer de invoervelden')
        } else {
          // add selected positions
          if (this.reader.positions) {
            var selectedPositions = []
            for (let position of this.reader.positions) {
              selectedPositions.push(position.id)
            }
            // handle attachment of positions through entity abstraction layer
            this.$entity.attach({}, { 'positions': selectedPositions }, 'Functies', '/reader/' + response.data.data.id + '/positions')
          }

          this.reader = response.data.data
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Lezer ' + this.reader.name + ' is succesvol toegevoegd')
          this.errors = {}
          this.$router.push('/readers')
        }
      }, () => {
        this.$bus.$emit('savingEvent', false)
        this.disabled = false
      })
    },
    getPositions: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('positions?limit=100&status=active').then((response) => {
        for (let position of response.data.data) {
          this.positions.push({
            id: position.id,
            name: position.name + ' (' + position.department.data.name + ')'})
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, () => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      })
    },
    getDepartments: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('departments?limit=100&status=active').then((response) => {
        for (let department of response.data.data) {
          this.departments.push({
            id: department.id,
            name: department.name
          })

          // also include subdepartments
          for (let subdepartment of department.descendants) {
            if (subdepartment.status === 'active') {
              this.departments.push({
                id: subdepartment.id,
                name: subdepartment.name
              })
            }
          }
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, () => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      })
    },
    getAccount: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('account').then((response) => {
        this.account = response.data.data
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, () => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      })
    }
  },
  mounted: function () {
    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', this.createReader)

    this.getPositions()
    this.getDepartments()
    this.getAccount()

    this.$refs.name.focus()
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askSaveEvent')
  },
  components: {
    'savebar': savebar,
    'v-select': vSelect,
    'no-entity-autocomplete': noEntityAutocomplete,
    'money-format': MoneyFormat,
    'toggle-button': ToggleButton
  }
}
</script>
