<template lang="html">
  <div class="card is-rounded">
    <header class="card-header">
      <p class="card-header-title">
        <router-link to="/comments" class="has-text-dark">
          <span class="icon is-medium">
            <i class="mdi mdi-comment-multiple-outline has-text-grey"></i>
          </span>
          <span class="has-text-weight-light is-size-4">Commentaren</span>
        </router-link>
      </p>
    </header>
    <div class="card-content">
      <div class="columns is-gapless">
        <div class="column has-text-centered">
          <router-link to="/comments" class="has-text-dark">
            <span class="tag is-rounded is-medium is-danger">{{ comments.length }}</span>
            <p class="has-text-grey">openstaand</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'commentsPersonal',
  props: [
    'comments'
  ]
}
</script>