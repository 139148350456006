<template lang="html">
  <div class="modal" v-bind:class="{ 'is-active': visible }">
    <div class="modal-background"></div>
    <div class="modal-content">

      <attachment-create inModal="true" :modalSave="saving" :reset="reset" />

      <div class="box">
        <a class="button is-primary" v-on:click="askSave()" v-bind:class="{ 'is-disabled': saving }">
          <span class="icon">
            <i class="mdi mdi-content-save"></i>
          </span>
          <span v-if="!saving">Opslaan</span>
          <span v-else>Bezig met opslaan</span>
        </a>
        <a class="button" v-on:click="close()">
          <span class="icon">
            <i class="mdi mdi-cancel"></i>
          </span>
          <span>Annuleer</span>
        </a>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
  </div>
</template>

<script>

import attachmentCreate from './attachment-create'

export default {
  name: 'attachmentCreateModal',
  props: [
    'entity',
    'entityName'
  ],
  data: function () {
    return {
      saving: false,
      visible: false,
      reset: false
    }
  },
  methods: {
    askSave: function () {
      this.saving = true
    },
    close: function () {
      this.visible = false
      this.reset = false
    },
    spawnModal: function () {
      this.visible = true
      this.reset = true
      this.saving = false
    },
    setSaving: function (state) {
      this.saving = state
    }
  },
  mounted: function () {
    // listen for ask for modal event
    this.$bus.$on('askAttachmentCreateModalEvent', this.spawnModal)
    this.$bus.$on('savingEvent', (state) => this.setSaving(state))
    this.$bus.$on('attachmentCreatedEvent', this.close)
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askAttachmentCreateModalEvent')
    this.$bus.$off('savingEvent')
    this.$bus.$off('attachmentCreatedEvent', this.close)
  },
  components: {
    'attachment-create': attachmentCreate
  }
}
</script>