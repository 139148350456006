<template lang="html">
  <div class="box">

    <index-bar
      createPath="/readers/create"
      createText="Lezer toevoegen"
      :activeStatus="statusFilter"
      :statusses="statusses"
      :showSearch=true />

    <archive-modal
      :entity="this.tobeArchivedReader"
      :entityName="this.tobeArchivedReader.name" 
      entityType='reader' />

    <delete-modal
      :entity="this.tobeDeletedReader"
      :entityName="this.tobeDeletedReader.name" 
      entityType='reader' />

    <table class="table is-fullwidth" v-if="readers.length >= 1">
      <thead>
        <tr>
          <th><abbr title="Naam">Naam</abbr></th>
          <th><abbr title="Afdeling">Afdeling</abbr></th>
          <th><abbr title="Functie">Functies</abbr></th>
          <th><abbr title="Laatste Web gebruik">Laatste Web gebruik</abbr></th>
          <th><abbr title="Laatste App gebruik">Laatste App gebruik</abbr></th>
          <th><abbr title="Toestel">Toestel</abbr></th>
          <th><abbr title="App versie">App versie</abbr></th>
          <th><abbr title="Notificaties">Notificaties</abbr></th>
          <th><abbr title="Bron">Bron</abbr></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="reader in readers" v-on:click="editReader(reader)" :key="reader.id">
          <td>{{ reader.name }}</td>
          <td><span v-if="reader.department">{{ reader.department.data.name }}</span></td>
          <td><entity-relations-badge :entities="reader.positions" /></td>
          <td>{{ reader.web_used_at | moment("from", "now") | checkEpoch }}</td>
          <td>{{ reader.app_used_at | moment("from", "now") | checkEpoch }}</td>
          <td>
            <i class="mdi mdi-cellphone-iphone" v-if="reader.device_type === 'phone'"></i>
            <i class="mdi mdi-tablet-android" v-if="reader.device_type === 'tablet'"></i>
            <span v-if="reader.device_model">{{ reader.device_model + ' (' + reader.device_os + ')' }}</span>
          </td>
          <td>{{ reader.app_build }}</td>
          <td>
            <i class="mdi mdi-bell-ring-outline" v-if="reader.push_id !== ''" v-tooltip="'Deze lezer kan notificaties ontvangen'"></i>
            <i class="mdi mdi-bell-off" v-if="reader.push_id === ''" v-tooltip="'Deze lezer kan geen notificaties ontvangen'"></i>
          </td>
          <td>
            <i class="mdi mdi-cloud-sync mdi-18px" v-if="reader.external_id" v-tooltip="'Deze lezer is opgehaald uit een externe koppeling'"></i>
            <i class="mdi mdi-account-edit mdi-18px" v-if="!reader.external_id" v-tooltip="'Deze lezer is handmatig aangemaakt'"></i>  
          </td>
          <td>
            <i class="mdi mdi-email-lock mdi-18px is-clickable" v-if="reader.id != inviteReaderId" v-on:click.stop="inviteReader(reader)" v-tooltip="'Verstuur uitnodigingsmail met wachtwoord instellen naar ' + reader.name"></i>
            <i class="mdi mdi-email-check has-text-primary mdi-18px" v-if="reader.id == inviteReaderId"></i>
          </td>
          <td>
            <div class="is-pulled-right">
              <div class="field has-addons">
                <p class="control">
                  <router-link :to="'/reader/'+ reader.id" class="button">
                    <span class="icon">
                      <i class="mdi mdi-pencil"></i>
                    </span>
                    
                  </router-link>
                </p>
                <p class="control">
                  <a class="button is-danger" v-on:click.stop="archiveReaderModal(reader)" v-show="reader.status !== 'archived'">
                    <span class="icon">
                      <i class="mdi mdi-cancel"></i>
                    </span>
                    <span>De-activeren</span>
                  </a>
                </p>
                <p class="control">
                  <a class="button is-danger" v-on:click.stop="deleteReaderModal(reader)" v-show="reader.status == 'archived'">
                    <span class="icon">
                      <i class="mdi mdi-delete-forever"></i>
                    </span>
                    <span>Verwijderen</span>
                  </a>
                </p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <no-entity
      title="Geen lezers gevonden"
      button="Lezer toevoegen"
      createPath="/readers/create"
      message="Probeer andere zoektermen of voeg een nieuw lezer toe."
      icon="account-circle"
      :count="readers.length" />

    <index-pagination
      :pages="pages"
      :currentPage="pageFilter" />

  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import indexBar from '../index-bar'
import indexPagination from '../index-pagination'
import archiveModal from '../common/archive-modal'
import deleteModal from '../common/delete-modal'
import noEntity from '../common/no-entity'
import entityRelationsBadge from '../common/entity-relations-badge'

export default {
  name: 'readers',
  data: function () {
    return {
      readers: [],
      tobeArchivedReader: {},
      tobeDeletedReader: {},
      inviteReaderId: false,
      pages: 1,
      disabled: false,
      pageFilter: 1,
      keywordsFilter: '',
      statusFilter: 'active',
      statusses: {
        'active': 'Actief',
        'archived': 'Inactief'
      }
    }
  },
  methods: {
    searchReaders: function () {
      this.$bus.$emit('loadingEvent', true)

      this.$api.get('readers?search=' + this.keywordsFilter + '&status=' + this.statusFilter + '&page=' + this.pageFilter + '&include=positions,department&sort=name&limit=50').then((response) => {
        this.readers = response.data.data

        this.pages = response.data.meta.pagination.total_pages
        this.pageFilter = response.data.meta.pagination.current_page

        this.$bus.$emit('loadingEvent', false)
      }, () => {
        this.$bus.$emit('loadingEvent', false)
      })
    },
    setPageFilter: function (page) {
      this.pageFilter = page
    },
    setKeywordFilter: function (keywords) {
      this.keywordsFilter = keywords
      this.pageFilter = 1
    },
    setStatusFilter: function (status) {
      this.statusFilter = status
      this.pageFilter = 1
    },
    editReader: function (reader) {
      // navigate to reader edit
      this.$router.push({ name: 'readerEdit', params: { id: reader.id } })
    },
    archiveReaderModal: function (reader) {
      this.tobeArchivedReader = reader
      this.$bus.$emit('askEntityArchiveModalEvent', true)
    },
    archiveReader: function (reader) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Lezer', reader.id, '/reader')
    },
    deleteReaderModal: function (reader) {
      this.tobeDeletedReader = reader
      this.$bus.$emit('askEntityDeleteModalEvent', true)
    },
    deleteReader: function (reader) {
      // handle delete through entity abstraction layer
      this.$entity.delete('Lezer', reader.id, '/reader')
    },
    inviteReader: function (reader) {
      // send password reset 
      this.$bus.$emit('loadingEvent', true)

      this.$api.get('reader/' + reader.id + '/send-invite').then(() => {
        this.inviteReaderId = reader.id

        this.$bus.$emit('loadingEvent', false)
      }, () => {
        this.$bus.$emit('loadingEvent', false)
      })
    }
  },
  mounted: function () {
    this.searchReaders()

    // listen for search event emitted from search bar
    this.$bus.$on('askSearchEvent', (keywords) => this.setKeywordFilter(keywords))
    // listen for page change event emitted from a paginator
    this.$bus.$on('askPageChangeEvent', (page) => this.setPageFilter(page))
    // listen for status filter change event emitted from a search bar
    this.$bus.$on('askStatusChangeEvent', (status) => this.setStatusFilter(status))

    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (reader) => this.archiveReader(reader))
    // listen for archived event
    this.$bus.$on('entityArchivedEvent', () => this.searchReaders())

    // listen for delete event
    this.$bus.$on('askEntityDeleteEvent', (reader) => this.deleteReader(reader))
    // listen for deleted event
    this.$bus.$on('entityDeletedEvent', () => this.searchReaders())
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askArchiveTagEvent')
    this.$bus.$off('askPageChangeEvent')
    this.$bus.$off('askSearchEvent')

    this.$bus.$off('askEntityArchiveEvent')
    this.$bus.$off('entityArchivedEvent')

    this.$bus.$off('askEntityDeleteEvent')
    this.$bus.$off('entityDeletedEvent')
  },
  watch: {
    keywordsFilter: function () {
      // perform new search if keywords change
      this.searchReaders()
    },
    pageFilter: function () {
      // perform new search if page is changed
      this.searchReaders()
    },
    statusFilter: function () {
      // perform new search if page is changed
      this.searchReaders()
    }
  },
  filters: {
    checkEpoch: function (date) {
      if (date.substr(0, 2) >= 40) {
        return '-'
      }
      return date
    }
  },
  components: {
    'index-bar': indexBar,
    'index-pagination': indexPagination,
    'archive-modal': archiveModal,
    'delete-modal': deleteModal,
    'no-entity': noEntity,
    'entity-relations-badge': entityRelationsBadge
  }
}
</script>
