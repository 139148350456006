<template lang="html">
  <div id="passwordForgot">
    <div class="columns">
      <div class="column is-3"></div>
      <div class="column is-6">
        <div class="card is-rounded">
          <div class="card-image">
            <figure class="image">
              
            </figure>
          </div>
          <div class="card-content">
            <div class="content">
              <div class="field">
                <label class="label">E-mailadres</label>
                <div class="control">
                  <input class="input" type="email" ref="email" v-model="passwordForgot.email" placeholder="E-mailadres" v-on:keyup.enter="request()">
                </div>
                <p v-for="(error, index) in errors.email" class="help is-danger" :key="index">{{ error }}</p>
              </div>
              <a class="button is-primary" v-on:click="request" v-show="!requesting">Nieuw wachtwoord aanvragen</a>
              <a class="button is-primary is-disabled" v-show="requesting">Bezig met aanvragen</a>
              <router-link class="button is-pulled-right" to="/login">
                <span class="icon">
                  <i class="mdi mdi-arrow-left"></i>
                </span>
                <span>Terug naar inloggen</span>
              </router-link>
            </div>
            <div class="content" v-if="requested">
              <article class="message is-small is-info">
                <div class="message-body">
                  Als het emailadres <strong>{{ passwordForgot.email }}</strong> bekend is ontvangt u een bericht met verdere instructies in uw mailbox. 
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3"></div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>
export default {
  name: 'passwordForgot',
  data: function () {
    return {
      passwordForgot: {
        email: ''
      },
      requesting: false,
      errors: {},
      requested: false
    }
  },
  methods: {
    request: function () {
      // emit reset in event
      this.requesting = true

      this.$api.post('/password-forgot', this.passwordForgot).then((response) => {
        // handle succesful login
        if (!response.data.errors) {
          this.requested = true
          this.errors = {}
          this.requesting = false
        } else {
          this.errors = response.data.errors
          this.requesting = false
          this.$noty.warning('Aanvraag mislukt. Probeer nogmaals een nieuw wachtwoord aan te vragen')
        }
      }, (error) => {
        this.requesting = false
        this.showApiErrorNotification(error)
      })
    }
  },
  mounted: function () {
    this.$refs.email.focus()
  }
}
</script>