<template lang="html">
  <div class="box">
    <index-bar
      :createMethod="createDocument"
      createText="Artikel toevoegen"
      entityName="artikelen"
      :activeStatus="statusFilter"
      :activeOwnership="mutableOwnershipFilter"
      :statusses="statusses" />

    <archive-modal
      :entity="this.tobeArchivedDocument"
      :entityName="this.tobeArchivedDocument.name"
      entityType='document' />

    <delete-modal
      :entity="this.tobeDeletedDocument"
      :entityName="this.tobeDeletedDocument.name"
      entityType='document' />

    <table class="table is-fullwidth" v-if="documents.length >= 1">
      <thead>
        <tr>
          <th><abbr title="Naam">Naam</abbr></th>
          <th><abbr title="Versie">Versie</abbr></th>
          <th><abbr title="Categorieën">Categorieën</abbr></th>
          <th><abbr title="Thema's">Thema's</abbr></th>
          <th><abbr title="Personalisatie">Personalisatie</abbr></th>
          <th><abbr title="Schrijver">Schrijver</abbr></th>
          <th><abbr title="Bijlages">Bijlages</abbr></th>
          <th><abbr title="Verloopdatum">Verloopdatum</abbr></th>
          <th><abbr title="Aangepast">Aangepast</abbr></th>
          <th><abbr title="Afgeschermd">Afgeschermd</abbr></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="document in documents" v-on:click="editDocument(document)" :key="document.id">
          <td>{{ document.name }}</td>
          <td><span class="tag">Versie {{ document.version }}</span></td>
          <td><entity-relations-badge :entities="document.categories" /></td>
          <td><entity-relations-badge :entities="document.tags" /></td>
          <td>
            <span class="icon" v-if="document.positions.data.length === 0 && document.departments.data.length === 0" v-tooltip="'Dit artikel is gericht op alle lezers'">
              <i class="mdi mdi-infinity"></i>
            </span>
            <entity-relations-badge :entities="document.positions" v-if="document.positions.data.length > 0" />
            <entity-relations-badge :entities="document.departments" v-if="document.departments.data.length > 0" />
          </td>
          <td>{{ document.writer.data.name }}</td>
          <td><span class="tag is-info" v-show="document.attachments.data.length > 0"> {{ document.attachments.data.length }}</span></td>
          <td><span v-if="document.due_at">{{ document.due_at | moment("DD-MM-YYYY") }}</span></td>
          <td>{{ document.updated_at | moment("DD-MM-YYYY") }}</td>
          <td>
            <i class="mdi mdi-lock has-text-danger" v-if="document.is_position_restricted" v-tooltip="'Dit artikel is alleen te openen door lezers met een van de gekozen functies.'"></i>
            <i class="mdi mdi-lock-open has-text-primary" v-if="!document.is_position_restricted" v-tooltip="'Dit artikel is door alle lezers te openen.'"></i>
          </td>
          <td>
            <div class="is-pulled-right">
              <div class="field has-addons">
                <p class="control">
                  <router-link :to="'/document/'+ document.id" class="button">
                    <span class="icon">
                      <i class="mdi mdi-pencil"></i>
                    </span>
                  </router-link>
                </p>
                <div class="dropdown control is-hoverable">
                  <div class="dropdown-trigger">
                    <button class="button" aria-haspopup="true" aria-controls="dropdown-menu3">
                      <span class="icon">
                        <i class="mdi mdi-download"></i>
                      </span>
                    </button>
                  </div>
                  <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                    <div class="dropdown-content">
                      <a class="dropdown-item" v-on:click.stop="downloadDocument(document)">
                        <span class="has-text-weight-bold">PDF</span> document
                      </a>
                      <a class="dropdown-item" v-on:click.stop="downloadDocumentWord(document)">
                        <span class="has-text-weight-bold">Word</span> document
                      </a>
                    </div>
                  </div>
                </div>
                <p class="control">
                  <a class="button is-danger" v-on:click.stop="archiveDocumentModal(document)" v-show="document.status !== 'archived'">
                    <span class="icon">
                      <i class="mdi mdi-archive"></i>
                    </span>
                  </a>
                </p>
                <p class="control">
                  <a class="button is-danger" v-on:click.stop="deleteDocumentModal(document)" v-show="document.status == 'archived'">
                    <span class="icon">
                      <i class="mdi mdi-delete-forever"></i>
                    </span>
                    <span>Verwijderen</span>
                  </a>
                </p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <no-entity
      title="Geen artikelen gevonden"
      message="Probeer andere zoektermen of voeg een nieuw artikel toe."
      icon="file-document"
      button="Artikel toevoegen"
      :count="documents.length" />

    <index-pagination
      :pages="pages"
      :currentPage="pageFilter" />

  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import indexBar from './index-bar'
import indexPagination from '../index-pagination'
import archiveModal from '../common/archive-modal'
import deleteModal from '../common/delete-modal'
import noEntity from '../common/no-entity'
import entityRelationsBadge from '../common/entity-relations-badge'
import * as Cookies from 'tiny-cookie'

export default {
  name: 'documents',
  props: {
    initialOwnershipFilter: {
      default: 'all',
      type: String
    },
    initialIsDueFilter: {
      default: false,
      type: Boolean
    },
    initialIsOverDueFilter: {
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      documents: [],
      tobeArchivedDocument: {},
      tobeDeletedDocument: {},
      pages: 1,
      pageFilter: 1,
      keywordsFilter: '',
      statusFilter: 'published',
      categoryFilter: '',
      positionFilter: '',
      tagFilter: '',
      isDueFilter: '',
      isOverDueFilter: '',
      mutableOwnershipFilter: 'all',
      mutableIsDueFilter: false,
      mutableIsOverDueFilter: false,
      statusses: {
        'published': 'Gepubliceerd',
        'draft': 'In concept',
        'archived': 'Gearchiveerd'
      }
    }
  },
  methods: {
    searchDocuments: function () {
      this.$bus.$emit('loadingEvent', true)

      // determine api route based on ownership filter
      if (this.mutableOwnershipFilter === 'me') {
        var documentRoute = 'me/documents'
      } else {
        documentRoute = 'documents'
      }

      this.$api.get(documentRoute + '?search=' + this.keywordsFilter + '&status=' + this.statusFilter + '&page=' + this.pageFilter + this.categoryFilter + this.positionFilter + this.tagFilter + this.isDueFilter + this.isOverDueFilter + '&include=categories,attachments,positions,departments&sort=name&limit=50').then((response) => {
        this.documents = response.data.data

        this.pages = response.data.meta.pagination.total_pages
        this.pageFilter = response.data.meta.pagination.current_page

        this.$bus.$emit('loadingEvent', false)
      }, (err) => {
        console.log(err)
      })
    },
    setPageFilter: function (page) {
      this.pageFilter = page
    },
    setKeywordFilter: function (keywords) {
      this.keywordsFilter = keywords
      this.pageFilter = 1
    },
    setStatusFilter: function (status) {
      this.statusFilter = status
      this.pageFilter = 1
    },
    setOwnershipFilter: function (ownership) {
      this.mutableOwnershipFilter = ownership
      this.pageFilter = 1
    },
    setCategoryFilter: function (categories) {
      this.categoryFilter = ''
      for (let category of categories) {
        this.categoryFilter += '&categories[]=' + category.id
      }
      this.pageFilter = 1
    },
    setPositionFilter: function (positions) {
      this.positionFilter = ''
      for (let position of positions) {
        this.positionFilter += '&positions[]=' + position.id
      }
      this.pageFilter = 1
    },
    setTagFilter: function (tags) {
      this.tagFilter = ''
      for (let tag of tags) {
        this.tagFilter += '&tags[]=' + tag.id
      }
      this.pageFilter = 1
    },
    setIsDueFilter: function (status) {
      if (status) {
        this.isDueFilter = '&is_due=1'
      }
    },
    setIsOverDueFilter: function (status) {
      if (status) {
        this.isOverDueFilter = '&is_over_due=1'
      }
    },
    archiveDocumentModal: function (document) {
      this.tobeArchivedDocument = document
      this.$bus.$emit('askEntityArchiveModalEvent', true)
    },
    archiveDocument: function (document) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Artikel', document.id, '/document')
    },
    deleteDocumentModal: function (document) {
      this.tobeDeletedDocument = document
      this.$bus.$emit('askEntityDeleteModalEvent', true)
    },
    deleteDocument: function (document) {
      // handle deleting through entity abstraction layer
      this.$entity.delete('Artikel', document.id, '/document')
    },
    editDocument: function (document) {
      // navigate to document edit
      this.$router.push({ name: 'documentEdit', params: { id: document.id } })
    },
    downloadDocument: function (document) {
      // handle archiving through entity abstraction layer
      var token = Cookies.get('access_token')
      window.location.href = process.env.VUE_APP_API_URL + '/document/' + document.id + '/file?access_token=' + token
    },
    downloadDocumentWord: function (document) {
      // handle archiving through entity abstraction layer
      var token = Cookies.get('access_token')
      window.location.href = process.env.VUE_APP_API_URL + '/document/' + document.id + '/fileword?access_token=' + token
    },
    createDocument: function () {
      this.$bus.$emit('savingEvent', true)

      // create new document in draft
      let newDocument = {}
      newDocument.name = 'Nieuw document'
      newDocument.status = 'draft'

      // post to api and forward
      this.$api.post('documents', newDocument).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Artikel niet aangemaakt, controleer de invoervelden')
        } else {
          newDocument = response.data.data
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Artikel ' + newDocument.name + ' is succesvol toegevoegd')
          this.errors = {}
          this.$router.push('/document/' + newDocument.id)
        }
      }, (error) => {
        this.$bus.$emit('savedEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    }
  },
  mounted: function () {
    this.searchDocuments()

    // listen for search event emitted from search bar
    this.$bus.$on('askSearchEvent', (keywords) => this.setKeywordFilter(keywords))
    // listen for page change event emitted from a paginator
    this.$bus.$on('askPageChangeEvent', (page) => this.setPageFilter(page))
    // listen for status filter change event emitted from a search bar
    this.$bus.$on('askStatusChangeEvent', (status) => this.setStatusFilter(status))
    // listen for ownership filter change event emitted from a search bar
    this.$bus.$on('askOwnershipChangeEvent', (ownership) => this.setOwnershipFilter(ownership))
    // listen for category filter change event emitted from a search bar
    this.$bus.$on('askCategoryFilterChangeEvent', (categories) => this.setCategoryFilter(categories))
    // listen for position filter change event emitted from a search bar
    this.$bus.$on('askPositionFilterChangeEvent', (positions) => this.setPositionFilter(positions))
    // listen for tag filter change event emitted from a search bar
    this.$bus.$on('askTagFilterChangeEvent', (tags) => this.setTagFilter(tags))

    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (document) => this.archiveDocument(document))
    // listen for archived event
    this.$bus.$on('entityArchivedEvent', () => this.searchDocuments())

    // listen for delete event
    this.$bus.$on('askEntityDeleteEvent', (document) => this.deleteDocument(document))
    // listen for deleted event
    this.$bus.$on('entityDeletedEvent', () => this.searchDocuments())
  },
  created: function () {
    this.mutableOwnershipFilter = this.initialOwnershipFilter

    this.mutableIsDueFilter = this.initialIsDueFilter
    this.setIsDueFilter(this.initialIsDueFilter)

    this.mutableIsOverDueFilter = this.initialIsOverDueFilter
    this.setIsOverDueFilter(this.initialIsOverDueFilter)
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askArchiveTagEvent')
    this.$bus.$off('askPageChangeEvent')
    this.$bus.$off('askStatusChangeEvent')
    this.$bus.$off('askOwnershipChangeEvent')
    this.$bus.$off('askSearchEvent')
    this.$bus.$off('askCategoryFilterChangeEvent')
    this.$bus.$off('askPositionFilterChangeEvent')
    this.$bus.$off('askTagFilterChangeEvent')

    this.$bus.$off('askEntityArchiveEvent')
    this.$bus.$off('entityArchivedEvent')

    this.$bus.$off('askEntityDeleteEvent')
    this.$bus.$off('entityDeletedEvent')
  },
  watch: {
    keywordsFilter: function () {
      this.searchDocuments()
    },
    pageFilter: function () {
      this.searchDocuments()
    },
    statusFilter: function () {
      this.searchDocuments()
    },
    mutableOwnershipFilter: function () {
      this.searchDocuments()
    },
    categoryFilter: function () {
      this.searchDocuments()
    },
    positionFilter: function () {
      this.searchDocuments()
    },
    tagFilter: function () {
      this.searchDocuments()
    }
  },
  components: {
    'index-bar': indexBar,
    'index-pagination': indexPagination,
    'archive-modal': archiveModal,
    'delete-modal': deleteModal,
    'no-entity': noEntity,
    'entity-relations-badge': entityRelationsBadge
  }
}
</script>
