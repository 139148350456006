<template lang="html">
  <div id="department">

    <archive-modal
      :entity="this.department" 
      :entityName="this.department.name" 
      entityType='department' />

    <div class="columns">
      <div class="column is-7">
        <div class="box">
          <div class="field">
            <label class="label title is-4 has-text-weight-light">{{ department.name }}</label>
            <div class="control">
              <input class="input" @focus="$event.target.select()" type="text" v-model="department.name" placeholder="Naam van de afdeling" 
                  v-bind:class="{ 'is-danger': errors.name }" 
                  v-bind:disabled="disabled"
                  v-on:keyup.enter="updateDepartment()">
            </div>
            <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Omschrijving</label>
            <div class="control">
              <textarea class="textarea" v-model="department.description" placeholder="Omschrijving van de afdeling"
                v-bind:class="{ 'is-danger': errors.description }" 
                v-bind:disabled="disabled"
                v-on:keyup.enter="updateDepartment()">
              </textarea>
            </div>
            <p v-for="(error, index) in errors.description" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Bovenliggende afdeling</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="department.parent_id" v-bind:disabled="disabled">
                  <option :value="null">Geen</option>
                  <optgroup v-for="(department, index) in departments" :key="index">
                    <option :value="department.id">{{ department.name }}</option>
                    <option v-for="(subdepartment, index) in department.descendants" :value="subdepartment.id" :key="index"><span v-for="(subdepartment, index) in subdepartment.depth" :key="index">-</span>> {{ subdepartment.name }}</option>
                  </optgroup>
                </select>
              </div>
              <p v-for="(error, index) in errors.parent_id" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <hr />
          <div class="field">
            <p>
              <span class="icon has-text-primary is-pulled-right" v-if="isReaderQuickswitchEnabled">
                <i class="mdi mdi-account-switch"></i>
              </span> 
              <span class="icon has-text-grey-light is-pulled-right" v-if="!isReaderQuickswitchEnabled">
                <i class="mdi mdi-account-switch"></i>
              </span> 
              <strong>Snelle gebruikerswissel</strong> toestaan
            </p>
            <toggle-button
              :sync="true"
              v-model="isReaderQuickswitchEnabled"
              :width="58"
              :height="25"
              :labels="{checked: 'Ja', unchecked: 'Nee'}" 
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
            <article class="message is-danger" v-if="isReaderQuickswitchEnabled">
              <div class="message-body">
                <span>
                  <strong>Let op:</strong> alle lezers (medewerkers) die in deze afdeling vallen kunnen hiermee in de App zonder uit te loggen
                  wisselen naar een andere lezer. Dit kun je gebruiken als bijvoorbeeld een hele afdeling een apparaat deelt. 
                </span>
              </div>
            </article>

          </div>
          <div class="field">
            <label class="label">Status</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="department.status" v-bind:disabled="disabled">
                  <option v-for="(status, index) in statusses" :value="index" :key="index">{{ status }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.status" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-5">
        <department-relations 
          :department="department" 
          :attachedPositions="attachedPositions"
          :attachedReaders="attachedReaders"
          :attachedSubdepartments="attachedSubdepartments"
          :disabled="disabled" />
      </div>
      <savebar
        :entity="department"
        entityName="Afdeling">
      </savebar>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import savebar from '../savebar'
import departmentRelations from './childs/department-relations'
import archiveModal from '../common/archive-modal'
import ToggleButton from 'vue-js-toggle-button/src/Button'

export default {
  name: 'departmentEdit',
  data: function () {
    return {
      department: [],
      departments: [],
      errors: {},
      disabled: false,
      attachedPositions: [],
      attachedSubdepartments: [],
      attachedReaders: [],
      statusses: {
        'active': 'Actief',
        'archived': 'Gearchiveerd'
      },
      isReaderQuickswitchEnabled: false
    }
  },
  methods: {
    getDepartment: function (departmentId) {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('department/' + departmentId + '?include=positions,readers').then((response) => {
        this.department = response.data.data

        this.attachedSubdepartments = []
        for (let subdepartment of this.department.descendants) {
          if (subdepartment.status === 'active') {
            this.attachedSubdepartments.push({
              id: subdepartment.id,
              name: subdepartment.name})
          }
        }

        this.attachedPositions = []
        for (let position of this.department.positions.data) {
          if (position.status === 'active') {
            this.attachedPositions.push({
              id: position.id,
              name: position.name })
          }
        }

        this.attachedReaders = []
        for (let reader of this.department.readers.data) {
          if (reader.status === 'active') {
            this.attachedReaders.push({
              id: reader.id,
              name: reader.name })
          }
        }

        this.isReaderQuickswitchEnabled = Boolean(this.department.is_reader_quickswitch_enabled)

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    updateDepartment: function () {
      this.$bus.$emit('savingEvent', true)
      this.disabled = true
      this.$api.put('department/' + this.department.id, this.department).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Controleer de invoervelden')
        } else {
          this.tag = response.data.data
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Afdeling ' + this.department.name + ' is succesvol toegevoegd')
          this.errors = {}
          this.$router.push('/departments')
        }
      }, () => {
        this.$bus.$emit('savingEvent', false)
        this.disabled = false
      })
    },
    getDepartments: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('departments?status=active&limit=100').then((response) => {
        this.departments = response.data.data
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    archiveDepartment: function (department) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Afdeling', department.id, '/department')
    }
  },
  mounted: function () {
    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', this.updateDepartment)
    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (department) => this.archiveDepartment(department))

    this.getDepartments()
    this.getDepartment(this.$route.params.id)
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askSaveEvent')
    this.$bus.$off('askEntityArchiveEvent')
  },
  components: {
    'savebar': savebar,
    'department-relations': departmentRelations,
    'archive-modal': archiveModal,
    'toggle-button': ToggleButton
  },
  watch: {
    '$route' (to) {
      this.getDepartment(to.params.id)
    },
    isReaderQuickswitchEnabled: function (status) {
      this.department.is_reader_quickswitch_enabled = status ? 1 : 0
    }
  }
}
</script>