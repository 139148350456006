<template lang="html">
  <div class="box is-paddingless" v-if="!disabled">
    <h1>Koppelingen</h1>
    <div class="tabs">
      <ul>
        <li v-on:click="toggleTab('documents')" v-bind:class="{ 'is-active': activeTab == 'documents' }">
          <a>
            <span>Artikelen <span class="tag is-info" v-show="attachedDocuments.length > 0"> {{ attachedDocuments.length }}</span></span>
          </a>
        </li>
        <li v-on:click="toggleTab('attachments')" v-bind:class="{ 'is-active': activeTab == 'attachments' }">
          <a>
            <span>Bijlages <span class="tag is-info" v-show="attachedAttachments.length > 0"> {{ attachedAttachments.length }}</span></span>
          </a>
        </li>
      </ul>
    </div>

    <div class="tab is-paddingless" v-show="activeTab == 'documents'">
      <table class="table is-fullwidth" v-show="attachedDocuments.length > 0">
        <thead>
          <tr>
            <th><abbr title="Naam">Naam</abbr></th>
            <th><abbr title="Schrijver">Schrijver</abbr></th>
            <th><abbr title="Aangepast">Aangepast</abbr></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in attachedDocuments" :key="document.id">
            <td>{{ document.name }}</td>
            <td>{{ document.writer }}</td>
            <td>{{ document.updated_at | moment("DD-MM-YYYY") }}</td>
            <td>
              <router-link class="button is-small is-pulled-right" :to="'/document/'+ document.id">
                Bewerk
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <article class="message is-info" v-show="attachedDocuments.length === 0">
        <div class="message-body">
          Geen artikelen gekoppeld aan {{ tag.name }}
        </div>
      </article>
    </div>

    <div class="tab is-paddingless" v-show="activeTab == 'attachments'">
      <table class="table is-fullwidth" v-show="attachedAttachments.length > 0">
        <thead>
          <tr>
            <th><abbr title="Naam">Naam</abbr></th>
            <th><abbr title="Schrijver">Schrijver</abbr></th>
            <th><abbr title="Aangepast">Aangepast</abbr></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="attachment in attachedAttachments" :key="attachment.id">
            <td>{{ attachment.name }}</td>
            <td>{{ attachment.writer }}</td>
            <td>{{ attachment.updated_at | moment("DD-MM-YYYY") }}</td>
            <td>
              <router-link class="button is-small is-pulled-right" :to="'/attachment/'+ attachment.id">
                Bewerk
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <article class="message is-info" v-show="attachedAttachments.length === 0">
        <div class="message-body">
          Geen bijlages gekoppeld aan {{ tag.name }}
        </div>
      </article>
    </div>
  </div>
</template>

<script>

export default {
  name: 'tagRelations',
  props: [
    'tag',
    'disabled',
    'attachedAttachments',
    'attachedDocuments'
  ],
  data: function () {
    return {
      activeTab: 'documents'
    }
  },
  methods: {
    toggleTab: function (tabName) {
      this.activeTab = tabName
    }
  }
}
</script>