<template lang="html">
  <div class="field has-addons">
    <div class="dropdown control is-hoverable">
      <div class="dropdown-trigger">
        <button class="button" v-if="personalisationScope == '*'">
          <i class="mdi mdi-infinity mr-2"></i> Iedereen
        </button>
        <button class="button" v-if="personalisationScope == 'position'">
          <i class="mdi mdi-account-card-details mr-2"></i> Op functie
        </button>
        <button class="button" v-if="personalisationScope == 'department'">
          <i class="mdi mdi-account-multiple mr-2"></i> Op afdeling
        </button>
      </div>
      <div class="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a class="dropdown-item" v-on:click="setPersonalisationScope('*')">
            <i class="mdi mdi-infinity"></i> Iedereen
          </a>
          <a class="dropdown-item" v-on:click="setPersonalisationScope('position')">
            <i class="mdi mdi-account-card-details"></i> Op functie
          </a>
          <a class="dropdown-item" v-on:click="setPersonalisationScope('department')">
            <i class="mdi mdi-account-multiple"></i> Op afdeling
          </a>
        </div>
      </div>
    </div>
    <p class="control is-expanded" v-show="personalisationScope === '*'">
      <input type="text" class="input" disabled="disabled" /> 
    </p>
    <p class="control is-expanded" v-show="personalisationScope === 'position'">
      <v-select ref="positionSelect" multiple
        :options="positions"
        label="name"
        v-model="attachedPositions">

        <no-entity-autocomplete
          title="Functie niet gevonden"
          message="Probeer andere zoektermen of voeg een nieuwe functie toe via het hoofdmenu."
          icon="account-card-details"
          slot="no-options" />

      </v-select>
    </p>
    <p class="control is-expanded" v-show="personalisationScope === 'department'">
      <v-select ref="departmentSelect" multiple
        :options="departments"
        label="name"
        v-model="attachedDepartments">

        <no-entity-autocomplete
          title="Afdeling niet gevonden"
          message="Probeer andere zoektermen of voeg een afdeling toe via het hoofdmenu."
          icon="mdi-account-multiple"
          slot="no-options" />

      </v-select>
    </p>
  </div>
</template>

<script>

import vSelect from 'vue-select'
import noEntityAutocomplete from './no-entity-autocomplete'

export default {
  name: 'personalisationScopeSelect',
  props: [
    'attachedPositionsImmutable',
    'attachedDepartmentsImmutable'
  ],
  data: function () {
    return {
      personalisationScope: '*',
      departmentPreselection: false,
      attachedPositions: [],
      attachedDepartments: [],
      positions: [],
      departments: []
    }
  },
  methods: {
    getPositions: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('positions?limit=100').then((response) => {
        for (let position of response.data.data) {
          this.positions.push({
            id: position.id,
            name: position.name,
            department_id: position.department_id })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getDepartments: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('departments/flat?limit=100').then((response) => {
        for (let department of response.data.data) {
          this.departments.push({
            id: department.id,
            name: department.name })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    setPersonalisationScope: function (scope) {
      this.personalisationScope = scope

      switch (scope) {
        case '*':
          // reset attached departments and positions
          this.attachedDepartments = []
          this.attachedPositions = []
          break

        case 'position':
          // reset attached departments
          this.attachedDepartments = []
          break

        case 'department':
          // reset attached positions
          this.attachedPositions = []
          break
      }
    }
  },
  mounted: function () {
    this.getPositions()
    this.getDepartments()
  },
  watch: {
    'attachedPositionsImmutable' (to) {
      this.attachedPositions = to
      if (to.length !== 0) {
        this.personalisationScope = 'position'
      }
    },
    'attachedDepartmentsImmutable' (to) {
      this.attachedDepartments = to
      if (to.length !== 0) {
        this.personalisationScope = 'department'
      }
    },
    'attachedPositions' (to) {
      this.$emit('updatePositions', to)
    },
    'attachedDepartments' (to) {
      this.$emit('updateDepartments', to)
    }
  },
  components: {
    'v-select': vSelect,
    'no-entity-autocomplete': noEntityAutocomplete
  }
}
</script>
