<template lang="html">
  <div class="box">

    <index-bar
      createPath="/notifications/create"
      createText="Notificatie versturen" />

    <table class="table is-fullwidth" v-if="notifications.length >= 1">
      <thead>
        <tr>
          <th><abbr title="Titel">Titel</abbr></th>
          <th><abbr title="Bericht">Bericht</abbr></th>
          <th><abbr title="Lezer">Lezer</abbr></th>
          <th><abbr title="Schrijver">Schrijver</abbr></th>
          <th><abbr title="Type">Type</abbr></th>
          <th><abbr title="Verstuurd">Verstuurd</abbr></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="notification in notifications" :key="notification.id">
          <td>{{ notification.title }}</td>
          <td>{{ notification.content }}</td>
          <td><span v-if="notification.reader_id">{{ notification.reader.data.name }}</span></td>
          <td><span v-if="notification.writer_id">{{ notification.writer.data.name }}</span></td>
          <td>
            <i class="mdi mdi-cellphone-iphone" v-if="notification.type === 'push'"></i>
            <i class="mdi mdi-email-outline" v-if="notification.type === 'mail'"></i>
          </td>
          <td>{{ notification.created_at | moment("DD-MM-YYYY") }}</td>
        </tr>
      </tbody>
    </table>

    <no-entity
      title="Geen notificaties gevonden"
      message="Probeer andere zoektermen of filters."
      icon="bell-outline"
      :count="notifications.length" />

    <index-pagination
      :pages="pages"
      :currentPage="pageFilter" />

  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import indexBar from '../index-bar'
import indexPagination from '../index-pagination'
import noEntity from '../common/no-entity'

export default {
  name: 'notifications',
  data: function () {
    return {
      notifications: [],
      pages: 1,
      pageFilter: 1,
      keywordsFilter: ''
    }
  },
  methods: {
    searchNotifications: function () {
      this.$bus.$emit('loadingEvent', true)

      this.$api.get('notifications?content=*' + this.keywordsFilter + '*&page=' + this.pageFilter + '&include=document,attachment,comment,writer,reader').then((response) => {
        this.notifications = response.data.data

        this.pages = response.data.meta.pagination.total_pages
        this.pageFilter = response.data.meta.pagination.current_page

        this.$bus.$emit('loadingEvent', false)
      }, (err) => {
        console.log(err)
      })
    },
    setPageFilter: function (page) {
      this.pageFilter = page
    },
    setKeywordFilter: function (keywords) {
      this.keywordsFilter = keywords
      this.pageFilter = 1
    }
  },
  mounted: function () {
    this.searchNotifications()

    // listen for search event emitted from search bar
    this.$bus.$on('askSearchEvent', (keywords) => this.setKeywordFilter(keywords))
    // listen for page change event emitted from a paginator
    this.$bus.$on('askPageChangeEvent', (page) => this.setPageFilter(page))
  },
  watch: {
    keywordsFilter: function () {
      // perform new search if keywords change
      this.searchNotifications()
    },
    pageFilter: function () {
      // perform new search if page is changed
      this.searchNotifications()
    }
  },
  components: {
    'index-bar': indexBar,
    'index-pagination': indexPagination,
    'no-entity': noEntity
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askPageChangeEvent')
    this.$bus.$off('askSearchEvent')
  }
}
</script>
