<template lang="html">
  <div id="reader">

    <archive-modal
      :entity="this.reader"
      :entityName="this.reader.name" />

    <div class="columns">
      <div class="column is-7">
        <div class="box">
          <div class="field">
            <label class="label title is-4 has-text-weight-light">{{ reader.name }}</label>
            <div class="control">
              <input class="input" type="text" v-model="reader.name" placeholder="Naam van de lezer"
                v-bind:class="{ 'is-danger': errors.name }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="updateReader()">
            </div>
            <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">E-mailadres</label>
            <div class="control">
              <input class="input" type="email" v-model="reader.email" placeholder="E-mailadres van de lezer"
                v-bind:class="{ 'is-danger': errors.email }"
                v-on:keyup.enter="updateReader()">
            </div>
            <p v-for="(error, index) in errors.email" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Herstel e-mailadres <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Als een herstel e-mailadres opgegeven is worden hier wachtwoord reset meldingen heen gestuurd i.p.v. naar het login e-mailadres.'"></i></label>
            <div class="control">
              <input class="input" type="email_recovery" v-model="reader.email_recovery" placeholder="Herstel e-mailadres van de lezer"
                v-bind:class="{ 'is-danger': errors.email_recovery }"
                v-on:keyup.enter="updateReader()">
            </div>
            <p v-for="(error, index) in errors.email_recovery" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Mobiel telefoonnummer</label>
            <div class="control">
              <input class="input" type="text" v-model="reader.cellphone" placeholder="Mobiel telefoonnummer van de lezer"
                v-bind:class="{ 'is-danger': errors.cellphone }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="updateReader()">
            </div>
            <p class="has-text-grey">Gebruik een 13-15 cijferig telefoonnummer, met landcode. Bijvoorbeeld: 0031612345678.</p>
            <p v-for="(error, index) in errors.cellphone" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Afdeling</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="reader.department_id" v-bind:disabled="disabled">
                  <option v-for="(department, index) in departments" :value="department.id" :key="index">{{ department.name }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.department_id" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Functies <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Je kunt één of meerdere functies aan een lezer koppelen'"></i></label>
            <div class="control">
              <v-select ref="vselect" multiple
                :options="positions"
                label="name"
                v-model="attachedPositions">

                <no-entity-autocomplete
                  title="Functie niet gevonden"
                  message="Probeer andere zoektermen of voeg een nieuwe functie toe via het hoofdmenu."
                  icon="account-card-details"
                  slot="no-options" />

              </v-select>
            </div>

            <div class="notification is-warning is-light p-2 mt-2">
              Na het wijzigen van de functie(s) of afdeling van de lezer kan het tot <strong>60 minuten</strong> duren alvorens dit in de personalisatie van Handboek is doorgevoerd.
            </div>
          </div>
          <div class="field">
            <label class="label">Status</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="reader.status" v-bind:disabled="disabled">
                  <option v-for="(status, index) in statusses" :value="index" :key="index">{{ status }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.status" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>

          <article class="message is-info" v-if="reader.status == 'archived'">
            <div class="message-body">
              <span>
                <strong>Let op:</strong> bij het her-activeren van deze lezer bent u extra maandelijkse kosten van
                <money-format
                  class="is-inline"
                  v-if="account.price_readers"
                  :value="account.price_readers / 100"
                  locale="nl"
                  currency-code="EUR">
               </money-format> verschuldigd.
              </span>
            </div>
          </article>

          <div class="field">
            <label class="label">Extern nummer <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Deze lezer is afkomstig uit een externe bron (een integratie). Onderstaand nummer vormt de koppeling met de externe bron. Wijzig dit nummer alleen om een koppelingsprobleem op te lossen.'"></i></label>
            <div class="control">
              <input class="input" type="text" v-model="reader.external_id" placeholder="Extern nummer van de lezer"
                     v-bind:class="{ 'is-danger': errors.external_id }"
                     v-on:keyup.enter="updateReader()">
            </div>
            <p v-for="(error, index) in errors.external_id" class="help is-danger" :key="index">{{ error }}</p>
          </div>

          <article class="message is-warning" v-if="account.limit_readers > 0 && account.limit_readers === account.usage_readers && reader.status == 'archived'">
            <div class="message-body">
              <span>
                <strong>Let op:</strong> het maximaal aantal van {{ account.limit_readers }} actieve lezers binnen uw {{ account.tier_name }} pakket is bereikt. Bij het
                her-activeren van deze lezer wordt, aan het einde van deze maand, uw pakket gewijzigd.
              </span>
            </div>
          </article>

        </div>
      </div>
      <div class="column is-5">

        <reader-relations
          :reader="reader"
          :attachedNotifications="attachedNotifications"
          :attachedComments="attachedComments"
          :attachedBookmarks="attachedBookmarks"
          :disabled="disabled" />

        <reader-sessions
          :reader="reader"
          :attachedSessions="attachedSessions" />

      </div>

      <savebar
        :entity="reader"
        entityName="Lezer">
      </savebar>

    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import savebar from '../savebar'
import readerRelations from './childs/reader-relations'
import readerSessions from './childs/reader-sessions'
import vSelect from 'vue-select'
import archiveModal from '../common/archive-modal'
import noEntityAutocomplete from '../common/no-entity-autocomplete'
import MoneyFormat from 'vue-money-format'

export default {
  name: 'readerEdit',
  data: function () {
    return {
      reader: [],
      account: {},
      positions: [],
      departments: [],
      attachedPositions: [],
      attachedComments: [],
      attachedBookmarks: [],
      attachedNotifications: [],
      attachedSessions: [],
      errors: {},
      disabled: false,
      statusses: {
        'active': 'Actief',
        'archived': 'Inactief'
      }
    }
  },
  methods: {
    getReader: function (readerId) {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('reader/' + readerId + '?include=comments,positions,notifications,bookmarks,sessions').then((response) => {
        this.reader = response.data.data

        for (let position of this.reader.positions.data) {
          this.attachedPositions.push({
            id: position.id,
            name: position.name + ' (' + position.department.data.name + ')' })
        }

        this.attachedComments = []
        for (let comment of this.reader.comments.data) {
          this.attachedComments.push(comment)
        }

        this.attachedBookmarks = []
        for (let bookmark of this.reader.bookmarks.data) {
          this.attachedBookmarks.push({
            id: bookmark.id,
            name: bookmark.name,
            writer: bookmark.writer.data.name })
        }

        this.attachedNotifications = []
        for (let notification of this.reader.notifications.data) {
          this.attachedNotifications.push({
            id: notification.id,
            title: notification.title,
            content: notification.content,
            created_at: notification.created_at,
            document_id: notification.document_id})
        }

        this.attachedSessions = []
        for (let session of this.reader.sessions.data) {
          this.attachedSessions.push({
            id: session.id,
            created_at: session.created_at,
            expires_at: session.expires_at,
            client_id: session.client_id })
        }

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    updateReader: function () {
      this.$bus.$emit('savingEvent', true)
      this.disabled = true
      this.$api.put('reader/' + this.reader.id, this.reader).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Controleer de invoervelden')
        } else {
          this.reader = response.data.data

          // handle attachment of positions through entity abstraction layer
          var selectedPositions = []
          for (let position of this.attachedPositions) {
            selectedPositions.push(position.id)
          }
          this.$entity.reattach(this.reader, { 'positions': selectedPositions }, 'Functies', '/reader/' + this.reader.id + '/positions')

          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Lezer ' + this.reader.name + ' is succesvol opgeslagen')
          this.errors = {}
          this.$router.push('/readers')
        }
      }, () => {
        this.$bus.$emit('savingEvent', false)
        this.disabled = false
      })
    },
    getPositions: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('positions?limit=100').then((response) => {
        for (let position of response.data.data) {
          this.positions.push({
            id: position.id,
            name: position.name + ' (' + position.department.data.name + ')' })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, () => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      })
    },
    getDepartments: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('departments?limit=100').then((response) => {
        for (let department of response.data.data) {
          this.departments.push({
            id: department.id,
            name: department.name
          })

          // also include subdepartments
          for (let subdepartment of department.descendants) {
            this.departments.push({
              id: subdepartment.id,
              name: subdepartment.name
            })
          }
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, () => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      })
    },
    archiveReader: function (reader) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Lezer', reader.id, '/reader')
    },
    getAccount: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('account').then((response) => {
        this.account = response.data.data
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, () => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      })
    }
  },
  mounted: function () {
    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', this.updateReader)
    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (reader) => this.archiveReader(reader))

    this.getPositions()
    this.getDepartments()
    this.getAccount()
    this.getReader(this.$route.params.id)
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askSaveEvent')
    this.$bus.$off('askEntityArchiveEvent')
  },
  components: {
    'savebar': savebar,
    'reader-relations': readerRelations,
    'reader-sessions': readerSessions,
    'v-select': vSelect,
    'archive-modal': archiveModal,
    'no-entity-autocomplete': noEntityAutocomplete,
    'money-format': MoneyFormat
  }
}
</script>
