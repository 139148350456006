<template lang="html">
  <div class="modal" v-bind:class="{ 'is-active': visible }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <p><strong>Je huidige sessie is verloopt binnenkort</strong></p> 
        <p>Als je niets doet zal je binnen <strong>{{ dueHours }} uur</strong> automatisch worden uitgelogd.</p>
        <hr />
        <a class="button is-primary" v-on:click="askExtend()" v-bind:class="{ 'is-disabled': extending }">
          <span class="icon">
            <i class="mdi mdi-refresh"></i>
          </span>
          <span v-if="!extending">Ingelogd blijven</span>
          <span v-else>Bezig met verlengen</span>
        </a>
        <a class="button is-pulled-right" v-on:click="askLogout()">
          <span class="icon">
            <i class="mdi mdi-logout"></i>
          </span>
          <span>Uitloggen</span>
        </a>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
  </div>
</template>

<script>

export default {
  name: 'extendLoginModal',
  props: [
    'entity',
    'entityName'
  ],
  data: function () {
    return {
      extending: false,
      visible: false,
      dueHours: 0
    }
  },
  methods: {
    askExtend: function () {
      this.$bus.$emit('askWriterExtendLoginEvent')
      this.extending = true
    },
    askLogout: function () {
      this.$bus.$emit('askWriterLogoutEvent', true)
      this.visible = false
    },
    close: function () {
      this.extending = false
      this.visible = false
    },
    spawnModal: function (dueHours) {
      this.extending = false
      this.dueHours = dueHours
      this.visible = true
    },
    setExtending: function (state) {
      this.extending = state
      if (state === false) {
        this.visible = false
      }
    }
  },
  mounted: function () {
    // listen for ask for modal event
    this.$bus.$on('apiAuthorizationDueEvent', (dueHours) => this.spawnModal(dueHours))
    this.$bus.$on('apiExtendedLoginEvent', this.close)
    this.$bus.$on('apiLoggedOutEvent', this.close)
    this.$bus.$on('apiRefreshingToken', (state) => this.setExtending(state))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('apiAuthorizationDueEvent')
    this.$bus.$off('apiExtendedLoginEvent')
    this.$bus.$off('apiLoggedOutEvent')
    this.$bus.$off('unpublishingEvent')
  }
}
</script>