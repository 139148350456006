<template lang="html">
  <div class="box is-paddingless">
    <h1>Inlog sessies</h1>
    <div class="tab">
      <table class="table is-fullwidth" v-show="attachedSessions.length > 0">
        <thead>
          <tr>
            <th><abbr title="Start">Ingelogd op</abbr></th>
            <th><abbr title="Verloopt">Sessie verloopt</abbr></th>
            <th><abbr title="Verloopt">Platform</abbr></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="session in attachedSessions" :key="session.id">
            <td>{{ session.created_at | moment("DD-MM-YYYY") }}</td>
            <td>{{ session.expires_at | moment("from", "now") }}</td>
            <td>
              <i class="mdi mdi-cellphone-iphone" v-if="session.client_id === 2"></i>
              <i class="mdi mdi-laptop-chromebook" v-if="session.client_id === 3"></i>
            </td>
          </tr>
        </tbody>
      </table>
      <article class="message is-info" v-show="attachedSessions.length === 0">
        <div class="message-body">
          {{ reader.name }} heeft geen actieve sessies
        </div>
      </article>
    </div>
  </div>
</template>

<script>

export default {
  name: 'readerSessions',
  props: [
    'reader',
    'attachedSessions'
  ],
  data: function () {
    return {

    }
  }
}
</script>
