<template lang="html">
  <div id="document">

    <unlock-message
      :entity="document"
      entityName="document" />

    <archive-modal
      :entity="this.document"
      :entityName="this.document.name"
      entityType='document' />

    <version-modal
      :entity="this.document"
      :entityName="this.document.name" />

    <latest-version-modal />

    <revision-modal />

    <attachment-create-modal
      :entity="this.document" />

    <div class="columns">
      <div class="column is-7">
        <div class="box">
          <div class="field has-addons is-pulled-right">
            <p class="control" v-if="document.revisions && document.revisions.length > 0">
                <a class="button" v-on:click="showDocumentRevisions()" v-tooltip="'Toon de wijzigingshistorie van dit artikel'">
                  <span class="icon">
                    <i class="mdi mdi-history"></i>
                  </span>
                  <span class="tag is-info">{{ document.revisions.length }}</span>
                </a>
              </p>
            <p class="control">
              <a class="button is-pulled-right is-clearfix" style="margin-bottom: 1em" @click="toggleFoldGeneral">
                <span class="icon" v-if="isFoldedGeneral">
                  <i class="mdi mdi-chevron-down"></i>
                </span>
                <span v-if="isFoldedGeneral">Toon alle velden</span>
                <span class="icon" v-if="!isFoldedGeneral">
                  <i class="mdi mdi-chevron-up"></i>
                </span>
                <span v-if="!isFoldedGeneral">Verberg velden</span>
              </a>
            </p>
          </div>

          <div class="dropdown is-hoverable is-pulled-right" v-tooltip="'Download een QR code waarmee direct naar dit artikel in de App kan worden verwezen'">
            <div class="dropdown-trigger">
              <button class="button" aria-haspopup="true" aria-controls="dropdown-menu3">
                  <i class="mdi mdi-qrcode"></i>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu3" role="menu">
              <div class="dropdown-content">
                <a class="dropdown-item" v-on:click.stop="downloadQr(100)">
                  <i class="mdi mdi-qrcode"></i> Download klein
                </a>
                <a class="dropdown-item" v-on:click.stop="downloadQr(250)">
                  <i class="mdi mdi-qrcode"></i> Download middel
                </a>
                <a class="dropdown-item" v-on:click.stop="downloadQr(1250)">
                  <i class="mdi mdi-qrcode"></i> Download groot
                </a>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label title is-4 has-text-weight-light">{{ document.name }}
              <br /><span class="tag mt-2">Versie {{ document.version }}</span> <span class="tag mt-2 ml-1" :class="{'is-primary': document.status === 'published'}">{{ statusses[document.status] }}</span>
            </label>
            <div class="control">
              <input class="input" @focus="$event.target.select()" type="text" v-model="document.name" placeholder="Naam van het document"
                  v-bind:class="{ 'is-danger': errors.name }"
                  v-bind:disabled="disabled"
                  v-on:keyup.enter="updateDocument()">
            </div>
            <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Thema <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Koppel relevante thema\'s aan het artikel zodat lezers dit artikel op thema kunnen vinden.'"></i></label>
            <div class="control">
              <v-select ref="vselect" multiple
                :options="tags"
                label="name"
                v-model="attachedTags">

                <no-entity-autocomplete
                  title="Thema niet gevonden"
                  message="Probeer andere zoektermen of voeg een nieuw thema toe via het hoofdmenu."
                  icon="tag-outline"
                  slot="no-options" />

              </v-select>
            </div>
          </div>
          <div class="field">
            <label class="label">Categorie&euml;n <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Koppel het artikel aan relevante categorie&euml;n zodat lezers er naar toe kunnen bladeren.'"></i></label>
            <div class="control">
              <v-select ref="vselect" multiple
                :options="categories"
                label="name"
                v-model="attachedCategories">

                <template slot="option" slot-scope="option">
                    {{ option.arrow }} {{ option.name }}
                </template>

                <no-entity-autocomplete
                  title="Categorie niet gevonden"
                  message="Probeer andere zoektermen of voeg een nieuwe categorie toe via het hoofdmenu."
                  icon="folder-outline"
                  slot="no-options" />

              </v-select>
            </div>
          </div>
          <div class="field">
            <label class="label">Personalisatie <i class="mdi mdi-help-circle has-text-info" @click="isVisiblePersonalisationHelp = !isVisiblePersonalisationHelp"></i></label>

            <personalisation-scope-select
              :attachedPositionsImmutable="this.attachedPositions"
              :attachedDepartmentsImmutable="this.attachedDepartments"
              @updatePositions="updateAttachedPositions"
              @updateDepartments="updateAttachedDepartments" />

            <article class="message is-info mt-4" v-if="isVisiblePersonalisationHelp">
              <div class="message-body">
                  <p>Je kunt één of meerdere functies of afdelingen aan een artikel koppelen. Alleen lezers met deze functies of afdelingen zien dit artikel in hun persoonlijke weergave. Ze kunnen het artikel wel benaderen in de weergave voor iedereen. Het artikel {{ document.name }} is zichtbaar voor een lezer als:</p>
                  <div class="content">
                    <ul>
                      <li>Er geen functies of afdelingen zijn gekoppeld;</li>
                      <li>Als de functie van de lezer is gekoppeld;</li>
                      <li>Als de afdeling van de lezer is gekoppeld;</li>
                      <li>Als de functie van de lezer onder een gekoppelde afdeling valt.</li>
                    </ul>
                  </div>
              </div>
            </article>
          </div>
          <div class="field">
            <p>
              <span class="icon has-text-danger is-pulled-right" v-if="isPositionRestricted">
                <i class="mdi mdi-lock"></i>
              </span>
              <span class="icon has-text-primary is-pulled-right" v-if="!isPositionRestricted">
                <i class="mdi mdi-lock-open"></i>
              </span>
              <strong>Sluit de toegang af</strong> voor lezers die niet binnen de personalisatie vallen <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Het volledige artikel wordt onbenaderbaar voor alle lezers die niet binnen de personalisatie vallen.'"></i>
            </p>
            <toggle-button
              :sync="true"
              v-model="isPositionRestricted"
              :width="58"
              :height="25"
              :labels="{checked: 'Ja', unchecked: 'Nee'}"
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
          </div>
          <div class="field">
            <label class="label">Status</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="document.status" v-bind:disabled="disabled">
                  <option v-for="(status, index) in statusses" :value="index" :key="index">{{ status }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.status" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field" v-if="!isFoldedGeneral">
            <label class="label">Schrijver</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="document.writer_id" v-bind:disabled="disabled">
                  <option v-for="(writer, index) in writers" :value="writer.id" :key="index">{{ writer.name }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.writer_id" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field" v-if="!isFoldedGeneral">
            <label class="label">Verantwoordelijke</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="document.responsible_writer_id" v-bind:disabled="disabled">
                  <option v-for="(writer, index) in writers" :value="writer.id" :key="index">{{ writer.name }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.responsible_writer_id" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field" v-if="!isFoldedGeneral">
            <label class="label">Geldigheid <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Op basis van de geldigheidsdatum worden notificaties over het verlopen van het artikel verstuurd.'"></i></label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <datepicker
                  v-model="document.due_at"
                  name="due_at"
                  language="nl"
                  input-class="input"
                  :format="dueDateFormatter">
                </datepicker>
                <p v-for="(error, index) in errors.due_at" class="help is-danger" :key="index">{{ error }}</p>
              </div>
              <div class="control">
                <a class="button is-outlined" v-on:click="resetDueDate()">
                  <span class="icon">
                    <i class="mdi mdi-close"></i>
                  </span>
                  <span>Geldigheid opheffen</span>
                </a>
              </div>
            </div>
          </div>
          <div class="field" v-if="!isFoldedGeneral">
            <label class="label">Index <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Met deze numerieke index kun je een artikel hoger of lager in lijsten weergeven. Artikelen worden in de App en Desktop gesorteerd op index (van hoog naar laag) en daarna alfabetisch.'"></i></label>
            <div class="control">
              <input class="input" autofocus type="text" v-model="document.index" placeholder="Numerieke index van het artikel"
                  v-bind:class="{ 'is-danger': errors.index }"
                  v-bind:disabled="disabled"
                  v-on:keyup.enter="updateDocument()">
            </div>
            <p v-for="(error, index) in errors.index" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field" v-if="!isFoldedGeneral">
            <label class="label">Zoekwoorden</label>
            <div class="control">
              <input class="input" v-model="document.keywords" type="text" placeholder="Zoekwoorden voor dit artikel"
                v-bind:class="{ 'is-danger': errors.keywords }"
                v-bind:disabled="disabled">
            </div>
            <p v-for="(error, index) in errors.keywords" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field" v-if="!isFoldedGeneral">
            <label class="label">Beoordeling <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Het gemiddeld aantal sterren waarmee dit artikel is beoordeeld.'"></i></label>
            <div class="control">
              <star-rating
                :rating=document.average_rating
                :show-rating=false
                :star-size=25
                :read-only=true />
            </div>
          </div>
          <div class="field">
            <p>
              <span class="icon has-text-primary is-pulled-right" v-if="isMarkedAsChanged">
                <i class="mdi mdi-alert-decagram"></i>
              </span>
              <span class="icon has-text-grey is-pulled-right" v-if="!isMarkedAsChanged">
                <i class="mdi mdi-alert-decagram"></i>
              </span>
              <strong>Markeer artikel als gewijzigd</strong> <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Mits ingeschakeld wordt het artikel, na het opslaan, als gewijzigd aangemerkt en zichtbaar onder de laatste wijzigingen in App en Web.'"></i>
            </p>
            <toggle-button
              :sync="true"
              v-model="isMarkedAsChanged"
              :width="58"
              :height="25"
              :labels="{checked: 'Ja', unchecked: 'Nee'}"
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
          </div>
        </div>

        <paragraph
          v-for="(paragraph, index) in attachedParagraphs"
          v-bind:index="index"
          v-bind:key="paragraph.id"
          v-bind:paragraphImmutable="paragraph"
          v-bind:document="document"
          v-bind:count="attachedParagraphs.length"
          @destroy="destroyParagraph(index)"
          @up="moveUpParagraph(index)"
          @down="moveDownParagraph(index)"
          @change="changeParagraph(index)">
        </paragraph>

        <hr />
        <a class="button is-primary" v-on:click="addParagraph()">
          <span class="icon">
            <i class="mdi mdi-plus-circle-outline"></i>
          </span>
          <span>Paragraaf toevoegen</span>
        </a>

      </div>
      <div class="column is-5">

        <document-statistics
          :document="document"
          :readersViewed="readersViewed" />

        <document-notifications
          :document="document" />

        <document-versions
          :document="document"
          :attachedVersions="attachedVersions" />

        <document-relations
          :document="document"
          :attachedVersions="attachedVersions"
          :attachedAttachments="attachedAttachments"
          :attachedComments="attachedComments"
          :attachedDocuments="attachedDocuments"
          @attach-attachment="attachAttachment"
          @detach-attachment="detachAttachment"
          @attach-document="attachDocument"
          @detach-document="detachDocument"
        />

        <document-tools
          :document="document" />

      </div>

      <savebar :advanced="true"
        :entity="document"
        :entityName="'document'">
      </savebar>

    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
  @import '../../assets/scss/documents/document-edit.scss';
</style>

<script>

import savebar from '../savebar'
import paragraph from './childs/paragraph'
import documentRelations from './childs/document-relations'
import documentVersions from './childs/document-versions'
import documentStatistics from './childs/document-statistics'
import documentNotifications from './childs/document-notifications'
import documentTools from './childs/document-tools'
import unlockMessage from '../common/unlock-message'
import vSelect from 'vue-select'
import StarRating from 'vue-star-rating'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import archiveModal from '../common/archive-modal'
import versionModal from '../common/version-modal'
import revisionModal from '../common/revision-modal'
import latestVersionModal from '../common/latest-version-modal'
import attachmentCreateModal from '../attachments/attachment-create-modal'
import noEntityAutocomplete from '../common/no-entity-autocomplete'
import personalisationScopeSelect from '../common/personalisation-scope-select'
import ToggleButton from 'vue-js-toggle-button/src/Button'
import * as Cookies from 'tiny-cookie'

export default {
  name: 'documentEdit',
  data: function () {
    return {
      document: {},
      writers: [],
      tags: [],
      categories: [],
      departments: [],
      errors: {},
      disabled: false,
      positions: [],
      attachedParagraphs: [],
      attachments: [],
      attachedTags: [],
      attachedCategories: [],
      attachedPositions: [],
      attachedDepartments: [],
      attachedAttachments: [],
      attachedComments: [],
      attachedVersions: [],
      attachedDocuments: [],
      readersViewed: [],
      statusses: {
        'published': 'Gepubliceerd',
        'draft': 'In concept',
        'archived': 'Gearchiveerd'
      },
      isFoldedGeneral: true,
      isPositionRestricted: false,
      isMarkedAsChanged: false,
      changeCount: 0,
      tobeArchivedDocument: {},
      isVisiblePersonalisationHelp: false
    }
  },
  methods: {
    getDocument: function (documentId) {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('document/' + documentId + '?include=paragraphs,attachments,documents,comments,tags,categories,versions,locked_writer,notifications,positions,departments,readers_viewed').then((response) => {
        this.document = response.data.data

        this.attachedParagraphs = []
        for (let documentParagraph of this.document.paragraphs.data) {
          this.attachedParagraphs.push({
            id: documentParagraph.id,
            document_id: this.document.id,
            attachment_id: documentParagraph.attachment_id,
            has_image: documentParagraph.has_image,
            index: documentParagraph.index,
            name: documentParagraph.name,
            content: documentParagraph.content,
            positions: documentParagraph.positions.data,
            attachment: (documentParagraph.attachment) ? documentParagraph.attachment.data : null,
            revisions: documentParagraph.revisions,
            isSaved: true
          })
        }

        this.attachedVersions = []
        for (let version of this.document.versions.data) {
          this.attachedVersions.push({
            id: version.id,
            version: version.version,
            is_latest_version: version.is_latest_version,
            name: version.name,
            writer: version.writer.data.name,
            status: version.status,
            updated_at: version.updated_at })
        }

        this.attachedTags = []
        for (let tag of this.document.tags.data) {
          this.attachedTags.push({
            id: tag.id,
            name: tag.name })
        }

        this.attachedCategories = []
        for (let category of this.document.categories.data) {
          this.attachedCategories.push({
            id: category.id,
            name: category.name + ' (' + category.id + ')' })
        }

        this.attachedAttachments = []
        for (let attachment of this.document.attachments.data) {
          this.attachedAttachments.push({
            id: attachment.id,
            name: attachment.name,
            version: attachment.version,
            status: attachment.status })
        }

        this.attachedDocuments = []
        for (let document of this.document.documents.data) {
          this.attachedDocuments.push({
            id: document.id,
            name: document.name })
        }

        this.attachedComments = []
        for (let comment of this.document.comments.data) {
          this.attachedComments.push(comment)
        }

        this.readersViewed = []
        for (let reader of this.document.readers_viewed.data) {
          this.readersViewed.push({
            id: reader.id,
            name: reader.name })
        }

        this.attachedPositions = []
        for (let position of this.document.positions.data) {
          this.attachedPositions.push({
            id: position.id,
            name: position.name })
        }

        this.attachedDepartments = []
        for (let department of this.document.departments.data) {
          this.attachedDepartments.push({
            id: department.id,
            name: department.name })
        }

        this.isPositionRestricted = Boolean(this.document.is_position_restricted)

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false

        // reset the change count on the document
        this.changeCount = 0
      }, () => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      })
    },
    getPositions: function () {
      this.$bus.$emit('loadingEvent', true)
      this.positions = []
      this.disabled = true
      this.$api.get('positions?limit=100').then((response) => {
        for (let position of response.data.data) {
          this.positions.push({
            id: position.id,
            name: position.name + ' (' + position.department.data.name + ')' })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getWriters: function () {
      this.$bus.$emit('loadingEvent', true)
      this.writers = []
      this.disabled = true
      this.$api.get('writers?limit=100').then((response) => {
        for (let writer of response.data.data) {
          this.writers.push({
            id: writer.id,
            name: writer.name })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getTags: function () {
      this.$bus.$emit('loadingEvent', true)
      this.tags = []
      this.disabled = true
      this.$api.get('tags?limit=100').then((response) => {
        for (let tag of response.data.data) {
          this.tags.push({
            id: tag.id,
            name: tag.name })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getCategories: function () {
      this.$bus.$emit('loadingEvent', true)
      this.categories = []
      this.disabled = true
      this.$api.get('categories/flat?limit=100&status=active').then((response) => {
        for (let category of response.data.data) {
          // markup arrow hierarchy structure
          let arrow = ''

          if (category.depth > 1) {
            for (var i = 0; i < category.depth; i++) {
              arrow = arrow + '-'
            }
            arrow = arrow + '>'
          }

          this.categories.push({
            id: category.id,
            name: category.name + ' (' + category.id + ')',
            depth: 0,
            arrow: arrow
          })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getDepartments: function () {
      this.$bus.$emit('loadingEvent', true)
      this.departments = []
      this.disabled = true
      this.$api.get('departments?limit=100').then((response) => {
        for (let department of response.data.data) {
          this.departments.push({
            id: department.id,
            name: department.name })

          // also include subdepartments
          for (let subdepartment of department.descendants) {
            this.departments.push({
              id: subdepartment.id,
              name: subdepartment.name })
          }
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getAttachments: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('attachments?limit=1000&status!=archived').then((response) => {
        for (let attachment of response.data.data) {
          this.attachments.push({
            id: attachment.id,
            name: attachment.name,
            version: attachment.version,
            status: attachment.status })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    updateDocument: function () {
      this.$bus.$emit('savingEvent', true)
      // start updating
      this.disabled = true
      this.$api.put('document/' + this.document.id, this.document).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Controleer de invoervelden')
        } else {
          this.document = response.data.data

          // handle attachment of tags through entity abstraction layer
          var selectedTags = []
          for (let tag of this.attachedTags) {
            selectedTags.push(tag.id)
          }
          this.$entity.reattach(this.document, { 'tags': selectedTags }, 'Thema\'s', '/document/' + this.document.id + '/tags')

          // handle attachment of categories through entity abstraction layer
          var selectedCategories = []
          for (let category of this.attachedCategories) {
            selectedCategories.push(category.id)
          }
          this.$entity.reattach(this.document, { 'categories': selectedCategories }, 'Categorieen', '/document/' + this.document.id + '/categories')

          // handle attachment of positions through entity abstraction layer
          var selectedPositions = []
          for (let position of this.attachedPositions) {
            selectedPositions.push(position.id)
          }
          this.$entity.reattach(this.document, { 'positions': selectedPositions }, 'Functies', '/document/' + this.document.id + '/positions')

          // handle attachment of departments through entity abstraction layer
          var selectedDepartments = []
          for (let department of this.attachedDepartments) {
            selectedDepartments.push(department.id)
          }
          this.$entity.reattach(this.document, { 'departments': selectedDepartments }, 'Afdelingen', '/document/' + this.document.id + '/departments')

          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Artikel ' + this.document.name + ' is succesvol opgeslagen')
          this.errors = {}

          // reset the change count on the document
          this.changeCount = 0
        }
      }, (error) => {
        this.$bus.$emit('savingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    addParagraph: function () {
      // calculate index which is to be added
      let index = this.attachedParagraphs.length

      this.attachedParagraphs.push({
        id: null,
        document_id: this.document.id,
        index: index,
        attachment_id: null,
        image_base64: null,
        content: '',
        isSaved: false,
        positions: [],
        revisions: []
      })
    },
    destroyParagraph: function (index) {
      // remove paragraph from attachedParagraphs
      this.attachedParagraphs.splice(index, 1)
    },
    moveUpParagraph: function (index) {
      // check if its the first (visible) paragraph
      if (index !== 0) {
        let movingParagraph = this.attachedParagraphs[index]
        let replaceParagraph = this.attachedParagraphs[index - 1]

        // use splice to trigger update
        this.attachedParagraphs.splice(index, 1, replaceParagraph)
        this.attachedParagraphs.splice(index - 1, 1, movingParagraph)

        // set new indexes to both paragraphs
        this.attachedParagraphs[index].index = index
        this.attachedParagraphs[index - 1].index = index - 1
      }
    },
    moveDownParagraph: function (index) {
      // check if its the last (visible) paragraph
      if (index + 1 !== this.attachedParagraphs.length) {
        let movingParagraph = this.attachedParagraphs[index]
        let replaceParagraph = this.attachedParagraphs[index + 1]

        // use splice to trigger update
        this.attachedParagraphs.splice(index, 1, replaceParagraph)
        this.attachedParagraphs.splice(index + 1, 1, movingParagraph)

        // set new indexes to both paragraphs
        this.attachedParagraphs[index].index = index
        this.attachedParagraphs[index + 1].index = index + 1
      }
    },
    changeParagraph: function () {
      // set change count on document, used for locking
      this.changeCount++
    },
    lockDocument: function () {
      // lock the document to the current editing writer, removing lock from other writer
      var writerId = localStorage.getItem('writerId')

      // only lock if document is not already locked to current writer
      if (parseInt(this.document.locked_writer_id) !== parseInt(writerId)) {
        // lock the document to the current writer
        this.$entity.lock('Artikel', this.document.id, '/document')
        // set current writer as current one in document object
        this.document.locked_writer_id = writerId
      }
    },
    resetDueDate: function () {
      this.document.due_at = null
    },
    dueDateFormatter: function (date) {
      this.document.due_at = moment(date).format('YYYY-MM-DD HH:mm:ss')
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    toggleFoldGeneral: function () {
      if (this.isFoldedGeneral) {
        this.isFoldedGeneral = false
      } else {
        this.isFoldedGeneral = true
      }
    },
    attachAttachment: function (attachment) {
      // only attach if it does not already exist
      if (attachment && !this.attachedAttachments.find(x => x.id === attachment.id)) {
        this.$api.get('document' + '/' + this.document.id + '/attachment/' + attachment.id + '/attach').then((response) => {
          if (response.status && response.status > 200) {
            this.$bus.$emit('loadingEvent', false)
          } else {
            this.$bus.$emit('loadingEvent', false)
            this.$noty.success('Bijlage is succesvol gekoppeld')

            // add to interface
            this.attachedAttachments.push(attachment)
          }
        }, () => {
          this.$bus.$emit('loadingEvent', false)
        })
      }
    },
    detachAttachment: function (attachment) {
      this.$api.get('document' + '/' + this.document.id + '/attachment/' + attachment.id + '/detach').then((response) => {
        if (response.status && response.status > 200) {
          this.$bus.$emit('loadingEvent', false)
        } else {
          this.$bus.$emit('loadingEvent', false)
          this.$noty.success('Bijlage is succesvol ontkoppeld')

          // remove from interface
          let index = this.attachedAttachments.findIndex(x => x.id === attachment.id)
          this.attachedAttachments.splice(index, 1)
        }
      }, () => {
        this.$bus.$emit('loadingEvent', false)
      })
    },
    attachDocument: function (document) {
      // only attach if it does not already exist
      if (document && !this.attachedDocuments.find(x => x.id === document.id)) {
        this.$api.get('document' + '/' + this.document.id + '/document/' + document.id + '/attach').then((response) => {
          if (response.status && response.status > 200) {
            this.$bus.$emit('loadingEvent', false)
          } else {
            this.$bus.$emit('loadingEvent', false)
            this.$noty.success('Artikel is succesvol gekoppeld')

            // add to interface
            this.attachedDocuments.push(document)
          }
        }, () => {
          this.$bus.$emit('loadingEvent', false)
        })
      }
    },
    detachDocument: function (document) {
      this.$api.get('document' + '/' + this.document.id + '/document/' + document.id + '/detach').then((response) => {
        if (response.status && response.status > 200) {
          this.$bus.$emit('loadingEvent', false)
        } else {
          this.$bus.$emit('loadingEvent', false)
          this.$noty.success('Artikel is succesvol ontkoppeld')

          // remove from interface
          let index = this.attachedDocuments.findIndex(x => x.id === document.id)
          this.attachedDocuments.splice(index, 1)
        }
      }, () => {
        this.$bus.$emit('loadingEvent', false)
      })
    },
    archiveDocument: function (document) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Artikel', document.id, '/document')
    },
    versionDocument: function (document) {
      // handle versioning through entity abstraction layer
      this.$entity.version('Artikel', document.id, '/document')
    },
    markLatestVersionDocument: function (document) {
      // handle latest version marking through entity abstraction layer
      this.$entity.markLatestVersion('Artikel', document.id, '/document')
    },
    goToNewVersion: function (document) {
      this.$router.push({ name: 'documentEdit', params: { id: document.id } })
    },
    showDocumentRevisions: function () {
      this.$bus.$emit('askEntityRevisionModalEvent', {'revisions': this.document.revisions, 'entityName': 'Document'})
    },
    askSave: function () {
      // update the document
      this.updateDocument()
    },
    updateAttachedPositions: function (selectedPositions) {
      this.attachedPositions = selectedPositions
    },
    updateAttachedDepartments: function (selectedDepartments) {
      this.attachedDepartments = selectedDepartments
    },
    downloadQr: function (size) {
      // handle archiving through entity abstraction layer
      var token = Cookies.get('access_token')
      window.location.href = process.env.VUE_APP_API_URL + '/document/' + this.document.id + '/qr/' + size + '?access_token=' + token
    }
  },
  mounted: function () {
    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', () => this.askSave())
    // listen for unlock event emitted from unlock message
    this.$bus.$on('askEntityLockEvent', () => this.lockDocument())
    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (document) => this.archiveDocument(document))
    // listen for raise version event
    this.$bus.$on('askEntityRaiseVersionEvent', (document) => this.versionDocument(document))
    // listen for mark latest version event
    this.$bus.$on('askEntityMarkLatestVersionEvent', (document) => this.markLatestVersionDocument(document))
    // listen for new created version event
    this.$bus.$on('entityVersionedEvent', (document) => this.goToNewVersion(document))
    // listen for marked latest version event
    this.$bus.$on('entityMarkedLatestVersionEvent', () => this.getDocument(this.$route.params.id))
    // listen for archived event
    this.$bus.$on('entityArchivedEvent', () => this.getDocument(this.$route.params.id))

    this.getWriters()
    this.getTags()
    this.getCategories()
    this.getDepartments()
    this.getAttachments()
    this.getPositions()
    this.getDocument(this.$route.params.id)
  },
  beforeDestroy: function () {
    // stop listening
    this.$bus.$off('askSaveEvent', () => this.askSave())
    this.$bus.$off('askEntityLockEvent')
    this.$bus.$off('askEntityArchiveEvent')
    this.$bus.$off('askEntityRaiseVersionEvent')
    this.$bus.$off('askEntityMarkLatestVersionEvent')
    this.$bus.$off('entityVersionedEvent')
    this.$bus.$off('entityMarkedLatestVersionEvent')
    this.$bus.$off('entityArchivedEvent')
  },
  components: {
    'savebar': savebar,
    'paragraph': paragraph,
    'document-relations': documentRelations,
    'document-versions': documentVersions,
    'document-statistics': documentStatistics,
    'document-notifications': documentNotifications,
    'document-tools': documentTools,
    'v-select': vSelect,
    'unlock-message': unlockMessage,
    'star-rating': StarRating,
    'datepicker': Datepicker,
    'archive-modal': archiveModal,
    'version-modal': versionModal,
    'revision-modal': revisionModal,
    'latest-version-modal': latestVersionModal,
    'attachment-create-modal': attachmentCreateModal,
    'no-entity-autocomplete': noEntityAutocomplete,
    'personalisation-scope-select': personalisationScopeSelect,
    'toggle-button': ToggleButton
  },
  watch: {
    '$route' (to) {
      this.getDocument(to.params.id)
    },
    'document': {
      // watch for changes on the document, used for locking
      handler: function () {
        this.changeCount++
      },
      deep: true
    },
    'changeCount': {
      // watch the change count to lock the document if needed
      handler: function (changes) {
        // make sure there are 4 or more changes (first one is counted on load)
        if (changes >= 4) {
          this.lockDocument()
        }
      }
    },
    isPositionRestricted: function (status) {
      this.document.is_position_restricted = status ? 1 : 0
    },
    isMarkedAsChanged: function (status) {
      this.document.is_marked_as_changed = status ? 1 : 0
    }
  }
}
</script>
