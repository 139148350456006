<template lang="html">
  <div class="modal" v-bind:class="{ 'is-active': visible }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <p>Weet je zeker dat je <strong>{{ entityName }}</strong> wilt archiveren?</p>

        <article class="message is-warning m-t-md" v-if="entityType === 'category'">
          <div class="message-body">
            Alle eventueel onderliggende categorieën worden tevens gearchiveerd. Gekoppelde artikelen, bijlagen en functies worden
            van de categorie ontkoppeld.
          </div>
        </article>

        <article class="message is-warning m-t-md" v-if="entityType === 'attachment'">
          <div class="message-body">
            De bijlage wordt tevens ontkoppeld van alle gekoppelde categorieën, artikelen, thema's en functies.
          </div>
        </article>

        <article class="message is-warning m-t-md" v-if="entityType === 'position'">
          <div class="message-body">
            De functie wordt tevens ontkoppeld van alle lezers, artikelen, categorieën en bijlages.
          </div>
        </article>

        <article class="message is-warning m-t-md" v-if="entityType === 'tag'">
          <div class="message-body">
            Het thema wordt tevens ontkoppeld van alle bijlages en artikelen.
          </div>
        </article>

        <article class="message is-warning m-t-md" v-if="entityType === 'document'">
          <div class="message-body">
            Het artikel wordt tevens ontkoppeld van alle gekoppelde categorieën en thema's. Als lezers dit artikel als favoriet hebben ingesteld wordt dit tevens ontkoppeld.
          </div>
        </article>

        <article class="message is-warning m-t-md" v-if="entityType === 'department'">
          <div class="message-body">
            Alle eventueel onderliggende afdelingen worden tevens gearchiveerd.
          </div>
        </article>

        <hr />
        <a class="button is-warning" v-on:click="askArchive(entity)" v-bind:class="{ 'is-disabled': archiving }">
          <span class="icon">
            <i class="mdi mdi-archive"></i>
          </span>
          <span v-if="!archiving">Archiveer</span>
          <span v-else>Bezig met archiveren</span>
        </a>
        <a class="button" v-on:click="close()">
          <span class="icon">
            <i class="mdi mdi-cancel"></i>
          </span>
          <span>Annuleer</span>
        </a>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
  </div>
</template>

<script>

export default {
  name: 'archiveModal',
  props: {
    entity: {
      type: Object,
      default: () => ({})
    },
    entityName: String,
    entityType: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      archiving: false,
      visible: false
    }
  },
  methods: {
    askArchive: function (entity) {
      this.$bus.$emit('askEntityArchiveEvent', entity)
      this.archiving = true
    },
    close: function () {
      this.visible = false
    },
    spawnModal: function () {
      this.visible = true
    },
    setArchiving: function (state) {
      this.archiving = state
      if (state === false) {
        this.visible = false
      }
    }
  },
  mounted: function () {
    // listen for ask for modal event
    this.$bus.$on('askEntityArchiveModalEvent', this.spawnModal)
    this.$bus.$on('archivingEvent', (state) => this.setArchiving(state))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askEntityArchiveModalEvent')
  }
}
</script>