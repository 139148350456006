<template lang="html">
  <nav class="box has-background-light is-shadowless">
    <div class="is-flex is-justify-content-space-between">
      <div class="field has-addons m-0">
        <div class="control" v-bind:class="{ 'is-loading': loading }" v-show="showSearch">
          <input class="input" type="text" placeholder="Zoekwoorden" v-model="keywords">
        </div>
        <div class="control">
          <div class="select">
            <select name="status" v-model="activeStatus" v-on:change="askStatusChange(activeStatus)">
              <option v-for="(status, key) in statusses" v-on:click="askStatusChange(key)" :value="key" :key="key">{{ status }}</option>
            </select>
          </div>
        </div>
        <div class="control" v-if="activeOwnership">
          <div class="select">
            <select name="ownership" v-model="activeOwnership" v-on:change="askOwnershipChange(activeOwnership)">
              <option value="me">Mijn {{ entityName }}</option>
              <option value="all">Alle {{ entityName }}</option>
            </select>
          </div>
        </div>
        <div class="control ml-3 pt-1" v-if="foldEntity">
          <span class="m-r-sm has-text-weight-bold is-size-7">Toon {{ foldEntityName }}</span>
          <toggle-button
            :sync="true"
            v-model="isUnfolded"
            :width="58"
            :height="25"
            :labels="{checked: 'Ja', unchecked: 'Nee'}"
            :color="{checked: '#00c4a7', unchecked: '#cccccc'}"
            class="m-0"
          />
        </div>
      </div>
      <div class="is-flex is-justify-content-end">
        <div class="control ml-2">
          <p class="control">
            <router-link class="button is-primary" :to="createPath" v-if="createPath">
              <span class="icon">
                <i class="mdi mdi-plus-circle-outline"></i>
              </span>
              <span>{{ createText }}</span>
            </router-link>
            <a class="button is-primary" v-on:click="createMethod" v-if="createMethod">
              <span class="icon">
                <i class="mdi mdi-plus-circle-outline"></i>
              </span>
              <span>{{ createText }}</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

import _ from 'lodash'
import ToggleButton from 'vue-js-toggle-button/src/Button'

export default {
  name: 'indexBar',
  data: function () {
    return {
      saving: false,
      keywords: '',
      loading: false,
      isUnfolded: false,
      categories: []
    }
  },
  props: [
    'createPath',
    'createMethod',
    'createText',
    'entityName',
    'activeStatus',
    'activeOwnership',
    'statusses',
    'showSearch',
    'foldEntity',
    'foldEntityName',
    'filters'
  ],
  methods: {
    checkStatus: function (status) {
      if (this.activeStatus === status) {
        return true
      } else {
        return false
      }
    },
    checkOwnership: function (ownership) {
      if (this.activeOwnership === ownership) {
        return true
      } else {
        return false
      }
    },
    askSearch: function () {
      this.$bus.$emit('askSearchEvent', this.keywords)
    },
    askStatusChange: function (status) {
      this.$bus.$emit('askStatusChangeEvent', status)
    },
    askOwnershipChange: function (status) {
      this.$bus.$emit('askOwnershipChangeEvent', status)
    },
    onLoadingEvent: function (state) {
      this.loading = state
    },
    getCategories: function () {
      this.$bus.$emit('loadingEvent', true)
      this.categories = []
      this.disabled = true
      this.$api.get('categories?limit=100').then((response) => {
        for (let category of response.data.data) {
          this.categories.push({
            id: category.id,
            name: category.name + ' (' + category.id + ')',
            depth: 0,
            arrow: ''
          })

          // also include subcategories
          for (let subcategory of category.descendants) {
            let arrow = ''
            for (var i = 0; i < subcategory.depth; i++) {
              arrow = arrow + '--'
            }
            arrow = arrow + '>'

            this.categories.push({
              id: subcategory.id,
              name: subcategory.name + ' (' + subcategory.id + ')',
              depth: subcategory.depth,
              arrow: arrow
            })
          }
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    }
  },
  mounted: function () {
    this.getCategories()

    this.$bus.$on('loadingEvent', this.onLoadingEvent)
  },
  watch: {
    keywords: _.debounce(function () {
      this.askSearch()
    }, 1000),
    isUnfolded: function (status) {
      this.$bus.$emit('askFoldEvent', status)
    }
  },
  components: {
    'toggle-button': ToggleButton
  }
}
</script>
