<template lang="html">
  <div class="tags has-addons">
    <span class="tag" v-for="entity in entities.data.slice(0, 1)" :key="entity.id">{{ entity[nameKey] }}</span>
    <span class="tag is-info" v-if="entities.data.length - 1 > 0" v-tooltip="otherRelations">
      <i class="mdi mdi-plus-circle mr-1"></i> {{ entities.data.length - 1 }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'entityRelationsBadge',
  props: {
    entities: {
      type: Object,
      default: () => ({})
    },
    nameKey: {
      type: String,
      default: 'name'
    }
  },
  methods: {},
  computed: {
    otherRelations: function () {
      if (this.entities.data.length - 1 > 0) {
        return this.entities.data.slice(1).map(entity => entity[this.nameKey]).join(', ')
      }
      return ''
    }
  },
  components: {}
}
</script>
