<template lang="html">
  <div id="writer">
    <div class="columns">
      <div class="column is-7">
        <div class="box">
          <div class="field">
            <label class="label title is-4 has-text-weight-light">{{ writer.name }}</label>
            <div class="control">
              <input class="input" type="text" ref="name" v-model="writer.name" placeholder="Naam van de schrijver"
                v-bind:class="{ 'is-danger': errors.name }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="createWriter()">
            </div>
            <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">E-mailadres</label>
            <div class="control">
              <input class="input" type="email" v-model="writer.email" placeholder="E-mailadres van de schrijver"
                v-bind:class="{ 'is-danger': errors.email }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="createWriter()">
            </div>
            <p v-for="(error, index) in errors.email" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Wachtwoord</label>
            <div class="control">
              <input class="input" type="password" v-model="writer.password" placeholder="Wachtwoord van de schrijver"
                v-bind:class="{ 'is-danger': errors.email }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="createWriter()">
            </div>
            <p v-for="(error, index) in errors.password" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Mobiel telefoonnummer</label>
            <div class="control">
              <input class="input" type="text" v-model="writer.cellphone" placeholder="Mobiel telefoonnummer van de schrijver"
                v-bind:class="{ 'is-danger': errors.cellphone }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="createWriter()">
            </div>
            <p class="has-text-grey">Gebruik een 13-15 cijferig telefoonnummer, met landcode. Bijvoorbeeld: 0031612345678.</p>
            <p v-for="(error, index) in errors.cellphone" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <p>
              <span class="icon has-text-primary is-pulled-right" v-if="isAdmin">
                <i class="mdi mdi-shield-account"></i>
              </span>
              <span class="icon has-text-grey-light is-pulled-right" v-if="!isAdmin">
                <i class="mdi mdi-account-edit"></i>
              </span>
              <strong>Deze schrijver is een beheerder</strong> <i class="mdi mdi-help-circle has-text-info" v-tooltip="'Beheerders kunnen toegangsrechten van andere schrijvers instellen en hebben toegang tot alle instellingen van Handboek'"></i>
            </p>
            <toggle-button
              :sync="true"
              v-model="isAdmin"
              :width="58"
              :height="25"
              :labels="{checked: 'Ja', unchecked: 'Nee'}"
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
          </div>
        </div>
        <div class="box">
          <div class="field">
            <p>
              <span class="icon has-text-primary is-pulled-right" v-if="isNotifiedDue">
                <i class="mdi mdi-bell-ring"></i>
              </span>
              <span class="icon has-text-grey-light is-pulled-right" v-if="!isNotifiedDue">
                <i class="mdi mdi-bell-off"></i>
              </span>
              <strong>Notificeer</strong> per e-mail als een artikel of bijlage verloopt
            </p>
            <toggle-button
              :sync="true"
              v-model="isNotifiedDue"
              :width="58"
              :height="25"
              :labels="{checked: 'Ja', unchecked: 'Nee'}"
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
          </div>
          <hr />
          <div class="field">
            <p>
              <span class="icon has-text-primary is-pulled-right" v-if="isNotifiedComment">
                <i class="mdi mdi-bell-ring"></i>
              </span>
              <span class="icon has-text-grey-light is-pulled-right" v-if="!isNotifiedComment">
                <i class="mdi mdi-bell-off"></i>
              </span>
              <strong>Notificeer</strong> per e-mail als er commentaar is gegeven op een artikel
            </p>
            <toggle-button
              :sync="true"
              v-model="isNotifiedComment"
              :width="58"
              :height="25"
              :labels="{checked: 'Ja', unchecked: 'Nee'}"
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
          </div>
          <div class="field">
            <p>
              <span class="icon has-text-primary is-pulled-right" v-if="isNotifiedSystem">
                <i class="mdi mdi-bell-ring"></i>
              </span>
              <span class="icon has-text-grey-light is-pulled-right" v-if="!isNotifiedSystem">
                <i class="mdi mdi-bell-off"></i>
              </span>
              <strong>Notificeer</strong> per e-mail over de resultaten van systeemtaken
            </p>
            <toggle-button
              :sync="true"
              v-model="isNotifiedSystem"
              :disabled="!isAdmin"
              :width="58"
              :height="25"
              :labels="{checked: 'Ja', unchecked: 'Nee'}"
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
          </div>
          <hr />
          <div class="field">
            <p>
              <span class="icon has-text-primary is-pulled-right" v-if="isTwofactorAuthenticated">
                <i class="mdi mdi-key-change"></i>
              </span>
              <span class="icon has-text-grey-light is-pulled-right" v-if="!isTwofactorAuthenticated">
                <i class="mdi mdi-key-change"></i>
              </span>
              <strong>Gebruik 'two factor' authenticatie </strong> om je account beter te beveiligen
            </p>
            <toggle-button
              :sync="true"
              v-model="isTwofactorAuthenticated"
              :width="58"
              :height="25"
              :labels="{checked: 'Ja', unchecked: 'Nee'}"
              :color="{checked: '#00c4a7', unchecked: '#cccccc'}" />
          </div>
        </div>
      </div>
      <div class="column is-5"></div>
      <savebar
        :entity="writer"
        entityName="Schrijver">
      </savebar>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import savebar from '../savebar'
import ToggleButton from 'vue-js-toggle-button/src/Button'

export default {
  name: 'writerCreate',
  data: function () {
    return {
      writer: {
        is_notified_due: 1,
        is_notified_comment: 1,
        is_notified_system: 1,
        is_twofactor_authenticated: 0,
        is_admin: 0
      },
      errors: {},
      disabled: false,
      isNotifiedDue: true,
      isNotifiedComment: true,
      isNotifiedSystem: true,
      isTwofactorAuthenticated: false,
      isAdmin: false
    }
  },
  methods: {
    createWriter: function () {
      this.$bus.$emit('savingEvent', true)
      this.disabled = true
      this.$api.post('writers', this.writer).then((response) => {
        if (response.status && response.status > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Controleer de invoervelden')
        } else {
          this.attachment = response.data.data
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Schrijver ' + this.writer.name + ' is succesvol toegevoegd')
          this.errors = {}
          this.$router.push('/writers')
        }
      }, () => {
        this.$bus.$emit('savingEvent', false)
        this.disabled = false
      })
    }
  },
  mounted: function () {
    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', this.createWriter)

    this.$refs.name.focus()
  },
  watch: {
    isNotifiedDue: function (status) {
      this.writer.is_notified_due = status ? 1 : 0
    },
    isNotifiedComment: function (status) {
      this.writer.is_notified_comment = status ? 1 : 0
    },
    isNotifiedSystem: function (status) {
      this.writer.is_notified_system = status ? 1 : 0
    },
    isTwofactorAuthenticated: function (status) {
      this.writer.is_twofactor_authenticated = status ? 1 : 0
    },
    isAdmin: function (status) {
      this.writer.is_admin = status ? 1 : 0

      // auto disable system notifications if writer is no admin
      if (status == 0) {
        this.isNotifiedSystem = false;
        this.writer.is_notified_system = 0;
      }
    }
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askSaveEvent')
  },
  components: {
    'savebar': savebar,
    'toggle-button': ToggleButton
  }
}
</script>
