<template lang="html">
  <div class="box is-paddingless" v-if="!disabled">
    <h1>Koppelingen</h1>
    <div class="tabs">
      <ul>
        <li v-on:click="toggleTab('subdepartments')" v-bind:class="{ 'is-active': activeTab == 'subdepartments' }">
          <a>
            <span>Onderafdelingen <span class="tag is-info" v-show="attachedSubdepartments.length > 0"> {{ attachedSubdepartments.length }}</span></span>
          </a>
        </li>
        <li v-on:click="toggleTab('positions')" v-bind:class="{ 'is-active': activeTab == 'positions' }">
          <a>
            <span>Functies <span class="tag is-info" v-show="attachedPositions.length > 0"> {{ attachedPositions.length }}</span></span>
          </a>
        </li>
        <li v-on:click="toggleTab('readers')" v-bind:class="{ 'is-active': activeTab == 'readers' }">
          <a>
            <span>Lezers <span class="tag is-info" v-show="attachedReaders.length > 0"> {{ attachedReaders.length }}</span></span>
          </a>
        </li>
      </ul>
    </div>

    <div class="tab is-paddingless" v-show="activeTab == 'subdepartments'">
      <ul class="menu-list">
        <li v-for="(subdepartment, index) in attachedSubdepartments" :key="index">
          <router-link class="" :to="'/department/'+ subdepartment.id">
            <i class="mdi mdi-subdirectory-arrow-right"></i> {{ subdepartment.name}}
          </router-link>
        </li>
      </ul>
      <article class="message is-info" v-show="attachedSubdepartments.length === 0">
        <div class="message-body">
          {{ department.name }} heeft geen onderafdelingen
        </div>
      </article>
    </div>

    <div class="tab is-paddingless" v-show="activeTab == 'positions'">
      <ul class="menu-list">
        <li v-for="(position, index) in attachedPositions" :key="index">
          <router-link class="" :to="'/position/'+ position.id">
            {{ position.name}}
          </router-link>
        </li>
      </ul>
      <article class="message is-info" v-show="attachedPositions.length === 0">
        <div class="message-body">
          {{ department.name }} heeft geen gekoppelde functies
        </div>
      </article>
    </div>

    <div class="tab is-paddingless" v-show="activeTab == 'readers'">
      <ul class="menu-list">
        <li v-for="(reader, index) in attachedReaders" :key="index">
          <router-link class="" :to="'/reader/'+ reader.id">
            {{ reader.name}}
          </router-link>
        </li>
      </ul>
      <article class="message is-info" v-show="attachedReaders.length === 0">
        <div class="message-body">
          {{ department.name }} heeft geen gekoppelde lezers
        </div>
      </article>
    </div>

  </div>
</template>

<script>

export default {
  name: 'departmentRelations',
  props: [
    'department',
    'disabled',
    'attachedSubdepartments',
    'attachedPositions',
    'attachedReaders'
  ],
  data: function () {
    return {
      activeTab: 'subdepartments'
    }
  },
  methods: {
    toggleTab: function (tabName) {
      this.activeTab = tabName
    }
  }
}
</script>