<template lang="html">
  <div class="modal" v-bind:class="{ 'is-active': visible }">
    <div class="modal-background"></div>
    <div class="modal-content is-large">
      <div class="box">
        <h5 class="title is-5">{{ entityName }} wijzigingshistorie</h5>
        <div class="card m-b-md" v-for="revision in revisions" :key="revision.id">
          <header class="card-header" @click="toggleRevision(revision)">
            <p class="card-header-title">
              {{ revision.key }} gewijzigd <span class="has-text-weight-light">&nbsp; - {{ revision.created_at | moment("from", "now") }}</span>
            </p>
            <a href="#" class="card-header-icon">
              <span class="icon" v-if="visibleRevisionId !== revision.id">
                <i class="mdi mdi mdi-chevron-down"></i>
              </span>
              <span class="icon" v-if="visibleRevisionId === revision.id">
                <i class="mdi mdi-chevron-up"></i>
              </span>
            </a>
          </header>
          <div class="card-content" v-if="visibleRevisionId === revision.id">
            <article class="media" v-if="!loading">
              <div class="media-left">
                <avatar
                  :username="visibleRevisionUser.name"
                  class="avatar"
                  :size="28"
                  backgroundColor="#1B9DC1"
                  color="#FFFFFF">
                </avatar>
              </div>
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>{{ visibleRevisionUser.name }}</strong><br />
                  </p>
                </div>
              </div>
            </article>

            <code-diff 
              :old-string="revision.old_value"
              :new-string="revision.new_value"
              :context="10"
              outputFormat="side-by-side">
            </code-diff>
          
          </div>
        </div>

        <hr />

        <a class="button" v-on:click="close()">
          <span class="icon">
            <i class="mdi mdi-close"></i>
          </span>
          <span>Sluiten</span>
        </a>
      </div>
    </div>

    <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
  </div>
</template>

<script>

import CodeDiff from 'vue-code-diff'
import Avatar from 'vue-avatar'

export default {
  name: 'revisionModal',
  data: function () {
    return {
      visible: false,
      loading: false,
      entityName: '',
      revisions: [],
      visibleRevisionId: 0,
      visibleRevisionUser: {}
    }
  },
  methods: {
    close: function () {
      this.visible = false
    },
    toggleRevision: function (revision) {
      if (this.visibleRevisionId === revision.id) {
        this.visibleRevisionId = 0
      } else {
        this.visibleRevisionId = revision.id

        // get revision writer data
        this.loading = true
        this.$api.get('writer/' + revision.user_id).then((response) => {
          this.visibleRevisionUser = response.data.data

          this.loading = false
        }, (error) => {
          console.log(error)
          this.loading = false
        })
      }
    },
    spawnModal: function (entityName, revisions) {
      this.visible = true
      this.revisions = revisions
      this.entityName = entityName
    }
  },
  mounted: function () {
    // listen for ask for modal event
    this.$bus.$on('askEntityRevisionModalEvent', (data) => this.spawnModal(data.entityName, data.revisions))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askEntityRevisionModalEvent')
  },
  components: {
    'code-diff': CodeDiff,
    'avatar': Avatar
  }
}
</script>
