// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueSimplemde from 'vue-simplemde'
import VueMoment from 'vue-moment'
import vSelect from 'vue-select'
import navbar from './components/navbar'
import extendLoginModal from './components/common/extend-login-modal'
import app from './app'
import router from './router'
import VTooltip from 'v-tooltip'
import VueCookie from 'vue-cookie'
import Api from './api'
import Entities from './entities'
import './filters/pretty-bytes'
import VueNoty from 'vuejs-noty'

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

// define a global event bus
Vue.prototype.$bus = new Vue()

Vue.use(VueCookie)
Vue.use(VueNoty, {
  timeout: 500,
  force: true,
  progressBar: true,
  layout: 'topRight'
})
Vue.use(VueSimplemde)
Vue.use(VueMoment)
Vue.use(vSelect)
Vue.use(VTooltip)
Vue.use(mavonEditor)

Vue.use(Api, {
  url: process.env.VUE_APP_API_URL,
  clientId: 1,
  // clientSecret: 'KsMsrEm3GBAW2asb1XzhZRRg8YMTSsLlMHOtzNnm',
  clientSecret: 'bVijjeSC7TTKen4QbE67yAexdfPjEsuTvXSxT3vJ',
  provider: 'writers',
  accessTokenExpireDays: process.env.VUE_APP_API_TOKEN_EXPIRE_DAYS,
  refreshTokenExpireDays: process.env.VUE_APP_API_REFRESH_TOKEN_EXPIRE_DAYS,
  cookieSecure: process.env.VUE_APP_COOKIE_SECURE
})

Vue.use(Entities)

new Vue({
  render: app.render,
  router,
  components: {
    'navbar': navbar,
    'extend-login-modal': extendLoginModal
  },
  data: function () {
    return {
      writer: {},
      globalIsLoading: false,
      isLoadingCount: 0,
    }
  },
  methods: {
    showApiErrorNotification: function () {
      this.$noty.error('Het ophalen en/of opslaan van informatie is mislukt. Probeer je actie opnieuw uit te voeren.')
    },
    showApiInsufficientPermissionsNotification: function () {
      this.$noty.warning('Je hebt niet genoeg rechten om deze actie uit te mogen voeren.')
    },
    logoutWriterRemote: function () {
      this.$api.logout()
    },
    logoutWriter: function () {
      // clear localstorage
      localStorage.clear()
      // forward to login
      router.push('/login')
    },
    loginWriter: function (credentials) {
      this.$api.login(credentials.username, credentials.password)
    },
    extendLoginWriter: function () {
      this.$api.extend()
    },
    twofactorLoginWriter: function () {
      // get email from localstorage
      let email = localStorage.getItem('email')
      // forward user to two factor auth
      router.push({ name: 'twofactor', params: { email: email } })
    },
    getWriterInfo: function () {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('me').then((response) => {
        // create writer object
        this.writer.accountId = response.data.data.account_id
        this.writer.writerId = response.data.data.id
        this.writer.name = response.data.data.name
        this.writer.isAdmin = response.data.data.is_admin
        // write to localstorage
        localStorage.setItem('accountId', this.writer.accountId)
        localStorage.setItem('writerId', this.writer.writerId)
        localStorage.setItem('name', this.writer.name)
        localStorage.setItem('isAdmin', this.writer.isAdmin)
        // forward to dashboard
        router.push('/')
        // emit event
        this.$bus.$emit('loadingEvent', false)
        this.$bus.$emit('writerDataLoadedEvent', this.writer)
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    unlockEntity: function (entityType, entityId, endpoint) {
      this.$entity.unlock(entityType, entityId, endpoint)
    },
    onLoadingEvent: function (state) {
      if (state === true) {
        this.isLoadingCount += 1
      } else if (state === false && this.isLoadingCount > 0) {
        this.isLoadingCount -= 1
      }

      if (this.isLoadingCount > 0) {
        this.$bus.$emit('globalLoadingEvent', true)
        this.globalIsLoading = true
      } else {
        this.$bus.$emit('globalLoadingEvent', false)
        this.globalIsLoading = false
      }
    },
  },
  created: function () {
    // set event bus to api plugin
    this.$api.setEventBus(this.$bus)
    // try to authenticate with existing session
    if (this.$api.authenticate()) {
      // after succesfull login, get writer info
      this.getWriterInfo()
    }
  },
  mounted: function () {
    // instantiate entities plugin
    this.$entity.setApi(this.$api)
    this.$entity.setEventBus(this.$bus)
    this.$entity.setNotificationBus(this.$noty)
    // set correct momentjs locale
    this.$moment.locale('nl')

    // listen for a generic api connection error
    this.$bus.$on('apiErrorEvent', this.showApiErrorNotification)
    // listen for a generic api invalid permissions error
    this.$bus.$on('apiInsufficientPermissionsEvent', this.showApiInsufficientPermissionsNotification)
    // listen for logout event
    this.$bus.$on('askWriterLogoutEvent', this.logoutWriterRemote)
    this.$bus.$on('askWriterLogoutEvent', this.logoutWriter)
    // listen for session extend event
    this.$bus.$on('askWriterExtendLoginEvent', this.extendLoginWriter)
    // listen for login event
    this.$bus.$on('askWriterLoginEvent', this.loginWriter)
    // listen for password forgot event
    this.$bus.$on('askWriterPasswordForgotEvent', this.passwordForgotWriter)
    // listen for api logged out event
    this.$bus.$on('apiLoggedOutEvent', this.logoutWriter)
    // listen for api logged out event
    this.$bus.$on('apiLoggedInEvent', this.getWriterInfo)
    // listen for api 2fa required event
    this.$bus.$on('apiTwoFactorRequiredEvent', this.twofactorLoginWriter)
    // listen for api invalid client event
    this.$bus.$on('apiInvalidClientEvent', this.showApiErrorNotification)
    // listen for loading events
    this.$bus.$on('loadingEvent', this.onLoadingEvent)
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('apiErrorEvent')
    this.$bus.$off('apiInsufficientPermissionsEvent')
    this.$bus.$off('askWriterLogoutEvent')
    this.$bus.$off('askWriterLoginEvent')
    this.$bus.$off('askWriterPasswordForgotEvent')
    this.$bus.$off('apiLoggedOutEvent')
    this.$bus.$off('apiLoggedInEvent', this.getWriterInfo)
    this.$bus.$off('apiTwoFactorRequiredEvent')
    this.$bus.$off('apiInvalidClientEvent')
    this.$bus.$off('loadingEvent')

  },
  watch: {
    '$route' (to, from) {
      // handle automatic unlocking of entities on route change
      switch (from.name) {
        case 'documentEdit':
          this.unlockEntity('Artikel', from.params.id, 'document')
          break
      }
    }
  }
}).$mount('#app')
