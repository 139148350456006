<template lang="html">
  <nav class="navbar is-fixed-bottom has-shadow" role="navigation" aria-label="secondary navigation">
    <div class="navbar-menu">
      <div class="navbar-start">
        <div class="navbar-item" v-if="!hideArchive">
          <div class="field has-addons">
            <p class="control">
              <a class="button is-danger" v-if="entity.status !== 'archived' && entity.status" v-on:click="archiveModal()">
                <span class="icon">
                  <i class="mdi mdi-archive"></i>
                </span>
                <span>Archiveren</span>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <p class="control">
            <a class="button is-primary" v-bind:class="{ 'is-disabled': saving }" v-on:click="askSave()">
              <span class="icon">
                <i class="mdi mdi-content-save"></i>
              </span>
              <span v-show="!saving">Opslaan</span>
              <span v-show="saving === true">Bezig met opslaan</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss">
  @import '../assets/scss/main.scss';
  @import '../assets/scss/savebar.scss';
</style>

<script>

export default {
  name: 'savebar',
  props: {
    entity: Object,
    entityName: String,
    hideArchive: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      saving: false
    }
  },
  methods: {
    askSave: function () {
      this.$bus.$emit('askSaveEvent')
    },
    archiveModal: function () {
      this.$bus.$emit('askEntityArchiveModalEvent', true)
    },
    isSaved: function () {
      this.saving = false
    },
    isSaving: function (status) {
      this.saving = status
    },
    test: function () {
      return this.entityName
    }
  },
  mounted: function () {
    // listen for a succesfull saved event emitted from a component
    this.$bus.$on('savedEvent', () => this.isSaved())
    // listen for a is saving event form a component
    this.$bus.$on('savingEvent', (status) => this.isSaving(status))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('savedEvent')
    this.$bus.$off('savingEvent')
  },
  components: {}
}
</script>
