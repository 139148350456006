<template lang="html">
  <div class="box">

    <index-bar
      createPath="/attachments/create"
      createText="Bijlage toevoegen"
      entityName="bijlages"
      :activeStatus="statusFilter"
      :activeOwnership="mutableOwnershipFilter"
      :statusses="statusses" />

    <archive-modal
      :entity="this.tobeArchivedAttachment"
      :entityName="this.tobeArchivedAttachment.name"
      entityType='attachment' />

    <delete-modal
      :entity="this.tobeDeletedAttachment"
      :entityName="this.tobeDeletedAttachment.name"
      entityType='attachment' />

    <table class="table is-fullwidth" v-if="attachments.length >= 1">
      <thead>
        <tr>
          <th><abbr title="Naam">Naam</abbr></th>
          <th><abbr title="Versie">Versie</abbr></th>
          <th><abbr title="Categorieën">Categorieën</abbr></th>
          <th><abbr title="Thema's">Thema's</abbr></th>
          <th><abbr title="Personalisatie">Personalisatie</abbr></th>
          <th><abbr title="Schrijver">Schrijver</abbr></th>
          <th><abbr title="Grootte">Grootte</abbr></th>
          <th><abbr title="Verloopdatum">Verloopdatum</abbr></th>
          <th><abbr title="Aangepast">Aangepast</abbr></th>
          <th><abbr title="Afgeschermd">Afgeschermd</abbr></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="attachment in attachments" :key="attachment.id" v-on:click="editAttachment(attachment)">
          <td>{{ attachment.name }}</td>
          <td><span class="tag">Versie {{ attachment.version }}</span></td>
          <td><entity-relations-badge :entities="attachment.categories" /></td>
          <td><entity-relations-badge :entities="attachment.tags" /></td>
          <td>
            <span class="icon" v-if="attachment.positions.data.length === 0 && attachment.departments.data.length === 0" v-tooltip="'Deze bijlage is gericht op alle lezers'">
              <i class="mdi mdi-infinity"></i>
            </span>
            <entity-relations-badge :entities="attachment.positions" v-if="attachment.positions.data.length > 0" />
            <entity-relations-badge :entities="attachment.departments" v-if="attachment.departments.data.length > 0" />
          </td>
          <td>{{ attachment.writer.data.name }}</td>
          <td>{{ attachment.size | prettyBytes }}</td>
          <td><span v-if="attachment.due_at">{{ attachment.due_at | moment("DD-MM-YYYY") }}</span></td>
          <td>{{ attachment.updated_at | moment("DD-MM-YYYY") }}</td>
          <td>
            <i class="mdi mdi-lock has-text-danger" v-if="attachment.is_position_restricted" v-tooltip="'Deze bijlage is alleen te openen voor lezers met een van de gekozen functies.'"></i>
            <i class="mdi mdi-lock-open has-text-primary" v-if="!attachment.is_position_restricted" v-tooltip="'Deze bijlage is voor alle lezers te openen.'"></i>
          </td>
          <td>
            <div class="is-pulled-right">
              <div class="field has-addons">
                <p class="control">
                  <router-link :to="'/attachment/'+ attachment.id" class="button">
                    <span class="icon">
                      <i class="mdi mdi-pencil"></i>
                    </span>
                  </router-link>
                </p>
                <p class="control">
                  <a class="button" v-on:click.stop="downloadAttachment(attachment)">
                    <span class="icon">
                      <i class="mdi mdi-download"></i>
                    </span>
                  </a>
                </p>
                <p class="control">
                  <a class="button is-danger" v-on:click.stop="archiveAttachmentModal(attachment)"  v-show="attachment.status !== 'archived'">
                    <span class="icon">
                      <i class="mdi mdi-archive"></i>
                    </span>
                  </a>
                </p>
                <p class="control">
                  <a class="button is-danger" v-on:click.stop="deleteAttachmentModal(attachment)"  v-show="attachment.status == 'archived'">
                    <span class="icon">
                      <i class="mdi mdi-delete-forever"></i>
                    </span>
                    <span>Verwijder</span>
                  </a>
                </p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <no-entity
      title="Geen bijlages gevonden"
      button="Bijlage toevoegen"
      createPath="/attachments/create"
      message="Probeer andere zoektermen of voeg een nieuwe bijlage toe."
      icon="paperclip"
      :count="attachments.length" />

    <index-pagination
      :pages="pages"
      :currentPage="pageFilter" />

  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import indexBar from './index-bar'
import indexPagination from '../index-pagination'
import archiveModal from '../common/archive-modal'
import deleteModal from '../common/delete-modal'
import noEntity from '../common/no-entity'
import entityRelationsBadge from '../common/entity-relations-badge'
import * as Cookies from 'tiny-cookie'

export default {
  name: 'attachments',
  props: {
    initialOwnershipFilter: {
      default: 'all',
      type: String
    },
    initialIsDueFilter: {
      default: false,
      type: Boolean
    },
    initialIsOverDueFilter: {
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      attachments: [],
      tobeArchivedAttachment: {},
      tobeDeletedAttachment: {},
      pages: 1,
      pageFilter: 1,
      keywordsFilter: '',
      categoryFilter: '',
      positionFilter: '',
      tagFilter: '',
      statusFilter: 'active',
      isDueFilter: '',
      isOverDueFilter: '',
      mutableOwnershipFilter: 'all',
      mutableIsDueFilter: false,
      mutableIsOverDueFilter: false,
      statusses: {
        'active': 'Gepubliceerd',
        'draft': 'In concept',
        'archived': 'Gearchiveerd'
      }
    }
  },
  methods: {
    searchAttachments: function () {
      this.$bus.$emit('loadingEvent', true)

      // determine api route based on ownership filter
      if (this.mutableOwnershipFilter === 'me') {
        var attachmentRoute = 'me/attachments'
      } else {
        attachmentRoute = 'attachments'
      }

      this.$api.get(attachmentRoute + '?search=' + this.keywordsFilter + '&status=' + this.statusFilter + '&page=' + this.pageFilter + this.categoryFilter + this.positionFilter + this.tagFilter + this.isDueFilter + this.isOverDueFilter + '&include=categories,tags,positions,departments&limit=50').then((response) => {
        this.attachments = response.data.data

        this.pages = response.data.meta.pagination.total_pages
        this.pageFilter = response.data.meta.pagination.current_page

        this.$bus.$emit('loadingEvent', false)
      }, (err) => {
        console.log(err)
      })
    },
    setPageFilter: function (page) {
      this.pageFilter = page
    },
    setKeywordFilter: function (keywords) {
      this.keywordsFilter = keywords
      this.pageFilter = 1
    },
    setStatusFilter: function (status) {
      this.statusFilter = status
      this.pageFilter = 1
    },
    setCategoryFilter: function (categories) {
      this.categoryFilter = ''
      for (let category of categories) {
        this.categoryFilter += '&categories[]=' + category.id
      }
      this.pageFilter = 1
    },
    setPositionFilter: function (positions) {
      this.positionFilter = ''
      for (let position of positions) {
        this.positionFilter += '&positions[]=' + position.id
      }
      this.pageFilter = 1
    },
    setTagFilter: function (tags) {
      this.tagFilter = ''
      for (let tag of tags) {
        this.tagFilter += '&tags[]=' + tag.id
      }
      this.pageFilter = 1
    },
    setIsDueFilter: function (status) {
      if (status) {
        this.isDueFilter = '&is_due=1'
      }
    },
    setIsOverDueFilter: function (status) {
      if (status) {
        this.isOverDueFilter = '&is_over_due=1'
      }
    },
    setOwnershipFilter: function (ownership) {
      this.mutableOwnershipFilter = ownership
      this.pageFilter = 1
    },
    archiveAttachmentModal: function (attachment) {
      this.tobeArchivedAttachment = attachment
      this.$bus.$emit('askEntityArchiveModalEvent', true)
    },
    archiveAttachment: function (attachment) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Bijlage', attachment.id, '/attachment')
    },
    deleteAttachmentModal: function (attachment) {
      this.tobeDeletedAttachment = attachment
      this.$bus.$emit('askEntityDeleteModalEvent', true)
    },
    deleteAttachment: function (attachment) {
      // handle delete through entity abstraction layer
      this.$entity.delete('Bijlage', attachment.id, '/attachment')
    },
    editAttachment: function (attachment) {
      // navigate to document edit
      this.$router.push({ name: 'attachmentEdit', params: { id: attachment.id } })
    },
    downloadAttachment: function (attachment) {
      var token = Cookies.get('access_token')
      var rnd = new Date().getTime()
      window.location.href = process.env.VUE_APP_API_URL + '/attachment/' + attachment.id + '/file?access_token=' + token + '&client=backoffice&rnd=' + rnd
    }
  },
  mounted: function () {
    this.searchAttachments()

    // listen for search event emitted from search bar
    this.$bus.$on('askSearchEvent', (keywords) => this.setKeywordFilter(keywords))
    // listen for page change event emitted from a paginator
    this.$bus.$on('askPageChangeEvent', (page) => this.setPageFilter(page))
    // listen for status filter change event emitted from a search bar
    this.$bus.$on('askStatusChangeEvent', (status) => this.setStatusFilter(status))
    // listen for ownership filter change event emitted from a search bar
    this.$bus.$on('askOwnershipChangeEvent', (ownership) => this.setOwnershipFilter(ownership))
    // listen for category filter change event emitted from a search bar
    this.$bus.$on('askCategoryFilterChangeEvent', (categories) => this.setCategoryFilter(categories))
    // listen for position filter change event emitted from a search bar
    this.$bus.$on('askPositionFilterChangeEvent', (positions) => this.setPositionFilter(positions))
    // listen for tag filter change event emitted from a search bar
    this.$bus.$on('askTagFilterChangeEvent', (tags) => this.setTagFilter(tags))

    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (attachment) => this.archiveAttachment(attachment))
    // listen for archived event
    this.$bus.$on('entityArchivedEvent', () => this.searchAttachments())

    // listen for delete event
    this.$bus.$on('askEntityDeleteEvent', (attachment) => this.deleteAttachment(attachment))
    // listen for deleted event
    this.$bus.$on('entityDeletedEvent', () => this.searchAttachments())
  },
  created: function () {
    this.mutableOwnershipFilter = this.initialOwnershipFilter

    this.mutableIsDueFilter = this.initialIsDueFilter
    this.setIsDueFilter(this.initialIsDueFilter)

    this.mutableIsOverDueFilter = this.initialIsOverDueFilter
    this.setIsOverDueFilter(this.initialIsOverDueFilter)
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askEntityArchiveEvent')
    this.$bus.$off('entityArchivedEvent')

    this.$bus.$off('askEntityDeleteEvent')
    this.$bus.$off('entityDeletedEvent')

    this.$bus.$off('askPageChangeEvent')
    this.$bus.$off('askSearchEvent')
    this.$bus.$off('askStatusChangeEvent')
    this.$bus.$off('askOwnershipChangeEvent')
    this.$bus.$off('askStatusChangeEvent')
    this.$bus.$off('askCategoryFilterChangeEvent')
    this.$bus.$off('askPositionFilterChangeEvent')
    this.$bus.$off('askTagFilterChangeEvent')
  },
  watch: {
    keywordsFilter: function () {
      this.searchAttachments()
    },
    pageFilter: function () {
      this.searchAttachments()
    },
    statusFilter: function () {
      this.searchAttachments()
    },
    mutableOwnershipFilter: function () {
      this.searchAttachments()
    },
    categoryFilter: function () {
      this.searchAttachments()
    },
    positionFilter: function () {
      this.searchAttachments()
    },
    tagFilter: function () {
      this.searchAttachments()
    }
  },
  components: {
    'index-bar': indexBar,
    'index-pagination': indexPagination,
    'archive-modal': archiveModal,
    'delete-modal': deleteModal,
    'no-entity': noEntity,
    'entity-relations-badge': entityRelationsBadge
  }
}
</script>
