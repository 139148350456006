<template lang="html">
  <div class="box">

    <index-bar
      createPath="/positions/create"
      createText="Functie toevoegen"
      :activeStatus="statusFilter"
      :statusses="statusses"
      foldEntity="reader"
      foldEntityName="lezers" />

    <archive-modal
      :entity="this.tobeArchivedPosition"
      :entityName="this.tobeArchivedPosition.name" 
      entityType='position' />

    <delete-modal
      :entity="this.tobeDeletedPosition"
      :entityName="this.tobeDeletedPosition.name" 
      entityType='position' />

    <table class="table is-fullwidth" v-if="positions.length >= 1">
      <thead>
        <tr>
          <th><abbr title="Titel">Titel</abbr></th>
          <th><abbr title="Afdeling">Afdeling</abbr></th>
          <th><abbr title="Lezers">Lezers</abbr></th>
          <th><abbr title="Bron">Bron</abbr></th>
          <th></th>
        </tr>
      </thead>
      <template v-for="position in positions">
        <tr v-on:click="editPosition(position)" :key="position.id">
          <td>{{ position.name }}</td>
          <td>{{ position.department.data.name }}</td>
          <td>
            <span class="tag is-info" v-show="position.readers.data.length > 0" v-tooltip="position.readers.data.length + ' lezers gekoppeld aan deze functie'"> {{ position.readers.data.length }}</span>
            <span class="tag is-danger" v-show="position.readers.data.length === 0" v-tooltip="'Er zijn geen lezers met deze functie'">0</span>
          </td>
          <td>
            <i class="mdi mdi-cloud-sync mdi-18px" v-if="position.external_id" v-tooltip="'Deze functie is opgehaald uit een externe koppeling'"></i>
            <i class="mdi mdi-account-edit mdi-18px" v-if="!position.external_id" v-tooltip="'Deze functie is handmatig aangemaakt'"></i>  
          </td>
          <td>
            <div class="is-pulled-right">
              <div class="field has-addons">
                <p class="control">
                  <router-link :to="'/position/'+ position.id" class="button">
                    <span class="icon">
                      <i class="mdi mdi-pencil"></i>
                    </span>
                    
                  </router-link>
                </p>
                <p class="control">
                  <a class="button is-danger" v-on:click.stop="archivePositionModal(position)" v-show="position.status !== 'archived'">
                    <span class="icon">
                      <i class="mdi mdi-archive"></i>
                    </span>
                    
                  </a>
                </p>
                <p class="control">
                  <a class="button is-danger" v-on:click.stop="deletePositionModal(position)" v-show="position.status == 'archived'">
                    <span class="icon">
                      <i class="mdi mdi-delete-forever"></i>
                    </span>
                    <span>Verwijderen</span>
                  </a>
                </p>
              </div>
            </div>
          </td>
        </tr>
        <tr v-for="reader in position.readers.data" v-show="reader.status === 'active' && isUnfoldedReaders" :key="reader.id">
          <td colspan="4">
            <i class="mdi mdi-account-circle" :style="'margin-left: 1em'"></i> {{ reader.name }}
          </td>
          <td>
            <div class="is-pulled-right">
              <div class="field has-addons">
                <p class="control">
                  <router-link :to="'/reader/'+ reader.id" class="button">
                    <span class="icon">
                      <i class="mdi mdi-pencil"></i>
                    </span>
                    
                  </router-link>
                </p>
              </div>
            </div>
          </td>
        </tr>
      </template>

    </table>

    <no-entity
      title="Geen functies gevonden"
      button="Functie toevoegen"
      createPath="/positions/create"
      message="Probeer andere zoektermen of voeg een functie toe."
      icon="account-card-details"
      :count="positions.length" />

    <index-pagination
      :pages="pages"
      :currentPage="pageFilter" />

  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import indexBar from '../index-bar'
import indexPagination from '../index-pagination'
import archiveModal from '../common/archive-modal'
import deleteModal from '../common/delete-modal'
import noEntity from '../common/no-entity'

export default {
  name: 'positions',
  data: function () {
    return {
      positions: [],
      tobeArchivedPosition: {},
      tobeDeletedPosition: {},
      pages: 1,
      pageFilter: 1,
      statusFilter: 'active',
      statusses: {
        'active': 'Actief',
        'archived': 'Gearchiveerd'
      },
      isUnfoldedReaders: false
    }
  },
  methods: {
    getPositions: function () {
      this.$bus.$emit('loadingEvent', true)

      this.$api.get('positions?limit=100?include=readers,department' + '&status=' + this.statusFilter + '&page=' + this.pageFilter).then((response) => {
        if (response.data.data) {
          this.positions = response.data.data
        }
        this.pages = response.data.meta.pagination.total_pages
        this.pageFilter = response.data.meta.pagination.current_page

        this.$bus.$emit('loadingEvent', false)
      }, () => {
        this.$bus.$emit('loadingEvent', false)
      })
    },
    setPageFilter: function (page) {
      this.pageFilter = page
    },
    editPosition: function (position) {
      // navigate to position edit
      this.$router.push({ name: 'positionEdit', params: { id: position.id } })
    },
    archivePositionModal: function (position) {
      this.tobeArchivedPosition = position
      this.$bus.$emit('askEntityArchiveModalEvent', true)
    },
    archivePosition: function (position) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Functie', position.id, '/position')
    },
    deletePositionModal: function (position) {
      this.tobeDeletedPosition = position
      this.$bus.$emit('askEntityDeleteModalEvent', true)
    },
    deletePosition: function (position) {
      // handle delete through entity abstraction layer
      this.$entity.delete('Functie', position.id, '/position')
    },
    setStatusFilter: function (status) {
      this.statusFilter = status
      this.pageFilter = 1
    },
    foldReaders: function (status) {
      this.isUnfoldedReaders = status
    }
  },
  mounted: function () {
    this.getPositions()

    // listen for page change event emitted from a paginator
    this.$bus.$on('askPageChangeEvent', (page) => this.setPageFilter(page))
    // listen for status filter change event emitted from a search bar
    this.$bus.$on('askStatusChangeEvent', (status) => this.setStatusFilter(status))
    // listen for fold/unfold event
    this.$bus.$on('askFoldEvent', (status) => this.foldReaders(status))

    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (position) => this.archivePosition(position))
    // listen for archived event
    this.$bus.$on('entityArchivedEvent', () => this.getPositions())

    // listen for delete event
    this.$bus.$on('askEntityDeleteEvent', (position) => this.deletePosition(position))
    // listen for deleted event
    this.$bus.$on('entityDeletedEvent', () => this.getPositions())
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askPageChangeEvent')
    this.$bus.$off('askStatusChangeEvent')
    this.$bus.$off('askFoldEvent')

    this.$bus.$off('askEntityArchiveEvent')
    this.$bus.$off('entityArchivedEvent')

    this.$bus.$off('askEntityDeleteEvent')
    this.$bus.$off('entityDeletedEvent')
  },
  watch: {
    statusFilter: function () {
      // perform new get if status filter changed
      this.getPositions()
    },
    pageFilter: function () {
      // perform new search if page is changed
      this.getPositions()
    }
  },
  components: {
    'index-bar': indexBar,
    'index-pagination': indexPagination,
    'archive-modal': archiveModal,
    'delete-modal': deleteModal,
    'no-entity': noEntity
  }
}
</script>
