<template lang="html">
  <div id="twofactor">
    <div class="columns">
      <div class="column is-3"></div>
      <div class="column is-6">
        <div class="card is-rounded">
          <div class="card-content">
            <div class="content">
              <h3>Aanmelding met bevestigingscode</h3>
              <p>Een bevestigingscode is verstuurd naar het door jou ingestelde mobiele telefoonnummer. Voer deze code in om door te gaan met inloggen.</p>
              <div class="field">
                <label class="label">E-mailadres</label>
                <div class="control">
                  <input class="input" type="email" disabled :value="twofactor.email" placeholder="E-mailadres">
                </div>
                <p v-for="(error, index) in errors.email" class="help is-danger" :key="index">{{ error }}</p>
              </div>
              <div class="field">
                <label class="label">Bevestigingscode</label>
                <div class="control">
                  <input class="input" type="text" ref="token" v-model="twofactor.token" placeholder="Bevestigingscode" v-on:keyup.enter="verify()">
                </div>
                <p v-for="(error, index) in errors.token" class="help is-danger" :key="index">{{ error }}</p>
              </div>
              
              <a class="button is-primary" v-on:click="verify()" v-show="!verifying">Doorgaan</a>
              <a class="button is-primary is-disabled" v-show="verifying">Bezig met controleren</a>
              <router-link class="button is-pulled-right" to="/login">
                <span class="icon">
                  <i class="mdi mdi-arrow-left"></i>
                </span>
                <span>Opnieuw inloggen</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3"></div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>
export default {
  name: 'twofactor',
  data: function () {
    return {
      twofactor: {
        email: '',
        token: ''
      },
      verifying: false,
      errors: {}
    }
  },
  methods: {
    verify: function () {
      // emit reset in event
      this.verifying = true

      this.$api.post('/2fa', this.twofactor).then((response) => {
        // validation failed
        if (response.data.errors) {
          this.errors = response.data.errors
          this.$noty.warning('Verificatie mislukt. Probeer het nogmaals.')
        }
        // verification success
        if (response.data.message === '2fa_success') {
          // forward to login
          this.$router.push('/')
          // emit login
          this.$bus.$emit('apiLoggedInEvent')
        }

        this.verifying = false
      }, (error) => {
        this.verifying = false
        this.showApiErrorNotification(error)
      })
    }
  },
  mounted: function () {
    this.$refs.token.focus()

    // retrieve route params
    this.twofactor.email = this.$route.params.email
  }
}
</script>