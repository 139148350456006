const Entities = {
  install (Vue) {
    // initiate global prototyp
    Vue.prototype.$entity = {}

    let api
    // set global api client to use
    Vue.prototype.$entity.setApi = function (apiClient) {
      api = apiClient
    }

    let eventBus
    // send out an event
    const emitEvent = function (event, payload) {
      if (eventBus) {
        eventBus.$emit(event, payload)
      }
    }

    // set global eventbus to use
    Vue.prototype.$entity.setEventBus = function (bus) {
      eventBus = bus
    }

    let notificationBus
    // send out an event
    const emitNotification = function (notification) {
      if (notificationBus) {
        notificationBus[notification.type](notification.text)
      }
    }

    // set global notifications bus to use
    Vue.prototype.$entity.setNotificationBus = function (bus) {
      notificationBus = bus
    }

    /*
     * Generic entity versioning method
     *
     * entityType: name of the entity, to display messages
     * entityId: identification of the entity to raise the version off
     * endpoint: api endpoint responsible
     */
    Vue.prototype.$entity.version = function (entityType, entityId, endpoint) {
      emitEvent('versioningEvent', true)
      api.get(endpoint + '/' + entityId + '/version').then((response) => {
        if (response.status && response.status > 200) {
          emitEvent('versioningEvent', false)
          emitNotification({
            type: 'error',
            title: entityType + ' versie niet gemaakt',
            text: 'Probeer nogmaals de actie uit te voeren'
          })
        } else {
          emitEvent('versioningEvent', false)
          emitEvent('entityVersionedEvent', response.data.data)
          emitNotification({
            type: 'success',
            title: entityType + ' versie gemaakt',
            text: entityType + ' versie is succesvol aangemaakt'
          })
        }
      }, () => {
        emitEvent('versioningEvent', false)
      })
    }

    /*
     * Generic entity latest version marking method
     *
     * entityType: name of the entity, to display messages
     * entityId: identification of the entity to be marked latest
     * endpoint: api endpoint responsible
     */
    Vue.prototype.$entity.markLatestVersion = function (entityType, entityId, endpoint) {
      emitEvent('markingLatestVersionEvent', true)
      api.get(endpoint + '/' + entityId + '/version/latest').then((response) => {
        if (response.status && response.status > 200) {
          emitEvent('markingLatestVersionEvent', false)
          emitNotification({
            type: 'error',
            title: entityType + ' versie niet gemarkeerd',
            text: 'Probeer nogmaals de actie uit te voeren'
          })
        } else {
          emitEvent('markingLatestVersionEvent', false)
          emitEvent('entityMarkedLatestVersionEvent', response.data.data)
          emitNotification({
            type: 'success',
            title: entityType + ' versie gemarkeerd',
            text: entityType + ' versie is succesvol als laatste versie gemarkeerd'
          })
        }
      }, () => {
        emitEvent('markingLatestVersionEvent', false)
      })
    }

    /*
     * Generic entity archiving method
     *
     * entityType: name of the entity, to display messages
     * entityId: identification of the entity to archive
     * endpoint: api endpoint responsible
     */
    Vue.prototype.$entity.archive = function (entityType, entityId, endpoint) {
      emitEvent('archivingEvent', true)
      api.get(endpoint + '/' + entityId + '/archive').then((response) => {
        if (response.status && response.status > 200) {
          emitEvent('archivingEvent', false)

          let message = 'Er ging iets mis, probeer de actie nogmaals uit te voeren'
          // better describe 422 messages
          if (response.status === 422) {
            for (let error in response.data.errors) {
              message = response.data.errors[error][0]
              break
            }
          }

          emitNotification({
            type: 'error',
            title: entityType + ' niet gearchiveerd',
            text: message
          })
        } else {
          emitEvent('archivingEvent', false)
          emitEvent('entityArchivedEvent', response.data.data)
          emitNotification({
            type: 'success',
            title: entityType + ' gearchiveerd',
            text: entityType + ' is succesvol gearchiveerd'
          })
        }
      }, () => {
        emitEvent('archivingEvent', false)
      })
    }

    /*
     * Generic entity deleting method
     *
     * entityType: name of the entity, to display messages
     * entityId: identification of the entity to delete
     * endpoint: api endpoint responsible
     * substituteEntityId: the substitute id of an entity that replaces the to be deleted entity
     */
    Vue.prototype.$entity.delete = function (entityType, entityId, endpoint, substituteEntityId = null) {
      emitEvent('deletingEvent', true)

      let substituteParam = ''
      if (substituteEntityId) {
        substituteParam = '?substitute_entity_id=' + substituteEntityId
      }
      api.delete(endpoint + '/' + entityId + substituteParam).then((response) => {
        if (response.status && response.status > 200) {
          emitEvent('deletingEvent', false)

          let message = 'Er ging iets mis, probeer de actie nogmaals uit te voeren'
          // better describe 422 messages
          if (response.status === 422) {
            for (let error in response.data.errors) {
              message = response.data.errors[error][0]
              break
            }
          }

          emitNotification({
            type: 'error',
            title: entityType + ' niet verwijderd',
            text: message
          })
        } else {
          emitEvent('deletingEvent', false)
          emitEvent('entityDeletedEvent', response.data.data)
          emitNotification({
            type: 'success',
            title: entityType + ' verwijderd',
            text: entityType + ' is succesvol verwijderd'
          })
        }
      }, () => {
        emitEvent('deletingEvent', false)
      })
    }

    /*
     * Generic entity locking method
     *
     * entityType: name of the entity, to display messages
     * entityId: identification of the entity to lock
     * endpoint: api endpoint responsible
     */
    Vue.prototype.$entity.lock = function (entityType, entityId, endpoint) {
      emitEvent('lockingEvent', true)
      api.get(endpoint + '/' + entityId + '/lock').then((response) => {
        if (response.status && response.status > 200) {
          emitEvent('lockingEvent', false)
          emitNotification({
            type: 'error',
            title: entityType + ' niet geblokkeerd',
            text: 'Probeer nogmaals de actie uit te voeren'
          })
        } else {
          emitEvent('lockingEvent', false)
        }
      }, () => {
        emitEvent('lockingEvent', false)
      })
    }
    /*
     * Generic entity unlocking method
     *
     * entityType: name of the entity, to display messages
     * entityId: identification of the entity to unlock
     * endpoint: api endpoint responsible
     */
    Vue.prototype.$entity.unlock = function (entityType, entityId, endpoint) {
      emitEvent('unlockingEvent', true)
      api.get(endpoint + '/' + entityId + '/unlock').then((response) => {
        if (response.status && response.status > 200) {
          emitEvent('lockingEvent', false)
          emitNotification({
            type: 'error',
            title: entityType + ' niet gedeblokkeerd',
            text: 'Probeer nogmaals de actie uit te voeren'
          })
        } else {
          emitEvent('unlockingEvent', false)
        }
      }, () => {
        emitEvent('unlockingEvent', false)
      })
    }
    /*
     * Generic method to set many-to-many relations (attach entities to other ones)
     *
     * entity: object with the primary entity
     * attachEntities: array of ids of entities which need to be attached to the primary entity
     * endpoint: api endpoint responsible
     *
     * This method overwrites current relations
     */
    Vue.prototype.$entity.reattach = function (entity, attachEntities, attachEntityType, endpoint) {
      emitEvent('loadingEvent', true)
      api.put(endpoint, attachEntities).then((response) => {
        if (response.status && response.status > 200) {
          emitEvent('loadingEvent', false)

          return false
        } else {
          emitEvent('loadingEvent', false)
          emitNotification({
            type: 'success',
            title: attachEntityType + ' gekoppeld',
            text: attachEntityType + ' is succesvol gekoppeld'
          })

          return true
        }
      }, () => {
        emitEvent('loadingEvent', false)

        return false
      })
    }

    /*
     * Generic method to add many-to-many relations (attach entities to other ones)
     *
     * entity: object with the primary entity
     * attachEntities: array of ids of entities which need to be attached to the primary entity
     * endpoint: api endpoint responsible
     *
     * This method only adds new relations
     */
    Vue.prototype.$entity.attach = function (entity, attachEntities, attachEntityType, endpoint) {
      emitEvent('loadingEvent', true)
      api.post(endpoint, attachEntities).then((response) => {
        if (response.status && response.status > 200) {
          emitEvent('loadingEvent', false)

          return false
        } else {
          emitEvent('loadingEvent', false)
          emitNotification({
            type: 'success',
            title: attachEntityType + ' gekoppeld',
            text: attachEntityType + ' zijn succesvol gekoppeld'
          })

          return true
        }
      }, () => {
        emitEvent('loadingEvent', false)

        return false
      })
    }

    /*
     * Generic method to remove a many-to-many relationship (detach entity from another one)
     *
     * entity: object with the primary entity
     * detachEntityId: id of entity which needs to be detached from the primary entity
     * detachEntityType: name of the entity which is about to be detached
     * entitySlug: url part of api endpoint from primary entity (used for building endpoint)
     * detachEntitySlug: url part of api endpoint for the to be detached entity (used for building endpoint)
     *
     */
    Vue.prototype.$entity.detach = function (entity, detachEntityId, detachEntityType, entitySlug, detachEntitySlug) {
      emitEvent('loadingEvent', true)
      let endpoint = entitySlug + '/' + entity.id + '/' + detachEntitySlug + '/' + detachEntityId

      api.delete(endpoint).then((response) => {
        if (response.status && response.status > 200) {
          emitEvent('loadingEvent', false)

          return false
        } else {
          emitEvent('loadingEvent', false)
          emitNotification({
            type: 'success',
            title: detachEntityType + ' ontkoppeld',
            text: detachEntityType + ' is succesvol ontkoppeld'
          })

          return true
        }
      }, () => {
        emitEvent('loadingEvent', false)

        return false
      })
    }
  }
}

export default Entities
