<!-- home page -->
<template lang="html">
  <div id="home">
    <div class="columns">
      <div class="column is-6">
        
        <documents-personal 
          :documents="documents" 
          :documentsDue="documentsDue"
          :documentsOverDue="documentsOverDue" />

      </div>

      <div class="column is-6">
        
        <attachments-personal 
          :attachments="attachments" 
          :attachmentsDue="attachmentsDue"
          :attachmentsOverDue="attachmentsOverDue" />

      </div>
    </div>

    <div class="columns">
      <div class="column is-4">
        
        <comments-personal :comments="commentsOpen" />

      </div>
      <div class="column is-4">
        
        <readers-personal :readers="readers" :readersTotal="readersTotal" />

      </div>
      <div class="column is-4">
        
        <tags-personal :tags="tags" />

      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
  @import '../../assets/scss/home/home.scss';
</style>

<script>

import documentsPersonal from './childs/documents-personal'
import attachmentsPersonal from './childs/attachments-personal'
import commentsPersonal from './childs/comments-personal'
import readersPersonal from './childs/readers-personal'
import tagsPersonal from './childs/tags-personal'

export default {
  name: 'home',
  data: function () {
    return {
      documents: [],
      documentsDue: [],
      documentsOverDue: [],
      attachments: [],
      attachmentsDue: [],
      attachmentsOverDue: [],
      readers: [],
      readersTotal: 0,
      tags: [],
      commentsOpen: []
    }
  },
  methods: {
    getMe: function () {
      this.$bus.$emit('loadingEvent', true)

      this.$api.get('me?status=' + this.statusFilter + '&page=' + this.pageFilter + '&include=documents,documents_due,documents_overdue,attachments,attachments_due,attachments_overdue,comments_open').then((response) => {
        this.attachments = response.data.data.attachments.data
        this.attachmentsDue = response.data.data.attachments_due.data
        this.attachmentsOverDue = response.data.data.attachments_overdue.data
        this.documents = response.data.data.documents.data
        this.documentsDue = response.data.data.documents_due.data
        this.documentsOverDue = response.data.data.documents_overdue.data
        this.commentsOpen = response.data.data.comments_open.data

        this.$bus.$emit('loadingEvent', false)
      }, (err) => {
        console.log(err)
      })
    },
    getReaders: function () {
      this.$bus.$emit('loadingEvent', true)

      this.$api.get('readers?status=active').then((response) => {
        this.readers = response.data.data
        this.readersTotal = response.data.meta.pagination.total

        this.$bus.$emit('loadingEvent', false)
      }, (err) => {
        console.log(err)
      })
    },
    getTags: function () {
      this.$bus.$emit('loadingEvent', true)

      this.$api.get('tags?status=active&limit=100').then((response) => {
        this.tags = response.data.data

        this.$bus.$emit('loadingEvent', false)
      }, (err) => {
        console.log(err)
      })
    }
  },
  mounted: function () {
    this.getMe()
    this.getReaders()
    this.getTags()
  },
  components: {
    'documents-personal': documentsPersonal,
    'attachments-personal': attachmentsPersonal,
    'comments-personal': commentsPersonal,
    'readers-personal': readersPersonal,
    'tags-personal': tagsPersonal
  }
}
</script>