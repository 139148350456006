<template lang="html">
  <div class="modal" v-bind:class="{ 'is-active': visible }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <p>Je staat op het punt om een nieuwe versie van <strong>{{ entityName }}</strong> te maken.</p>
        <hr />
        <article class="message is-small is-info">
          <div class="message-body">
            De gepubliceerde versie van <strong>{{ entityName }}</strong> zal worden gekopieerd en <strong>in concept</strong>
            worden klaargezet. Na voltooiing kan deze nieuwe versie worden gepubliceerd waarmee de huidige wordt vervangen.
          </div>
        </article>
        <hr />
        <a class="button is-primary" v-on:click="askVersion(entity)" v-bind:class="{ 'is-disabled': versioning }">
          <span class="icon">
            <i class="mdi mdi-plus-circle-multiple-outline"></i>
          </span>
          <span v-if="!versioning">Nieuwe versie aanmaken</span>
          <span v-else>Bezig met aanmaken</span>
        </a>
        <a class="button" v-on:click="close()">
          <span class="icon">
            <i class="mdi mdi-cancel"></i>
          </span>
          <span>Annuleer</span>
        </a>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
  </div>
</template>

<script>

export default {
  name: 'versionModal',
  props: [
    'entity',
    'entityName'
  ],
  data: function () {
    return {
      versioning: false,
      visible: false
    }
  },
  methods: {
    askVersion: function (entity) {
      this.$bus.$emit('askEntityRaiseVersionEvent', entity)
      this.versioning = true
    },
    close: function () {
      this.visible = false
    },
    spawnModal: function () {
      this.visible = true
    },
    setVersioning: function (state) {
      this.versioning = state
      if (state === false) {
        this.visible = false
      }
    }
  },
  mounted: function () {
    // listen for ask for modal event
    this.$bus.$on('askEntityVersionModalEvent', this.spawnModal)
    this.$bus.$on('versioningEvent', (state) => this.setVersioning(state))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askEntityVersionModalEvent')
    this.$bus.$off('versioningEvent')
  }
}
</script>
