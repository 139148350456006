<template lang="html">
  <div class="box is-paddingless" v-if="!disabled">
    <h1>Koppelingen</h1>
    <div class="tabs">
      <ul>
        <li v-on:click="toggleTab('comments')" v-bind:class="{ 'is-active': activeTab == 'comments' }">
          <a>
            <span>Commentaren <span class="tag is-info" v-show="attachedComments.length > 0"> {{ attachedComments.length }}</span></span>
          </a>
        </li>
        <li v-on:click="toggleTab('notifications')" v-bind:class="{ 'is-active': activeTab == 'notifications' }">
          <a>
            <span>Notificaties <span class="tag is-info" v-show="attachedNotifications.length > 0"> {{ attachedNotifications.length }}</span></span>
          </a>
        </li>
        <li v-on:click="toggleTab('bookmarks')" v-bind:class="{ 'is-active': activeTab == 'bookmarks' }">
          <a>
            <span>Favorieten <span class="tag is-info" v-show="attachedBookmarks.length > 0"> {{ attachedBookmarks.length }}</span></span>
          </a>
        </li>
      </ul>
    </div>

    <div class="tab" v-show="activeTab == 'comments'">
      <comment v-for="(comment) in attachedComments" 
        v-bind:key="comment.id"
        :comment="comment"/>

      <article class="message is-info" v-show="attachedComments.length === 0">
        <div class="message-body">
          {{ reader.name }} heeft geen commentaren
        </div>
      </article>
    </div>

    <div class="tab is-paddingless" v-show="activeTab == 'notifications'">
      <table class="table is-fullwidth" v-show="attachedNotifications.length > 0">
        <thead>
          <tr>
            <th><abbr title="Titel">Titel</abbr></th>
            <th><abbr title="Inhoud">Bericht</abbr></th>
            <th><abbr title="Laatst aangepast">Verzonden</abbr></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="notification in attachedNotifications" :key="notification.id">
            <td>{{ notification.title }}</td>
            <td>{{ notification.content }}</td>
            <td>{{ notification.created_at | moment("DD-MM-YYYY") }}</td>
          </tr>
        </tbody>
      </table>
      <article class="message is-info" v-show="attachedNotifications.length === 0">
        <div class="message-body">
          {{ reader.name }} heeft geen notificaties ontvangen
        </div>
      </article>
    </div>

    <div class="tab is-paddingless" v-show="activeTab == 'bookmarks'">
      <table class="table is-fullwidth" v-show="attachedBookmarks.length > 0">
        <thead>
          <tr>
            <th><abbr title="Naam">Naam</abbr></th>
            <th><abbr title="Schrijver">Schrijver</abbr></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bookmark in attachedBookmarks" :key="bookmark.id">
            <td>{{ bookmark.name }}</td>
            <td>{{ bookmark.writer }}</td>
            <td>
              <router-link class="button is-small is-pulled-right" :to="'/document/'+ bookmark.id">
                Bewerk
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <article class="message is-info" v-show="attachedBookmarks.length === 0">
        <div class="message-body">
          {{ reader.name }} heeft geen favorieten gekozen
        </div>
      </article>
    </div>

  </div>
</template>

<script>

import comment from '../../common/comment'

export default {
  name: 'readerRelations',
  props: [
    'reader',
    'disabled',
    'attachedComments',
    'attachedNotifications',
    'attachedBookmarks'
  ],
  data: function () {
    return {
      activeTab: 'comments'
    }
  },
  methods: {
    toggleTab: function (tabName) {
      this.activeTab = tabName
    }
  },
  components: {
    'comment': comment
  }
}
</script>