<template lang="html">
  <div class="field has-addons">
    <p class="control">
      <span class="select">
        <select v-model="positionPreselection" @change="setPositionPreselection">
          <option value="*">Iedereen</option>
          <option value="custom">Zelf kiezen</option>
          <option v-for="(department, index) in departments" :value="department.id" :key="index">{{ department.name }}</option>
        </select>
      </span>
    </p>
    <p class="control is-expanded">
      <v-select ref="positionSelect" multiple
        :options="positions"
        label="name"
        :disabled="positionSearchDisabled"
        v-model="attachedPositions">

        <no-entity-autocomplete
          title="Functie niet gevonden"
          message="Probeer andere zoektermen of voeg een nieuwe functie toe via het hoofdmenu."
          icon="account-card-details"
          slot="no-options" />

      </v-select>
    </p>
  </div>
</template>

<script>

import vSelect from 'vue-select'
import noEntityAutocomplete from './no-entity-autocomplete'

export default {
  name: 'positionSelect',
  props: [
    'attachedPositionsImmutable'
  ],
  data: function () {
    return {
      positionPreselection: false,
      attachedPositions: [],
      positions: [],
      departments: []
    }
  },
  methods: {
    getPositions: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('positions?limit=100').then((response) => {
        for (let position of response.data.data) {
          this.positions.push({
            id: position.id,
            name: position.name,
            department_id: position.department_id })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getDepartments: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('departments?limit=100').then((response) => {
        for (let department of response.data.data) {
          this.departments.push({
            id: department.id,
            name: department.name })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    setPositionPreselection: function (event) {
      let preselection = event.target.value

      // auto fill positions if a department is selected
      if (preselection !== 'custom' || preselection !== '*') {
        let departmentId = preselection
        this.attachedPositions = []
        // walk over departments match those who are in right department_id
        for (let position of this.positions) {
          if (parseInt(position.department_id) === parseInt(departmentId)) {
            this.attachedPositions.push({
              id: position.id,
              name: position.name })
          }
        }
      }
    }
  },
  mounted: function () {
    this.getPositions()
    this.getDepartments()
  },
  watch: {
    'attachedPositions' (to) {
      // set correct position preselection on load
      if (!this.positionPreselection) {
        if (to.length === 0) {
          this.positionPreselection = '*'
        } else {
          this.positionPreselection = 'custom'
        }
      } else {
        this.$emit('update', to)
      }
    },
    'attachedPositionsImmutable' (to) {
      this.attachedPositions = to
      if (to.length !== 0) {
        this.positionPreselection = 'custom'
      }
    }
  },
  components: {
    'v-select': vSelect,
    'no-entity-autocomplete': noEntityAutocomplete
  },
  computed: {
    positionSearchDisabled: function () {
      if (this.positionPreselection === 'custom') {
        return false
      }
      return true
    }
  }
}
</script>