<template lang="html">
  <div id="category">

    <archive-modal
      :entity="this.position"
      :entityName="this.position.name" 
      entityType='position' />

    <div class="columns">
      <div class="column is-7">
        <div class="box">
          <div class="field">
            <label class="label title is-4 has-text-weight-light">{{ position.name }}</label>
            <div class="control">
              <input class="input" @focus="$event.target.select()" type="text" v-model="position.name" placeholder="Titel van de functie"
                v-bind:class="{ 'is-danger': errors.name }"
                v-bind:disabled="disabled"
                v-on:keyup.enter="updatePosition()">
            </div>
            <p v-for="(error, index) in errors.name" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Afdeling</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="position.department_id" v-bind:disabled="disabled">
                  <option v-for="(department, index) in departments" :value="department.id" :key="index">{{ department.name }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.department_id" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Status</label>
            <div class="control is-expanded">
              <div class="select is-fullwidth">
                <select v-model="position.status" v-bind:disabled="disabled">
                  <option v-for="(status, index) in statusses" :value="index" :key="index">{{ status }}</option>
                </select>
              </div>
              <p v-for="(error, index) in errors.status" class="help is-danger" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-5">
        <position-relations
          :position="position"
          :attachedDocuments="attachedDocuments"
          :attachedAttachments="attachedAttachments"
          :attachedReaders="attachedReaders"
          :disabled="disabled" />
      </div>

      <savebar
        :entity="position"
        entityName="Functie">
      </savebar>

    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import savebar from '../savebar'
import positionRelations from './childs/position-relations'
import archiveModal from '../common/archive-modal'

export default {
  name: 'positionEdit',
  data: function () {
    return {
      position: [],
      errors: {},
      disabled: false,
      departments: [],
      statusses: {
        'active': 'Actief',
        'archived': 'Gearchiveerd'
      },
      attachedDocuments: [],
      attachedAttachments: [],
      attachedReaders: []
    }
  },
  methods: {
    getPosition: function (positionId) {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('position/' + positionId + '?include=documents,readers,attachments').then((response) => {
        this.position = response.data.data

        this.attachedDocuments = []
        for (let document of this.position.documents.data) {
          if (document.status === 'published') {
            this.attachedDocuments.push({
              id: document.id,
              name: document.name,
              writer: document.writer.data.name,
              updated_at: document.updated_at })
          }
        }

        this.attachedReaders = []
        for (let reader of this.position.readers.data) {
          if (reader.status === 'active') {
            this.attachedReaders.push({
              id: reader.id,
              name: reader.name })
          }
        }

        this.attachedAttachments = []
        for (let attachment of this.position.attachments.data) {
          if (attachment.status === 'active') {
            this.attachedAttachments.push({
              id: attachment.id,
              name: attachment.name,
              updated_at: attachment.updated_at })
          }
        }

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    updatePosition: function () {
      this.$bus.$emit('savingEvent', true)
      this.disabled = true
      this.$api.put('position/' + this.position.id, this.position).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.errors = response.data.errors
          this.$noty.warning('Controleer de invoervelden')
        } else {
          this.position = response.data.data
          this.$bus.$emit('savingEvent', false)
          this.disabled = false
          this.$noty.success('Functie ' + this.position.name + ' is succesvol opgeslagen')
          this.errors = {}
          this.$router.push('/positions')
        }
      }, () => {
        this.$bus.$emit('savingEvent', false)
        this.disabled = false
      })
    },
    getDepartments: function () {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('departments?status=active&limit=100').then((response) => {
        for (let department of response.data.data) {
          this.departments.push({
            id: department.id,
            name: department.name
          })

          // also include subdepartments
          for (let subdepartment of department.descendants) {
            this.departments.push({
              id: subdepartment.id,
              name: subdepartment.name
            })
          }
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    archivePosition: function (position) {
      // handle archiving through entity abstraction layer
      this.$entity.archive('Functie', position.id, '/position')
    }
  },
  mounted: function () {
    // listen for save event emitted from a global savebutton
    this.$bus.$on('askSaveEvent', this.updatePosition)
    // listen for archive event
    this.$bus.$on('askEntityArchiveEvent', (position) => this.archivePosition(position))

    this.getDepartments()
    this.getPosition(this.$route.params.id)
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askSaveEvent')
    this.$bus.$off('askEntityArchiveEvent')
  },
  components: {
    'savebar': savebar,
    'position-relations': positionRelations,
    'archive-modal': archiveModal
  }
}
</script>
