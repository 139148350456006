 <template lang="html">
  <div class="box is-paddingless">
    <h1>Statistieken
      <a class="button is-pulled-right is-clearfix" style="margin-bottom: 1em" @click="toggleFold">
        <span class="icon" v-if="isFolded">
          <i class="mdi mdi-chevron-down"></i>
        </span>
        <span v-if="isFolded">Toon statistieken</span>
        <span class="icon" v-if="!isFolded">
          <i class="mdi mdi-chevron-up"></i>
        </span>
        <span v-if="!isFolded">Verberg statistieken</span>
      </a>
    </h1>

    <statistics-chart v-bind:chartData="statistics" :height="150" v-if="!isFolded" />

    <table class="table is-fullwidth" v-if="(!isFolded && readersViewed.length > 0)">
      <thead>
        <tr>
          <th><abbr title="Naam"></abbr>Dit artikel is bekeken door</th>
          <th><abbr title=""></abbr></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="reader in readersViewed" :key="reader.id">
          <td>{{ reader.name }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>

import statisticsChart from '../../common/statistics-chart'
import moment from 'moment'

export default {
  name: 'documentStatistics',
  props: [
    'document',
    'readersViewed'
  ],
  data: function () {
    return {
      statistics: false,
      isFolded: true
    }
  },
  methods: {
    getStatistics: function (documentId) {
      this.$bus.$emit('loadingEvent', true)
      this.disabled = true
      this.$api.get('statistics/document/' + documentId).then((response) => {
        // store formatted statistics
        this.statistics = this.formatStatistics(response.data)

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    formatStatistics: function (rawStatistics) {
      let viewsPoints = []
      let uniqueViewsPoints = []
      let days = []
      // format total views statistics if available
      if (rawStatistics.views && rawStatistics.views.serie.day.total) {
        for (let point of rawStatistics.views.serie.day.total) {
          if (point.views) {
            viewsPoints.push(point.views)
          } else {
            viewsPoints.push(0)
          }
          // add day labels
          days.push(moment(point.time).format('DD-MM-YY'))
        }
      }

      // format unique views statistics if available
      if (rawStatistics.views && rawStatistics.views.serie.day.unique) {
        for (let point of rawStatistics.views.serie.day.unique) {
          if (point.views) {
            uniqueViewsPoints.push(point.views)
          } else {
            uniqueViewsPoints.push(0)
          }
        }
      }

      // return full formatted structure
      let statistics = {
        labels: days,
        datasets: [
          {
            label: 'Weergaves',
            backgroundColor: 'rgb(27, 157, 193, 0.1)',
            borderColor: 'rgb(27, 157, 193)',
            borderWidth: '2',
            data: viewsPoints
          },
          {
            label: 'Unieke weergaves',
            backgroundColor: 'rgb(0, 209, 178, 0.3)',
            borderColor: 'rgb(0, 209, 178)',
            borderWidth: '2',
            data: uniqueViewsPoints
          }
        ]
      }

      return statistics
    },
    toggleFold: function () {
      if (this.isFolded) {
        this.isFolded = false
      } else {
        this.isFolded = true
      }
    }
  },
  components: {
    'statistics-chart': statisticsChart
  },
  watch: {
    document: function (val) {
      // get document statistics if document property is set
      if (val.id) {
        this.getStatistics(val.id)
      }
    }
  }
}
</script>
