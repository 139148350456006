<template lang="html">
  <div class="box is-paddingless" v-if="!disabled">
    <h1>Koppelingen</h1>
    <div class="tabs">
      <ul>
        <li>
          <a>
            <span>Artikelen <span class="tag is-info" v-show="attachedDocuments.length > 0"> {{ attachedDocuments.length }}</span></span>
          </a>
        </li>
      </ul>
    </div>

    <div class="tab is-paddingless">
      <table class="table is-fullwidth" v-show="attachedDocuments.length > 0">
        <thead>
          <tr>
            <th><abbr title="Versie">Versie</abbr></th>
            <th><abbr title="Naam">Naam</abbr></th>
            <th><abbr title="Aangepast">Aangepast</abbr></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in attachedDocuments" :key="document.id">
            <td><span class="tag">{{ document.version }}</span></td>
            <td>{{ document.name }}</td>
            <td>{{ document.updated_at | moment("DD-MM-YYYY") }}</td>
            <td>
              <router-link class="button is-small is-pulled-right" :to="'/document/'+ document.id">
                Bewerk
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <article class="message is-info" v-show="attachedDocuments.length === 0">
        <div class="message-body">
          Geen artikelen gekoppeld aan {{ attachment.name }}
        </div>
      </article>
    </div>
  </div>
</template>

<script>

export default {
  name: 'attachmentRelations',
  props: [
    'attachment',
    'attachedDocuments',
    'disabled'
  ],
  data: function () {
    return {
      documents: []
    }
  }
}
</script>